import { useState, useEffect, useRef } from 'react';
import L from 'leaflet';
// import {windowCheck} from '../../utilities/layoutFunctions.js';

import Slide from './Slide.js';
import ShareModule from '../../ShareModule.js'

import x from '../../../images/projects/x-white-outline.svg';
import loader from '../../../images/projects/loader.svg';

const MiniTapperContainer = ({activeData, setActive, muted, mobile}) => {
	const [slideActive, setSlideActive] = useState(0);
	const [viewInfo, setViewInfo] = useState(true);
	const [muteGesture, setMuteGesture] = useState(true);
	const [loading, setLoading] = useState(true)
	const panelRef = useRef()
	
	const handleTap = (direction) => {
		if (direction) {
			if (slideActive === activeData.slides.length - 1) {
				setSlideActive(0)
			} else {
				setSlideActive(slideActive + 1)
			}			
		} else {
			if (slideActive === 0) {
				setSlideActive(activeData.slides.length - 1)
			} else {
				setSlideActive(slideActive - 1)
			}	
		}
		// if (slideActive === 1 && muteGesture) {
		// 	setMuteGesture(false)
		// }

		// MUTE CONSIDERTIONS
		if ((slideActive === 1 || slideActive === activeData.slides.length - 1) && muteGesture && window.top !== window) {
			// IF you're in iFrame always unmute
			setMuteGesture(false)
		} else if (window.top === window && muteGesture) {
			if (!muted || mobile) {
				setMuteGesture(false)
			}
		} 

		//OLD MUTE CONSIDERATIONS
		// if ((slideActive === 1 || slideActive === activeData.slides.length - 1) && muteGesture) {
		// 	// IF you're in iFrame always unmute
		// 	setMuteGesture(false)
		// }

	}

	// useEffect(() => {
	// 	if (!muted) {
	// 		setMuteGesture(false)
	// 	}
	// }, [muted])

	useEffect(() => {
		// L.DomEvent.disableClickPropagation(panelRef.current);
	}, [])

	// useEffect(() => {
	// 	if (slideActive === 1 && muteGesture) {
	// 		setMuteGesture(false)
	// 		console.log("UNMUTE!")
	// 	}
	// 	console.log(slideActive)
	// }, [slideActive])


	useEffect(() => {
		let preloadedData;
		if (activeData.slides[0].slideType === "img") {
			preloadedData = activeData.slides.flatMap((slide, i) => {
				if (slide.slideType === "img") {
				    const img = new Image();
				    img.src = slide.img;
				    if (i === 0) {
				    	img.onload = () => {
				    		setLoading(false)
				    	}
				    }
				    return img
				} else {
					return []
				}
			});
			window.preloadedPictures = preloadedData;
		} else {
			setLoading(false)
		}

		return () => {
			if (preloadedData) {
				preloadedData[0].onload = () => {}
			}
		}
	}, [activeData, setLoading]);

	const slides = activeData.slides.map((slide, i) => <Slide key={`slide-${i}`} i={i} muted={muted} muteGesture={muteGesture} setMuteGesture={setMuteGesture} slide={slide} slideActive={slideActive} setSlideActive={setSlideActive} handleTap={handleTap} slideTotal={activeData.slides.length} />)

	const handleInfoView = (open) => {
		if (open) {
			setViewInfo(true)
		} else {
			setViewInfo(false);
		}
	}

	return (
		<div ref={panelRef} style={{overflow: "hidden"}} className='tapperContainer'>
			<div id="top" className='tapperContent'>
				<div className="xPosition cursor-pointer greyHaze" onClick={() => setActive(null)}>
					<img src={x}/>
				</div>
				<div style={{}} className='slideContainer'>
					{
						(!loading) ?
							<>
								{	
									(slideActive === 0) ?
										<>
											<h1 className="panelTitle">{activeData.shortTitle}</h1>
										</>
									: 	null
								}
								{ slides }
								<div onClick={() => handleTap(false)} className='prevTap' /> 
								<div onClick={() => handleTap(true)} className='nextTap' />
							</>
							: <div className="bootstrap-loader"></div>
					}
				</div>
				{
					(activeData.slides[slideActive].slideType !== "vid") ?
						<div onClick={() => handleInfoView(false)} style={{backgroundColor: `${activeData.panelColor}, .75)` }} className='cursor-pointer button'>
							See Initiative / Impact
						</div>
					: null
				}
			</div>

			<div id="initiativeImpact" className={`initiativeImpact ${(!viewInfo) ? 'slideUp' : ''}`}>
				<div>
					<div style={{backgroundColor: `${activeData.panelColor}, .75)` }} onClick={() => handleInfoView(true)} className={'button returnTop cursor-pointer'}>
						Hide
					</div>
					<h1>{activeData.title}</h1>
					<div className='decorativeSeparator'/>
					<div 
						style={{background: `linear-gradient(0deg, rgba(0,0,0,0) 0%, ${ activeData.panelColor },1) 100%)`}}
						className='headerBlurImg'
					/>	
					<h2>Initiative</h2>
						<p style={{paddingLeft: "1em"}}><em>{ activeData.initiative }</em></p>
					<h2>Impact</h2>
					<ul>
						{ activeData.impact.map((im, i) => <li key={`impact-${i}`}>{im}</li>)}
					</ul>
					<div className="learnMoreContainer flex-col align-center">
						<a className="inline-block cursor-pointer" href={activeData.url} target="_blank" rel="noopener noreferrer">
							<div className="learnMoreButton">
								Learn More
							</div>
						</a>
						<ShareModule icons={["Link", "Twitter", "Facebook"]} styles={{position: "unset", textAlign: "center"}}>
							<p>Share this initiative:</p>
						</ShareModule>
					</div>
				</div>
			</div>
		</div>
	)
}
export default MiniTapperContainer;