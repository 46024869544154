import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import Twitter from '../images/socials/twitter.svg';
import Facebook from '../images/socials/facebook.svg';
import Instagram from '../images/socials/instagram.svg';
import Link from '../images/socials/link.svg';

const ShareModule = ({icons, styles, options, children}) => {
	const [copied, setCopied] = useState(false);
	
	const copyToClipboard = (content: any) => {
		const el = document.createElement('textarea');
		el.value = window.location;
		document.body.appendChild(el);
		el.select();
		// DEPRECATION WARNINGS ALREADY COMING IN ==> 
		document.execCommand('copy');
		document.body.removeChild(el);
		setCopied(true)
	};

	useEffect(() => {
		if (copied) {
			let copyTimeout = setTimeout(() => {
				setCopied(false)
			}, 1000)
			return () => clearTimeout(copyTimeout)
		}
	}, [copied, setCopied])

	const shareIconMapping = {
		"Twitter": {
			"icon": Twitter,
			"data": {
				"href": `https://twitter.com/intent/tweet?url=${window.location}/?s-tr=567`,
				"data-show-count": "false",
				"data-url": `${window.location}/?s-tr=567s`,
				"target": "_blank",
				"rel": "noopener noreferrer"
			}
		},
		"Facebook": {
			"icon": Facebook,
			"data": {
				"href": `https://www.facebook.com/sharer/sharer.php?u=${window.location}`,
				"target": "_blank",
				"rel": "noopener noreferrer"
			}
		},
		"Link": {
			"icon": Link,
			"data": {
				"onClick": copyToClipboard
			}

		},
		"Instagram": Instagram,
	}

	const iconSpread = icons.map((icon, i) => {
			const widthConsideration = (icon === "Link") ? "70%" : "100%";
			const adjustmentStyles = (icon === "Link") ? {background: "black"} : {};
			const adjustmentClasses = (options === "noGlow") ? "share-icon" : "share-icon ring";
			return (
					<a key={`share-${icon}`} {...shareIconMapping[icon].data}>
						<div style={adjustmentStyles} className={adjustmentClasses}>
							<img width={widthConsideration} alt={`Share on ${icon}`} src={shareIconMapping[icon].icon} />
						</div>
					</a>
			)
		}
	)

	return (
		<div style={styles} className="share-module pos-abso flex flex-col">
			{ children }
			<div className="flex flex-row pos-rel">
				<CSSTransition 
					in={copied} 
					timeout={300} 
					classNames="fade-animate"
				>
					<span className="copied-flag-abso">Copied!</span>
				</CSSTransition>
				{iconSpread}
			</div>
		</div>
	)
}

export default ShareModule;