import React, { useState, useEffect, useRef } from 'react';
import D3Component from './D3Component';
import Key from './Key';
import CaptionModule from '../../CaptionModule';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

let vis;

function VisContainer(props) {
	const [yearHeader, setYearHeader] = useState(null);
	const chartElement = useRef(null);

	// useEffect(handleResizeEvent, []);
	useEffect(initVis, [ props.slideData, props.height ]);
	// useEffect(updateVisOnResize, [ props.width, props.height ]);

	// function handleResizeEvent() {
	// 	let resizeTimer;
	// 	const handleResize = () => {
	// 		clearTimeout(resizeTimer);
	// 		resizeTimer = setTimeout(function() {
	// 			setWidth(window.innerWidth);
	// 			setHeight(window.innerHeight - 20);
	// 		}, 300);
	// 	};
	// 	window.addEventListener('resize', handleResize);

	// 	return () => {
	// 		window.removeEventListener('resize', handleResize);
	// 	};
	// }

	function initVis() {
		if(props.slideData && props.slideData.length && props.height) {
			const d3Props = {
				mainData: props.slideData.slice(1),
				chartColorReference: props.colorReference,
				width: props.width,
				height: props.height,
				margin: {top: 10, right: 30, bottom: 75, left: 65},
				setActive: props.setActive,
				setYearHeader: setYearHeader,
				setChartLoading: props.setChartLoading,
				firstChange: props.firstChange,
				setFirstChange: props.setFirstChange
			};
			vis = new D3Component(chartElement.current, d3Props);
		}
	}

	// function updateVisOnResize() {
	// 	vis && vis.resize(width, height);
	// }

	const activeKeyProp = (props.active === 2);
	const activeYearHeaderProp = (props.active === 4);

	return (
		<div className='chart-panel-container'>
			<div className="chart-header-container">
				<SwitchTransition>
					<CSSTransition 
						key={`header-${props.active}`} 
						timeout={600}
						classNames="fade-600-animate"
					>
						<h2 className="chart-header">{props.slideData[props.active].chart.text.header}</h2>
					</CSSTransition>
				</SwitchTransition>
			</div>
			<div className="chart-body-container" ref={chartElement}>
				<CSSTransition 
					in={activeKeyProp} 
					timeout={4500}
					classNames="fade-4000-delay-animate"
				>
					<div>
						{		
							(activeKeyProp)	?		
								<Key colorReference={props.colorReference} mobile={props.mobile} />
							: null
						}	
					</div>
				</CSSTransition>
				
				<CSSTransition 
					in={activeYearHeaderProp} 
					timeout={{
						enter: 4500,
						exit: 700
					}}
					classNames="fade-4000-delay-animate"
				>
					<h1 className="datascroll-year-container">2002{yearHeader}</h1>
				</CSSTransition>
				
				<CSSTransition 
					in={activeYearHeaderProp} 
					timeout={{
						enter: 4500,
						exit: 700
					}}
					classNames="fade-4000-delay-animate"
				>	
					<div>
						{ 
							(activeYearHeaderProp) ?
								<CaptionModule optionalIconClasses={"no-scroll ring"} optionalBoxClasses={"no-scroll"} text={"These graphs reflect the racial/ethnic composition of drivers against whom law enforcement officers in Raleigh reported using force since the department began reporting its data to the North Carolina Department of Justice."}/>
							: 	null 
						}
					</div>
				</CSSTransition>

			</div>
		</div>
	);
}

export default VisContainer;