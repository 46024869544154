const policeReformExperiments = [
	{
		title: "Brownsville, New York",
		shortTitle: "Brownsville, NY",
		subHead: "Crisis Intervention",
		query: "brownsville",
		panelColor: "rgba(39, 52, 159",
		url: "https://www.thecity.nyc/2021/1/3/22211709/nypd-cops-brooklyn-brownsville-experiment-defund-police",
		lat: 40.669013,
		lng: -73.906702,
		initiative: "After his predecessor was reassigned following an incident of police brutality in 2020, Brownsville’s Deputy Inspector Terrell Anderson, green lit the Brownsville Safety Alliance pilot. It aimed to reduce the neighborhood's police presence and increase access to social services.",
		impact: [
			"No emergency police calls during a 5-day period in the pilot program’s area",
			"Connected residents with vital social services, including childcare, education, and job opportunities",
			"Earned the city’s approval to re-run the experiment in the future and potentially expand the program",
		],
		slides: [
			{	
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/BROWNSVILLE/brooklyn_brownsville1.jpg",
				imgAlt: "Photo credit: Spencer Platt/Getty Images",
				text: `The police presence on Mother Gaston Boulevard in Brownsville, Brooklyn is so thick, community leaders describe it as feeling like an "occupation."`,
				textPosition: {
					bottom: "6em",
					left: "0"
				}
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/BROWNSVILLE/brooklyn_brownsville7.jpg",
				imgAlt: "Photo credit: @NYPD73Pct/Twitter",
				text: `That changed briefly in December 2020, when NYC police withdrew from their posts for five days, replaced by unarmed violence interrupter and crisis management groups.`,
				textPosition: {
					bottom: "6em",
					right: "0"
				}
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/BROWNSVILLE/brooklyn_brownsville3.jpg",
				imgAlt: "Google Maps",
				text: `They watched over two-blocks between Pitkin and Sutter avenues. City agencies and nonprofits set up tents along the strip, sharing info on education, job, and housing opportunities.`,
				textPosition: {
					bottom: "6em",
					left: "0"
				}			
			},
			{
				slideType: "img",
				img: "https://frame-next-cdn.s3.amazonaws.com/detours/pact-policing-experiments/brownsville2.jpg",
				imgAlt: "Photo credit: Tdorante10/Wikimedia",
				text: `Only one 911 call was reported by a bus driver who accidentally tripped a distress signal.`,
				textPosition: {
					bottom: "6em",
					right: "0"
				}	
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/BROWNSVILLE/brooklyn_brownsville5.jpg",
				imgAlt: "Photo credit: @NYPD73Pct/Twitter",
				text: `It was an isolated experiment: covering just 50 daytime and evening hours during a cold week in the middle of the COVID-19 pandemic.`,
				textPosition: {
					bottom: "6em",
					left: "0"
				}	
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/BROWNSVILLE/brooklyn_brownsville6web.jpg",
				imgAlt: "Photo credit: DeShaun Craddock/Flickr",
				text: `But the city sees promise in the program, and is dedicated to trying it again during warmer months.`,
				textPosition: {
					bottom: "8em",
					right: "0"
				}	
			},
			{
				slideType: "imgQuote",
				img: "https://pact-reform-map.s3.amazonaws.com/BROWNSVILLE/brooklyn_brownsville2.jpg",
				imgAlt: "Photo credit: @NYPD73Pct/Twitter",
				text: `"I'm learning through working with the different organizations how to be a better commanding officer and how to help my cops be better police officers as we move toward a future of reimagining public safety. But what was displayed was amazing — I think it's going to be a model for the future."`,
				quoteAuthor: ["— Deputy Inspector Terrell Anderson,", "Commanding officer of NYPD’s 73rd Precinct"],
				textPosition: {
					margin: "auto",
					left: "0",
					right: "0",
					top: "0",
					bottom: "0",
					height: "max-content",
					fontSize: "1.3em",
				}			
			},
		]
	},
	{
		title: "Eugene, Oregon",
		shortTitle: "Eugene, OR",
		subHead: "Crisis Intervention",
		query: "cahoots",
		panelColor: "rgba(39, 52, 159",
		url: "https://whitebirdclinic.org/what-is-cahoots/",
		lat: 44.046818,
		lng: -123.0875039,
		initiative: "In 1989, Eugene, Oregon, began routing a portion of 911 calls to a crisis-intervention service, CAHOOTS, to respond to non-violent incidents related to substance abuse, mental illness, and more.",
		impact: [
			"Reduces the need for police intervention in mental health cases, helping Eugene avoid violent escalations",
			"CAHOOTS requires only 2% of its companion police department’s budget and saves Eugene an estimated $8.5 million annually",
			"The service saves over an estimated $2.9 million annually in emergency medical costs by helping individuals avoid costly ER visits and ambulance fees",
			"Makes mental health and addiction treatment accessible, safer, and less traumatic for those in need",
			"Provides vital support for the city’s unhoused population — a practically non-existent service in traditional police departments"
		],
		slides: [
			{	
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/CAHOOTS/CAHOOTS_RMiller1.jpg",
				imgAlt: "Photo credit: Flickr/R. Miller",
				text: `For 30 years, Eugene, Oregon and nearby Springfield have run one of the country’s most successful experiments in crisis intervention.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/CAHOOTS/CAHOOTS_2web.jpg",
				imgAlt: "The CAHOOTS van, Image provided by White Bird Clinic",
				text: `Named CAHOOTS (Crisis Assistance Helping Out On The Streets) the service provides mobile crisis intervention, dispatching teams of medics and mental health professionals instead of (or in addition to) police to respond to non-violent distress calls.`
			},
			{
				slideType: "vid",
				vid: "https://pact-reform-map.s3.amazonaws.com/CAHOOTS/Eugene+3.mp4",
				vidTitle: "Interview with Michelle Perin, CAHOOTS, Part 1",
				lowerThird: ["Michelle Perin", "EMT and Crisis Worker", "CAHOOTS"]
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/CAHOOTS/CAHOOTS_3web.jpg",
				imgAlt: "Image provided by White Bird Clinic",
				text: `Many CAHOOTS calls deal with unhoused individuals or those experiencing mental health issues. Their intervention can be lifesaving: People with untreated mental illness in the U.S. are 16 times more likely to be killed during a police encounter than other civilians.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/CAHOOTS/whitebird_web2.jpg",
				imgAlt: "WhiteBirdClinic/Facebook",
				text: `The service was born out of an unlikely partnership between the Eugene Police Department and the White Bird Clinic, originally a counter-cultural collective dating back to the 1970s that evolved into a grass-roots human services program.`
			},
			{
				slideType: "vid",
				vid: "https://pact-reform-map.s3.amazonaws.com/CAHOOTS/Eugene+6.mp4",
				vidTitle: "Interview with Michelle Perin, CAHOOTS, Part 2",
				lowerThird: false
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/CAHOOTS/CAHOOTS_7web.jpg",
				imgAlt: "WhiteBirdClinic/Facebook",
				text: `In 2019, the program handled between 8-20% of the police department’s calls and only requested police back-up 311 times out of the year’s roughly 24,000 total calls.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/CAHOOTS/CAHOOTS_8web.jpg",
				imgAlt: "Image provided by White Bird Clinic",
				text: `Since the police killing of George Floyd in 2020, CAHOOTS has received a surge of interest from other municipalities interested in learning how they can set up similar services. They’re building a playbook for selling officials on the idea.`,
				textPosition: {
					bottom: "8em",
					left: "0"
				}
			},
			{
				slideType: "vid",
				vid: "https://pact-reform-map.s3.amazonaws.com/CAHOOTS/Eugene+9.mp4",
				imgAlt: "Interview with Michelle Perin, CAHOOTS, Part 2",
				lowerThird: false
			},
		]
	},
	{
		title: "Camden, New Jersey",
		shortTitle: "Camden, NJ",
		subHead: "Union Busting",
		query: "camden",
		panelColor: "rgba(74,20,68",
		url: "https://citymonitor.ai/government/camden-new-jersey-isnt-really-a-model-for-police-reform",
		lat: 39.9437022,
		lng: -75.1162223,
		initiative: "Facing a $14 million deficit and a reputation for rampant excessive use-of-force, the state dissolved Camden’s police department and built a new one from the ground up.",
		impact: [
			`Former police officials say the move allowed officials to "reset the culture" and create a more community-minded policing strategy for Camden`,
			"Local activists say the police department got more punitive, more aggressive, and would have remained that way without key reforms",
			"Camden revamped its use-of-force protocols in 2015, and again in 2019, and implemented a new de-escalation training program",
			"Between 2014 and 2020, excessive force complaints dropped 95%, while homicide rates fell by more than 50%"
		],
		slides: [
			{	
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/CAMDEN/Camden_1web.jpg",
				imgAlt: "Photo credit: Steve Shook/Flickr",
				text: `One of the most oft-cited examples of “defunding the police” in action, the words don’t entirely describe what happened to the Camden police department in the 2010s.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/CAMDEN/Camden_Police_2web.jpg",
				imgAlt: "Photo credit: Blake Bolinger/Flickr",
				text: `In 2010, Camden had one of the highest murder rates in the country. Its police department was broke and had a reputation for corruption and excessive use-of-force.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/CAMDEN/Camden_Police_3_CCPD-header.jpg",
				imgAlt: "Photo credit: Camden County Division of Public Affairs",
				text: `Facing a $14 million deficit, the state threatened huge layoffs unless the police union made contract concessions. They refused and half the department was fired in 2010.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/CAMDEN/Camden_Police_4.jpg",
				imgAlt: "Photo credit: Andrew Burton/Getty Images",
				text: `In 2013, the city’s department was dissolved and reformed under county (rather than state) control. Every officer had to reapply for a job. Protesting union members boycotted, and they were left out of hiring.`
			},
			{
				slideType: "imgQuote",
				img: "https://pact-reform-map.s3.amazonaws.com/CAMDEN/Camden_Police_5.jpg",
				imgAlt: "Screenshot from Not Our Streets/YouTube",
				text: `"I was able to accomplish in three days what I couldn’t in three years. That allowed me to reset the culture."`,
				quoteAuthor: ["— Scott Thompson,", "Camden police chief (2008 - 2019)",],
				textPosition: {
					margin: "auto",
					bottom: "5em",
					left: 0,
					right: 0,
					height: "max-content",
					fontSize: "1.3em",
				}		
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/CAMDEN/Camden_Police_6.jpg",
				imgAlt: "Office of Public Affairs/Flickr",
				text: `But the Camden police didn’t change overnight. The new department hired more officers and summons for petty crimes spiked, as did complaints of excessive use-of-force — trends that resemble the now-deeply criticized "broken windows" New York City policing in the '90s.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/CAMDEN/Camden_Police_7.jpg",
				imgAlt: "Photo credit: Mark Makela/Getty Images",
				text: `Local activists have put concerted pressure on the new department to revamp its use-of-force protocols — first in 2015, and again in 2019 — and implement an effective de-escalation training program.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/CAMDEN/Camden_Police_8web.jpg",
				imgAlt: "Screenshot from Not Our Streets/YouTube",
				text: `The pressure appears to have worked. Between 2014 and 2020, excessive force complaints dropped 95%, while homicide rates fell by more than 50%.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/CAMDEN/Camden_Police_9web.jpg",
				imgAlt: "Photo credit: Blake Bolinger/Flickr",
				text: `Camden is not a utopia. There are still huge social inequities, persistent use-of-force issues and a growing, largely unchecked surveillance program. Without the dedicated work of local activists, Camden would not be a touchstone in police reform discussions.`,
				textPosition: {
					bottom: "8em",
					right: "0"
				}	
			},
		]
	},
	{
		title: "Denver, Colorado",
		shortTitle: "Denver, CO",
		subHead: "Qualified Immunity",
		query: "denver",
		panelColor: "rgba(74,20,68",
		url: "https://leg.colorado.gov/bills/sb20-217",
		lat: 39.7337,
		lng: -104.9799,
		initiative: "Responding to community pressure demanding police reform in the wake of the killings of Elijah McClain, George Floyd, and Breonna Taylor, the Colorado state legislature crafted and passed an ambitious police reform package.",
		impact: [
			"Colorado became the first state to strip qualified immunity from officers, which allows plaintiffs to bring civil rights claims against officers in Colorado state court",
			"All officers must be outfitted with body cameras by 2023. Additionally, police who tamper with cameras or purposely fail to activate them during interactions with the public, could face criminal liability or other penalties.",
			"Banned the use of chokeholds and carotid control holds",
			"Officers who fail to try to stop other officers from using excessive force can face a Class 1 misdemeanor or a greater charge"
		],
		slides: [
			{	
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/DENVER/Denver_Protest_March_1web.jpg",
				imgAlt: "Photo credit: Thomas Elliott/Wikimedia",
				text: `Passed in June 2020, Denver’s Enhance Law Enforcement Integrity Act is one of the most ambitious and comprehensive bills aimed at curbing officer use-of-force in recent memory.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/DENVER/Denver_Protest_March_2web.jpg",
				imgAlt: "Photo credit: Craig Talbert/Wikimedia",
				text: `The bill bans chokeholds, expands the use of body cameras, and is the first legislation of its kind in the country to end "qualified immunity" for officers — ending a legal protection that prevents many cases of officer misconduct from reaching trial.`,
			},
		
			{
				slideType: "vid",
				vid: "https://pact-reform-map.s3.amazonaws.com/DENVER/Denver+4_part1.mp4",
				imgAlt: "Photo credit: Thomas Elliott/Wikimedia",
				lowerThird: ["Mari Newman", "Civil Rights Attorney"]
			},
			{
				slideType: "vid",
				vid: "https://pact-reform-map.s3.amazonaws.com/DENVER/Denver+4_part2.mp4",
				imgAlt: "Photo credit: Thomas Elliott/Wikimedia",
				lowerThird: ["Rep. Leslie Herod", "Colorado State General Assemblymember"]
			},	
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/DENVER/Denver_Protest_March_6web.jpg",
				imgAlt: "Photo credit: @leslieherod/Twitter",
				text: `Herod and Newman gathered a coalition of activists, lawyers, and politicians, including Representative Serena Gonzales-Gutierrez and State Senate President Leroy Garcia, to begin drafting a law, building off previous bills introduced to the legislature by groups like the Colorado ACLU.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/DENVER/Denver_Protest_March_3web.jpg",
				imgAlt: "Brownsville MTA",
				text: `To  Rep. Herod’s surprise, the "qualified immunity" centerpiece of the bill gained significant support from right-wing and libertarian legislators when the issue was framed as an example of government overreach.`},
			{
				slideType: "vid",
				vid: "https://pact-reform-map.s3.amazonaws.com/DENVER/Denver+7.mp4",
				imgAlt: "Brownsville MTA",
				lowerThird: false
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/DENVER/Denver_Protest_March_8web.jpg",
				imgAlt: "Photo credit: Thomas Elliott/Wikimedia",
				text: `Officers who courts determine to not have acted in good faith can now be held personally liable for up to $25,000. Proponents also say the law will work to prevent officers from acting rashly in the first place — since there's far less shielding them from legal repercussions.`
			},
			{
				slideType: "vid",
				vid: "https://pact-reform-map.s3.amazonaws.com/DENVER/Denver+9.mp4",
				imgAlt: "Brownsville MTA",
				lowerThird: false
			},
			{
				slideType: "vid",
				vid: "https://pact-reform-map.s3.amazonaws.com/DENVER/Denver+10.mp4",
				imgAlt: "Brownsville MTA",
				lowerThird: false
			},
		]
	},
	{
		title: "Oakland, California",
		shortTitle: "Oakland, CA",
		subHead: "Violence Disruption",
		query: "oakland",
		panelColor: "rgba(74,20,68",
		url: "https://www.hellmanfoundation.org/oakland-ceasefire.html",
		lat: 37.400851,
		lng: -122.074893,
		initiative: " Responding to sustained issues with gun violence, the city of Oakland started a program called Oakland Ceasefire. The initiative intervenes in the lives of young people to offer access to social services and economic support.",
		impact: [
			"The number of shootings in Oakland dropped by more than 50% between 2011 and 2017",
			"Arrests were scaled back by 55% between 2006 and 2015",
			"Officers are solving more murders, rising from 29% in 2011 to over 70% in 2017 ",
		],
		slides: [
			{	
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/OAKLAND/Oakland_1web.jpg",
				imgAlt: "Photo credit: Beppe Sabatini/Flickr",
				text: `Oakland Ceasefire is a policing technique aimed at intervening in the lives of young people at risk of getting involved with gun violence, before that involvement leads to irreparable harm.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/OAKLAND/Oakland_2web.jpg",
				imgAlt: "Photo credit: Thomas Hawk/Flickr",
				text: "It’s a tailored response to an analysis that 0.1% of Oakland's population is responsible for the bulk of its homicides. These individuals — often members of gangs, with a history of shooting or being shot — are also in many cases the most isolated from or resistant to social services."
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/OAKLAND/Oakland_3web.jpg",
				imgAlt: "Photo credit: Mercury News/YouTube",
				text: `Ceasefire outreach workers identify young people at risk and invite them in for meetings. They're offered access to housing, jobs, medical care, life coaches, and a monthly stipend — if they accomplish goals like signing up for health insurance or opening a savings account.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/OAKLAND/oakland_6web.jpg",
				imgAlt: "Photo credit: Daniel Arauz/Flickr)",
				text: `The program has had a rocky history. It started and failed in 2007 and 2011, before rolling out again in 2013 — this time with more emphasis on social services and less on law enforcement.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/OAKLAND/oakland_5web.jpg",
				imgAlt: "Photo credit: Thomas Hawk/Flickr",
				text: `There’s evidence it’s working: The number of shootings in Oakland has dropped by more than 50% between 2011 and 2017, arrests have declined, and officers are solving more murders than they once did.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/OAKLAND/Oakland_MISC_FINAL.jpg",
				imgAlt: "Photo credit: Thomas Hawk/Flickr",
				text: `Issues with Oakland's policing techniques still exist. Research from 2016 shows officers were four times more likely to search Black men than white men during traffic stops. Additional studies show Oakland police make an arrest in 80% of homicides with white victims, but only in 40% with Black victims (similar to national percentages).`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/OAKLAND/OAKLAND_7web.jpg",
				imgAlt: "Photo credit: usmarshals/Flickr",
				text: `Critics also charge that the program still leans too much into its law enforcement aspects and threats of legal retribution — something the program’s champions are fighting to change.`
			},
			{
				slideType: "imgQuote",
				img: "https://pact-reform-map.s3.amazonaws.com/OAKLAND/OAKLAND_8web.jpg",
				imgAlt: "Photo credit: benjmcbride/Facebook",
				text: `"If you can find the money to pay police officers up to $300,000 a year to patrol the streets with guns, why can't we find the resources to stipend community members to take time away to also reduce violence? We want to continue to see the kind of partnership and respect for people of color that are doing the work."`,
				quoteAuthor: ["— Ben McBride,", "San Francisco pastor &", "leading Ceasefire advocate"],
				textPosition: {
					margin: "auto",
					bottom: "5em",
					left: 0,
					right: 0,
					height: "max-content",
					fontSize: "1.3em",
				}	
			},
		]
	},
	{
		title: "Las Vegas, Nevada",
		shortTitle: "Las Vegas, NV",
		subHead: "Chase Reform",
		query: "las_vegas",
		panelColor: "rgba(74,20,68",
		url: "https://www.apa.org/monitor/2020/10/cover-police-brutality",
		lat: 36.2188,
		lng: -115.3098,
		initiative: "In 2011, then third in the U.S. for the number of officer-involved shootings per capita, LVMPD implemented a number of use-of-force policy overhauls, including a tactic called 'No Hands On' that prohibits the officer pursuing a suspect from being the same officer to physically apprehend them.",
		impact: [
			"Use-of-force reports were cut nearly in half from 1,400 in 2005 to 734 in 2013.",
			"The number of officer-involved shootings declined 36% from 2010 to 2015. However, a 2015 longitudinal study of two decades of officer-involved shooting data in Las Vegas found minimal long-term change in the annual average of number shootings."
		],
		slides: [
			{	
				slideType: "vidText",
				video: "https://pact-reform-map.s3.amazonaws.com/LAS+VEGAS/vegas_strip_video_TRIM.mp4",
				imgAlt: "Michael Scott/Pexels",
				text: `Throughout the 90s and early 00s, the Las Vegas Metropolitan Police Department was infamous for its use-of-force.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/LAS+VEGAS/Las_Vegas_Police_2web.jpg",
				imgAlt: "Photo credit: Mark Ittleman/Wikimedia",
				text: "It ranked third in the country for officer-involved shootings per capita, behind Houston and Chicago. A third of these shootings involved Black people."
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/LAS+VEGAS/Las_Vegas_Police_3.jpg",
				imgAlt: "Photo credit: Mark Ittleman/Wikimedia",
				text: `The details of this brutality were laid bare in a 2011 Las Vegas Review-Journal investigation, which concluded that in most of the LVMPD's fatal incidents in the past 20 years, force was used unnecessarily. The Department of Justice launched its own investigation shortly thereafter, picking apart the department's culture, training, and oversight.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/LAS+VEGAS/Las_Vegas_Police_4.jpg",
				imgAlt: "Photo credit: Tomás Del Coro/Flickr",
				text: `LVMPD implemented a package of reforms that included training on how to better handle suspects with mental health issues, strategies to mitigate racial bias, and a specific measure regarding police chases called 'No Hands On.'`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/LAS+VEGAS/Las_Vegas_Police_5.jpg",
				imgAlt: "The White House from Washington, DC/Wikimedia",
				text: `During police chases, officer adrenaline runs high. 'No Hands On' prohibits the pursuing officer from being the one to physically apprehend the suspect, preventing flared tempers from resulting in violence.`
			},
			{
				slideType: "imgQuote",
				img: "https://pact-reform-map.s3.amazonaws.com/LAS+VEGAS/Las_Vegas_Police_6.jpg",
				imgAlt: "Photo credit: Tomás Del Coro/Flickr",
				text: `"The assumption is that the officer in pursuit is more likely to be out of breath, angry, or otherwise not in a good place to use force wisely...We train our officers to slow the situation down and create distance, and distance allows you time. It allows you time to communicate. It allows you time to think about what you're going to do."`,
				quoteAuthor: ["— Lt. John Farrell (retired),", "One of the policy’s architects"],
				textPosition: {
					margin: "auto",
					top: "25vh",
					left: 0, 
					right: 0,
					height: "max-content",
					fontSize: "1.3em",
				}
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/LAS+VEGAS/Las_Vegas_Police_7_HORSE.jpg",
				imgAlt: "Photo credit: Tomás Del Coro/Flickr",
				text: `The reform package cut use-of-force cases nearly in half — dropping from 1,400 in 2005 to 734 in 2013. But issues with force persist, highlighted in the 2017 police killing of Byron Williams, who uttered the words "I can't breathe" before dying in police custody.`
			},
		]
	},
	{
		title: "Berkeley, California",
		shortTitle: "Berkeley, CA",
		subHead: "Police Oversight",
		query: "berkeley",
		panelColor: "rgba(140, 10, 10",
		url: "https://www.cityofberkeley.info/Police_Review_Commission/PAB.aspx",
		lat: 38.3722403,
		lng: -122.2747239,
		initiative: "To investigate the Berkeley Police Department’s aggressive handling of a series of anti-Vietnam War protests, the city created the country’s second police oversight agency. It reformed in 2020 following a surge of momentum around police reform.",
		impact: [
			"The nine-person Police Accountability Board now has full access to data and subpoena powers to investigate public complaints against police officers",
			"The Board has put into practice a number of substantive policy reforms, including an overhauled use-of-force mandate and a set of standards regarding how police should interact with the city’s transgender community"
		],
		slides: [
			{	
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/BERKELEY/Berkley_1_web.jpg",
				imgAlt: "Photo credit: Oakland (Calif.). Police Dept/Wikimedia",
				text: `Berkeley’s police oversight agency is the second ever established in the U.S., and the first to include investigatory powers. It was founded in 1973, following a series of Vietnam War protests that were marred by widespread police brutality. `
			},
			{	
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/BERKELEY/Berkley_2web.jpg",
				imgAlt: "Photo credit: Roger Jones/Wikimedia",
				text: `The oversight agency’s powers were expanded in 2020, when 84% of the electorate voted to reform the Police Review Commission into the Police Accountability Board, granting it complete access to department records and the ability to subpoena officers.`
			},
			{
				slideType: "vid",
				vid: "https://pact-reform-map.s3.amazonaws.com/BERKELEY/Berkeley_3.mp4",
				imgAlt: "Interview with George Perezvelez, Part 1",
				lowerThird: ["George Perezvelez", "Senior Member", "Berkeley Police Accountability Board"]
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/BERKELEY/Berkley_4web.jpg",
				imgAlt: "CityofBerkeley.info",
				text: `The Board’s nine members apply through the city of Berkeley's website and are selected by the Mayor’s office to investigate public complaints of officer misconduct, review the department budget, assist in hiring police chiefs, and recommend new police procedures.`
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/BERKELEY/Berkeley_5web.jpg",
				imgAlt: "Photo provided by George Perezvelez",
				text: `George Perezvelez, the longest serving member of the Police Review Commission, spearheaded the push to reform the board and helped lead a major overhaul of Berkeley's use-of-force policy, which has been in the works for nearly a decade. He almost gave up in 2018.`,
				styles: {
					objectPosition: "50% 0%"
				}
			},
			{
				slideType: "vid",
				vid: "https://pact-reform-map.s3.amazonaws.com/BERKELEY/Berkeley+6.mp4",
				imgAlt: "Interview with George Perezvelez, Part 2",
				lowerThird: false
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/BERKELEY/Berkley_7web.jpg",
				imgAlt: "Photo credit: pedrik/Wikimedia",
				text: `The city’s previous use-of-force standard encouraged officers to "strive to" use the minimum force possible, which left a lot of room for interpretation for officers to justify violent actions. That standard has now changed in Berkeley.`
			},
			{
				slideType: "vid",
				vid: "https://pact-reform-map.s3.amazonaws.com/BERKELEY/Berkeley+8.mp4",
				imgAlt: "Interview with George Perezvelez, Part 3",
				lowerThird: false
			},
			{
				slideType: "img",
				img: "https://pact-reform-map.s3.amazonaws.com/BERKELEY/Berkeley_9web.jpg",
				imgAlt: "George Perezvelez",
				text: "It's not clear yet whether the changes will meaningfully reduce police violence in Berkeley, but George and the Police Accountability Board are prepared to re-evaluate and push the policy further if needed, in the hopes of ultimately creating a new culture in policing.",
			},
			{
				slideType: "vid",
				vid: "https://pact-reform-map.s3.amazonaws.com/BERKELEY/Berkeley+10.mp4",
				imgAlt: "Interview with George Perezvelez, Part 4",
				lowerThird: false
			},
		]
	},
]

// {
// 		title: "Kalamazoo, Michigan",
// 		lat: 42.2796528,
// 		lng: -85.5835779,
// 		initiative: "After years facing accusations of racial bias, Kalamazoo Police Department hired an outside firm to analyze its policing practices, producing a study that found concrete evidence of departmental racial profiling. It created the political will for change.",
// 		impact: [
// 			"The addition of mandatory racial bias training and new consent to search protocols designed to cut back on prejudicial “fishing” search practices.",
// 			"A 42% decline in the number of traffic stops, amid a 7% drop in the crime rate in the year following",
// 			"A new openness to source data to reform police practices — The department organized another study in 2020 to review their use-of-force practices in handling of the summer’s BLM protests and Proud Boys rallies."
// 		],
// 		slides: [
// 			{	
// 				slideType: "img",
// 				img: "https://frame-next-cdn.s3.amazonaws.com/detours/pact-policing-experiments/brownsville1.jpg",
// 				imgAlt: "Brownsville MTA",
// 				text: `In 2011, Kalamazoo Police Department received a request from the city's Citizens Public Safety Review and Appeals board to conduct a study on racial profiling.`
// 			},
// 			{
// 				slideType: "img",
// 				img: "https://frame-next-cdn.s3.amazonaws.com/detours/pact-policing-experiments/brownsville2.jpg",
// 				imgAlt: "Brownsville MTA",
// 				text: "It was intended to determine whether or not the police department exhibited racial bias in traffic stops — an assertion long leveled by local communities of color."
// 			},
// 			{
// 				slideType: "img",
// 				img: "https://frame-next-cdn.s3.amazonaws.com/detours/pact-policing-experiments/brownsville1.jpg",
// 				imgAlt: "Brownsville MTA",
// 				text: `The department hired a Philadelphia firm, Lamberth Consulting, which observed traffic data from March 1, 2012 to Feb. 28, 2013.`,
// 			},
// 			{
// 				slideType: "img",
// 				img: "https://frame-next-cdn.s3.amazonaws.com/detours/pact-policing-experiments/brownsville2.jpg",
// 				imgAlt: "Brownsville MTA",
// 				text: `The results shocked the department: Black motorists were more than two times as likely to be stopped by police than white people behind the wheel.`
// 			},
// 			{
// 				slideType: "img",
// 				img: "https://frame-next-cdn.s3.amazonaws.com/detours/pact-policing-experiments/brownsville1.jpg",
// 				imgAlt: "Brownsville MTA",
// 				text: `“It kind of takes your breath away. But what do you do? Do you sit there and act like a deer in headlights? Do you dismiss the study that you asked for?” — Public Safety Chief Jeff Hadley`
// 			},
// 			{
// 				slideType: "img",
// 				img: "https://frame-next-cdn.s3.amazonaws.com/detours/pact-policing-experiments/brownsville2.jpg",
// 				imgAlt: "Brownsville MTA",
// 				text: `In response, Kalamazoo added mandatory racial bias training and imposed new rules requiring officers to explain their “reasonable suspicion” each time they sought a driver’s consent to search.`
// 			},
// 			{
// 				slideType: "img",
// 				img: "https://frame-next-cdn.s3.amazonaws.com/detours/pact-policing-experiments/brownsville2.jpg",
// 				imgAlt: "Brownsville MTA",
// 				text: `In the year following, traffic stops declined 42%, as did the crime rate by 7%.`
// 			},
// 			{
// 				slideType: "img",
// 				img: "https://frame-next-cdn.s3.amazonaws.com/detours/pact-policing-experiments/brownsville2.jpg",
// 				imgAlt: "Brownsville MTA",
// 				text: `It was hardly a cure-all. Interactions between police and community of color in Kalamazoo aren’t rosey. But the department has shown an openness to continue using data to reform its practices.`
// 			},
// 			{
// 				slideType: "img",
// 				img: "https://frame-next-cdn.s3.amazonaws.com/detours/pact-policing-experiments/brownsville2.jpg",
// 				imgAlt: "Brownsville MTA",
// 				text: `In 2020, it organized another study to review its use-of-force practices following criticisms of its handling of the summer’s BLM protests and Proud Boys rallies.`
// 			},
// 		]
// 	},

export default policeReformExperiments;