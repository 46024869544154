import React, { useState, useEffect, useCallback, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useThrottle } from "../../utilities/utilityFunctions.js";

import play from '../../../images/projects/play.svg';
import pause2 from '../../../images/projects/pause2.svg';
import forward10 from '../../../images/projects/forward-10.svg';
import back10 from '../../../images/projects/back-10.svg';
import loader from '../../../images/projects/loader.svg';

function CircleTextPlayer({src, playText, active, i}) {
	const [paused, setPaused] = useState(true)
	const [controlVis, setControlVis] = useState(false)
	const [featureLoad, setFeatureLoad] = useState(false)
	const vidRef = useRef();
	// const fadeTimer = useRef();

	//CONTROLS AUTO FADE AND ITS TIMER
	// useEffect(() => {
	// 	// clearTimeout(fadeTimer.current);
	// 	// if (!paused) {
	// 	// 	fadeTimer.current = setTimeout(() => {
	// 	// 		setControlVis(false);
	// 	// 	}, 3500);
	// 	// }
	// 	// return () => clearTimeout(fadeTimer.current);
	// }, [controlVis, paused]);

	//PLAY PAUSE AND SCRUB
	const playVid = useCallback(() => {
		(paused) ? vidRef.current.play() : vidRef.current.pause()
		setPaused(!paused);
		setControlVis(c => !c);
	}, [paused])

	const resetListener = useCallback(() => {
		setPaused(true);
		// clearTimeout(fadeTimer.current);
		if (controlVis) setControlVis(false);
		vidRef.current.currentTime = 0;
	}, [controlVis])

	const scrubSeconds = useCallback((secs) => {
		let time = vidRef.current.currentTime + secs;
		vidRef.current.currentTime = time;
		// clearTimeout(fadeTimer.current);
		// fadeTimer.current = setTimeout(() => {
		// 	setControlVis(false);
		// }, 3500);
	}, [])

	// const holdTimer = useThrottle(() => {
	// 	// clearTimeout(fadeTimer.current);
	// 	if (!controlVis && !paused) {
	// 		setControlVis(true)
	// 	};
	// 	// fadeTimer.current = setTimeout(() => {
	// 	// 	setControlVis(false);
	// 	// }, 3500);
	// }, 50);

	useEffect(() => {
		const vid = vidRef.current
		vid.addEventListener('ended', resetListener);
		return () => vid.removeEventListener('ended', resetListener);
	}, [resetListener])

	//CREATE CIRCULAR TEXT
	const circularText = useCallback((txt, radius) => {
		txt = txt.split("");

		let deg = 360 / txt.length,
		origin = 0;

		return txt.map((ea, i) => {
			if (i > 0) {
				origin += deg;
			}
			return <p key={`letter-${i}-${ea}`} style={{height:`${radius}px`, position:`absolute`, transform:`rotate(${origin}deg)`, fontSize: "2em", transformOrigin:`0% 100%`}}>{ea}</p>
		});
	}, [])

	return (
		<div style={{position: "fixed", left: "0"}} className="bespoke-container">
			<img alt="Loading..." style={{display: featureLoad ? "none" : paused ? "none": "block"}} className="proj-loader loader noShadow" src={loader}/>
			<video 
				onCanPlay={() => setFeatureLoad(true)} 
				style={{}} 
				onClick={() => !paused ? setControlVis(true) : null} 
				ref={vidRef} 
				playsInline 
				className="static-scrollyteller-img vid" src={src} 
			/>
			
			<CSSTransition in={paused} timeout={200} unmountOnExit classNames="lightbox-animate">
				<div onClick={() => playVid()} className="bespoke-play circle">
					<div className="bespoke-pos-rel">
						{ 
							(playText) ?
								circularText(playText, 150) 
							: null
						}
					</div>
					<img alt="Play button" className="pos-abso margin-auto left0 right0 top0 bottom0" width="50%" src={ play } />
				</div>
			</CSSTransition>

			<CSSTransition in={controlVis} timeout={300} unmountOnExit classNames="lightbox-animate">
				<div className="bespoke-controls">
					<div onClick={() => scrubSeconds(-10)} className="bespoke-player-icon back-10">
						<img alt="Rewind 10 seconds" src={back10}/>
					</div>
					<div onClick={() => playVid()} className="bespoke-player-icon pause">
						<img alt="Pause" src={pause2}/>
					</div>
					<div onClick={() => scrubSeconds(10)} className="bespoke-player-icon forward-10">
						<img alt="Fast forward 10 seconds" src={forward10} />
					</div>
				</div>
			</CSSTransition>
		</div>
	)
}

export default CircleTextPlayer;