export const disasterJSON = [
	{
		"name": "Hurricanes",
		"type":"FeatureCollection",
		"features":[
			{
				"type":"Feature",
				"geometry":{
					"type":"Polygon",
					"coordinates":[
						[
							[
								-67.18139648437499,
								45.034714778688596
							],
							[
								-67.40112304687501,
								45.04247805089152
							],
							[
								-67.5,
								45.460130637920926
							],
							[
								-67.8076171875,
								45.62940492064498
							],
							[
								-68.84033203125,
								44.731125592643195
							],
							[
								-69.97192382812499,
								44.174324837518846
							],
							[
								-72.4658203125,
								42.30981541568661
							],
							[
								-74.0478515625,
								41.82045509614029
							],
							[
								-78.79394531249999,
								36.932330061503116
							],
							[
								-83.18847656250001,
								33.72433966174758
							],
							[
								-84.9462890625,
								33.10074540514422
							],
							[
								-86.7041015625,
								32.54681317351517
							],
							[
								-89.47265625000001,
								32.175612478499325
							],
							[
								-91.7578125,
								31.80289258670676
							],
							[
								-95.75683593750004,
								31.48489338689006
							],
							[
								-98.0419921875,
								30.524413269923986
							],
							[
								-98.87695312499999,
								29.84064389983441
							],
							[
								-99.404296875,
								29.22889003019423
							],
							[
								-99.580078125,
								28.22697003891834
							],
							[
								-99.37133789062503,
								26.637638886645895
							],
							[
								-98.426513671875,
								26.05678288577881
							],
							[
								-97.53662109375001,
								25.819671943904044
							],
							[
								-97.218017578125,
								25.928407032694118
							],
							[
								-97.33886718750001,
								27.03955660216317
							],
							[
								-97.16308593750001,
								27.58619785769259
							],
							[
								-96.67968750000001,
								28.062285999812136
							],
							[
								-96.009521484375,
								28.39140037581778
							],
							[
								-94.79003906250004,
								29.132970130878586
							],
							[
								-93.86718750000001,
								29.44916482692468
							],
							[
								-93.31787109375003,
								29.496987596535742
							],
							[
								-92.82348632812501,
								29.324720161511003
							],
							[
								-91.68090820312503,
								29.094577077511826
							],
							[
								-90.48339843750001,
								28.97931203672236
							],
							[
								-89.84619140625001,
								28.7869180854202
							],
							[
								-89.02221679687503,
								28.950475674847983
							],
							[
								-88.89038085937501,
								29.544787796199465
							],
							[
								-88.857421875,
								30.145127183376076
							],
							[
								-86.2646484375,
								30.126124364224452
							],
							[
								-85.31982421875001,
								29.420460341013108
							],
							[
								-84.82543945312501,
								29.525670426175775
							],
							[
								-84.30908203125001,
								29.888280933159265
							],
							[
								-84.034423828125,
								29.93589521337242
							],
							[
								-83.419189453125,
								29.31514119318728
							],
							[
								-82.880859375,
								28.815799886487298
							],
							[
								-82.880859375,
								28.536274512989916
							],
							[
								-83.00170898437501,
								28.246327971048842
							],
							[
								-82.95776367187501,
								27.58619785769264
							],
							[
								-81.9140625,
								25.977798955464333
							],
							[
								-81.44165039062503,
								25.591994180254687
							],
							[
								-81.32080078124999,
								25.12539261151198
							],
							[
								-81.71630859375,
								24.68695241199913
							],
							[
								-81.93603515625001,
								24.367113562651223
							],
							[
								-80.57373046874999,
								24.776759933219115
							],
							[
								-80.20019531249999,
								25.20494115356904
							],
							[
								-80.04638671875001,
								26.263862280111066
							],
							[
								-80.035400390625,
								26.765230565697433
							],
							[
								-80.44189453124999,
								27.78077164334817
							],
							[
								-80.57373046874999,
								28.101057958669397
							],
							[
								-80.56274414062499,
								28.516969440401006
							],
							[
								-81.10107421874999,
								29.535229562948416
							],
							[
								-81.38671875,
								30.581179257386932
							],
							[
								-80.8154296875,
								31.76553740948435
							],
							[
								-80.26611328125,
								32.222095840502305
							],
							[
								-79.69482421875,
								32.528289364825234
							],
							[
								-78.72802734375,
								33.422272258865966
							],
							[
								-77.98095703125001,
								33.71520164474082
							],
							[
								-77.65136718749999,
								34.03445260967637
							],
							[
								-77.39868164062503,
								34.32529192442723
							],
							[
								-76.91528320312503,
								34.551811369170466
							],
							[
								-76.453857421875,
								34.56990638085625
							],
							[
								-75.77270507812504,
								34.94899072578219
							],
							[
								-75.41015625000003,
								35.12889434101045
							],
							[
								-75.311279296875,
								35.496456056584115
							],
							[
								-75.90454101562501,
								36.77409249464188
							],
							[
								-74.79492187500001,
								38.70265930723788
							],
							[
								-74.16870117187501,
								39.461643642055414
							],
							[
								-73.94897460937503,
								39.85072092501592
							],
							[
								-73.83911132812499,
								40.28790661250733
							],
							[
								-71.80664062500001,
								40.83043687764913
							],
							[
								-69.93896484375,
								41.087632124679104
							],
							[
								-69.96093750000001,
								41.96765920367808
							],
							[
								-70.77392578125,
								42.204107493733154
							],
							[
								-70.55419921875001,
								42.52069952914961
							],
							[
								-70.718994140625,
								42.83569550641449
							],
							[
								-70.048828125,
								43.40504748787025
							],
							[
								-66.92871093750001,
								44.63739123445574
							],
							[
								-67.18139648437499,
								45.034714778688596
							]
						]
					]
				},
				"properties":{
					"fill":"#13b07c",
					"stroke":"#555555",
					"show_on_map":true,
					"fill-opacity":0.5,
					"popupContent":"Currently, more than 8.6 million Americans live in areas susceptible to coastal flooding caused by hurricane or intense storm activity. Recent projections don\u2019t show climate change necessarily increasing the total number of hurricanes, but it will likely increase their overall intensity, precipitation levels, and the number of very intense category 4 and 5 storms.",
					"stroke-width":0.5,
					"stroke-opacity":0.5
				}
			}
		]
	},
	{
		"name": "Floods",
		"type":"FeatureCollection",
		"features":[
			{
				"type":"Feature",
				"geometry":{
					"type":"Polygon",
					"coordinates":[
						[
							[
								-96.591796875,
								28.265682390146477
							],
							[
								-95.64697265625,
								28.786918085420226
							],
							[
								-95.1416015625,
								28.9600886880068
							],
							[
								-94.833984375,
								29.34387539941801
							],
							[
								-94.74609375,
								29.554345125748267
							],
							[
								-94.4384765625,
								29.49698759653577
							],
							[
								-93.8232421875,
								29.76437737516313
							],
							[
								-93.2958984375,
								29.859701442126756
							],
							[
								-92.8125,
								29.6880527498568
							],
							[
								-93.07617187499999,
								30.14512718337613
							],
							[
								-92.61474609375,
								30.334953881988564
							],
							[
								-92.52685546875,
								30.675715404167743
							],
							[
								-92.59277343749999,
								30.977609093348686
							],
							[
								-92.94433593749999,
								31.12819929911196
							],
							[
								-93.515625,
								31.22219703210317
							],
							[
								-93.7353515625,
								31.541089879585808
							],
							[
								-93.779296875,
								31.914867503276223
							],
							[
								-93.955078125,
								32.287132632616384
							],
							[
								-94.21875,
								32.43561304116276
							],
							[
								-94.306640625,
								32.80574473290688
							],
							[
								-94.482421875,
								33.063924198120645
							],
							[
								-94.8779296875,
								33.247875947924385
							],
							[
								-95.3173828125,
								33.02708758002874
							],
							[
								-95.49316406249999,
								32.69486597787505
							],
							[
								-95.97656249999999,
								32.0639555946604
							],
							[
								-96.3720703125,
								31.615965936476076
							],
							[
								-96.6796875,
								31.16580958786196
							],
							[
								-96.7236328125,
								30.751277776257812
							],
							[
								-96.5478515625,
								30.29701788337205
							],
							[
								-96.240234375,
								29.916852233070173
							],
							[
								-96.45996093749999,
								29.57345707301757
							],
							[
								-96.94335937499999,
								29.49698759653577
							],
							[
								-97.42675781249999,
								29.401319510041485
							],
							[
								-97.3828125,
								29.22889003019423
							],
							[
								-97.0751953125,
								28.76765910569123
							],
							[
								-96.591796875,
								28.265682390146477
							]
						],
						[
							[
								-87.89886474609375,
								30.517315187761888
							],
							[
								-87.89337158203125,
								30.39656853856939
							],
							[
								-87.83843994140625,
								30.342065246371636
							],
							[
								-87.78076171875,
								30.259067203213018
							],
							[
								-87.7423095703125,
								30.232967599863763
							],
							[
								-87.03369140625,
								30.346805868962075
							],
							[
								-86.30584716796876,
								30.44867367928756
							],
							[
								-85.55877685546876,
								30.06909396443887
							],
							[
								-85.16326904296875,
								29.726222319395504
							],
							[
								-84.72381591796875,
								29.80251790576445
							],
							[
								-84.59197998046876,
								30.031055426540206
							],
							[
								-84.85565185546876,
								30.372875188118016
							],
							[
								-85.03143310546875,
								30.637912028341123
							],
							[
								-84.76776123046875,
								30.789036751261136
							],
							[
								-84.50408935546876,
								31.541089879585808
							],
							[
								-84.41619873046875,
								31.840232667909365
							],
							[
								-84.37225341796876,
								32.08257455954592
							],
							[
								-84.26239013671876,
								31.87755764334002
							],
							[
								-84.28436279296875,
								31.59725256170666
							],
							[
								-84.06463623046875,
								31.653381399664
							],
							[
								-83.75701904296875,
								31.653381399664
							],
							[
								-83.36151123046875,
								31.87755764334002
							],
							[
								-82.79022216796875,
								32.287132632616384
							],
							[
								-82.83416748046875,
								32.54681317351514
							],
							[
								-82.92205810546876,
								32.91648534731439
							],
							[
								-83.27362060546875,
								33.247875947924385
							],
							[
								-83.84490966796875,
								33.43144133557529
							],
							[
								-83.97674560546875,
								33.8339199536547
							],
							[
								-83.84490966796875,
								34.161818161230386
							],
							[
								-83.49334716796876,
								34.34343606848294
							],
							[
								-83.36151123046875,
								34.63320791137959
							],
							[
								-83.18572998046875,
								34.994003757575776
							],
							[
								-84.15252685546875,
								34.95799531086792
							],
							[
								-84.24041748046875,
								35.24561909420681
							],
							[
								-84.67987060546875,
								35.38904996691167
							],
							[
								-84.81170654296875,
								35.871246850027966
							],
							[
								-85.82244873046876,
								35.85343961959182
							],
							[
								-86.39373779296875,
								35.60371874069731
							],
							[
								-86.92108154296876,
								34.88593094075317
							],
							[
								-87.14080810546875,
								34.30714385628804
							],
							[
								-87.09686279296875,
								33.687781758439364
							],
							[
								-87.09686279296875,
								32.95336814579932
							],
							[
								-87.14080810546875,
								32.10118973232094
							],
							[
								-87.22869873046875,
								31.87755764334002
							],
							[
								-87.71209716796875,
								31.615965936476076
							],
							[
								-88.165283203125,
								31.25507418542194
							],
							[
								-88.2257080078125,
								31.13760327002129
							],
							[
								-88.1982421875,
								31.034108344903512
							],
							[
								-88.0938720703125,
								30.803192546290973
							],
							[
								-87.989501953125,
								30.65681556429287
							],
							[
								-87.89886474609375,
								30.517315187761888
							]
						],
						[
							[
								-81.98547363281251,
								30.760718908944398
							],
							[
								-81.54602050781249,
								30.836214626064717
							],
							[
								-81.27685546874999,
								31.151707478133602
							],
							[
								-81.09008789062497,
								31.615965936476048
							],
							[
								-80.4144287109375,
								32.23138966273753
							],
							[
								-79.51904296875003,
								32.83344284664945
							],
							[
								-79.15649414062501,
								33.11915022676884
							],
							[
								-78.90380859375003,
								33.568861182555565
							],
							[
								-77.95898437500001,
								33.78827853625994
							],
							[
								-77.55249023437501,
								34.31621838080741
							],
							[
								-76.99218750000001,
								34.56990638085633
							],
							[
								-76.36596679687503,
								34.58799745550482
							],
							[
								-75.41015625000001,
								35.20972164522136
							],
							[
								-75.38818359375001,
								35.61265081875668
							],
							[
								-75.93750000000003,
								36.862042695087254
							],
							[
								-76.95922851562501,
								36.976226784640936
							],
							[
								-77.77221679687501,
								37.32648861334201
							],
							[
								-77.80517578125001,
								37.95286091815646
							],
							[
								-77.48657226562503,
								38.950865400919994
							],
							[
								-76.82739257812501,
								39.50404070558415
							],
							[
								-76.14074707031251,
								39.54641191968666
							],
							[
								-76.2725830078125,
								39.91816284660933
							],
							[
								-76.22863769531249,
								40.455307212131494
							],
							[
								-76.09680175781247,
								41.21998578493914
							],
							[
								-75.48156738281247,
								41.681117562906444
							],
							[
								-74.9542236328125,
								41.713930073371245
							],
							[
								-75.08605957031249,
								41.05450196329045
							],
							[
								-75.13000488281247,
								40.321419995934335
							],
							[
								-75.61340332031247,
								39.54641191968666
							],
							[
								-74.9102783203125,
								38.899583425982655
							],
							[
								-74.06982421875003,
								39.72408857733368
							],
							[
								-73.927001953125,
								40.47202439692057
							],
							[
								-71.861572265625,
								40.94671366508002
							],
							[
								-71.90551757812501,
								41.09591205639544
							],
							[
								-72.235107421875,
								41.19518982948959
							],
							[
								-73.69628906250004,
								40.85537053192494
							],
							[
								-72.79541015625003,
								41.244772343082026
							],
							[
								-72.33398437500003,
								41.95131994679699
							],
							[
								-72.05932617187501,
								41.95131994679699
							],
							[
								-71.56494140625001,
								41.63186741069745
							],
							[
								-71.31225585937503,
								41.3108238809182
							],
							[
								-70.80688476562501,
								41.145569731009445
							],
							[
								-69.62036132812503,
								41.062786068733026
							],
							[
								-69.88403320312501,
								42.09822241118974
							],
							[
								-71.136474609375,
								42.358543917497
							],
							[
								-72.41088867187503,
								42.309815415686636
							],
							[
								-72.61962890625003,
								42.358543917497
							],
							[
								-73.21289062500003,
								42.46399280017058
							],
							[
								-73.15795898437503,
								43.100982876188496
							],
							[
								-73.04809570312503,
								43.51668853502904
							],
							[
								-73.25683593750001,
								43.88997537383685
							],
							[
								-73.93798828125004,
								43.84245116699034
							],
							[
								-74.11926269531249,
								43.492782808224945
							],
							[
								-74.38293457031249,
								42.85180609584698
							],
							[
								-74.90478515625001,
								42.956422511073306
							],
							[
								-75.02563476562503,
								43.659924074789075
							],
							[
								-75.23437500000001,
								43.723474896114794
							],
							[
								-75.44311523437501,
								43.67581809328339
							],
							[
								-76.14074707031251,
								43.07691312608701
							],
							[
								-77.113037109375,
								42.91620643817353
							],
							[
								-77.464599609375,
								43.22118973298753
							],
							[
								-77.662353515625,
								43.26920624914964
							],
							[
								-77.93701171875,
								43.18114705939968
							],
							[
								-78.4259033203125,
								42.819580715795894
							],
							[
								-78.4259033203125,
								42.43156587257911
							],
							[
								-78.1182861328125,
								41.87774145109669
							],
							[
								-78.1182861328125,
								41.31907562295128
							],
							[
								-78.1182861328125,
								40.72228267283141
							],
							[
								-79.541015625,
								39.70718665682654
							],
							[
								-79.62890625,
								39.26628442213066
							],
							[
								-79.48059082031247,
								38.762650338334154
							],
							[
								-79.30480957031247,
								37.274052809979
							],
							[
								-78.6895751953125,
								36.712467243386214
							],
							[
								-78.7335205078125,
								35.89795019335746
							],
							[
								-79.43664550781249,
								36.32397712011259
							],
							[
								-80.49133300781251,
								36.39475669987376
							],
							[
								-81.19445800781249,
								36.39475669987376
							],
							[
								-81.54602050781249,
								36.39475669987376
							],
							[
								-82.1173095703125,
								35.89795019335746
							],
							[
								-82.42492675781251,
								35.43381992014194
							],
							[
								-82.02941894531249,
								35.146862906756205
							],
							[
								-81.5899658203125,
								34.53371242139559
							],
							[
								-80.93078613281247,
								34.46127728843695
							],
							[
								-80.6671142578125,
								34.93097858831617
							],
							[
								-79.83215332031247,
								35.074964853989485
							],
							[
								-79.61242675781249,
								34.75063980505008
							],
							[
								-79.04113769531251,
								33.95247360616279
							],
							[
								-79.6563720703125,
								33.47727218776026
							],
							[
								-80.22766113281249,
								32.92570748876035
							],
							[
								-81.28234863281247,
								32.37068286611422
							],
							[
								-82.02941894531249,
								31.96148355726848
							],
							[
								-82.02941894531249,
								31.43803717312439
							],
							[
								-81.98547363281251,
								30.760718908944398
							]
						],
						[
							[
								-81.123046875,
								39.842286020743394
							],
							[
								-82.705078125,
								39.70718665682654
							],
							[
								-83.14453125,
								39.436192999314095
							],
							[
								-84.0234375,
								39.57182223734374
							],
							[
								-85.4296875,
								39.639537564366684
							],
							[
								-86.220703125,
								39.16414104768742
							],
							[
								-87.275390625,
								38.41055825094609
							],
							[
								-88.505859375,
								38.20365531807149
							],
							[
								-89.384765625,
								37.85750715625203
							],
							[
								-89.296875,
								37.09023980307208
							],
							[
								-88.9453125,
								36.66841891894786
							],
							[
								-88.154296875,
								36.80928470205937
							],
							[
								-87.5390625,
								37.3002752813443
							],
							[
								-86.8359375,
								37.71859032558816
							],
							[
								-85.69335937499999,
								37.996162679728116
							],
							[
								-85.166015625,
								38.272688535980976
							],
							[
								-84.287109375,
								38.272688535980976
							],
							[
								-83.14453125,
								38.20365531807149
							],
							[
								-81.73828125,
								38.41055825094609
							],
							[
								-81.123046875,
								38.89103282648846
							],
							[
								-80.595703125,
								39.436192999314095
							],
							[
								-81.123046875,
								39.842286020743394
							]
						],
						[
							[
								-92.87841796875,
								37.87485339352928
							],
							[
								-91.93359375,
								37.996162679728116
							],
							[
								-91.07666015625,
								37.71859032558816
							],
							[
								-90.263671875,
								37.64903402157866
							],
							[
								-89.75830078125,
								37.92686760148135
							],
							[
								-89.6484375,
								38.272688535980976
							],
							[
								-89.384765625,
								39.36827914916014
							],
							[
								-87.451171875,
								40.3130432088809
							],
							[
								-86.396484375,
								40.3130432088809
							],
							[
								-86.24267578125,
								40.43022363450862
							],
							[
								-86.24267578125,
								40.613952441166596
							],
							[
								-86.17675781249999,
								40.79717741518766
							],
							[
								-86.2646484375,
								40.84706035607122
							],
							[
								-87.099609375,
								41.11246878918088
							],
							[
								-87.36328125,
								41.705728515237524
							],
							[
								-87.71484375,
								42.21224516288584
							],
							[
								-87.6708984375,
								42.76314586689492
							],
							[
								-87.82470703125,
								43.11702412135048
							],
							[
								-87.802734375,
								43.45291889355465
							],
							[
								-88.505859375,
								43.89789239125797
							],
							[
								-90,
								44.5278427984555
							],
							[
								-90.3515625,
								44.762336674810996
							],
							[
								-90.087890625,
								45.02695045318546
							],
							[
								-89.6484375,
								45.22848059584359
							],
							[
								-89.49462890625,
								45.460130637921004
							],
							[
								-89.56054687499999,
								45.72152152227954
							],
							[
								-90.17578124999999,
								45.81348649679973
							],
							[
								-91.318359375,
								45.9511496866914
							],
							[
								-92.373046875,
								45.9511496866914
							],
							[
								-94.04296874999999,
								46.01222384063236
							],
							[
								-95.2734375,
								45.89000815866184
							],
							[
								-95.2734375,
								46.49839225859763
							],
							[
								-95.185546875,
								47.15984001304432
							],
							[
								-94.5703125,
								47.45780853075031
							],
							[
								-94.02099609375,
								47.754097979680026
							],
							[
								-93.93310546875,
								48.151428143221224
							],
							[
								-94.21875,
								48.356249029540734
							],
							[
								-95.185546875,
								48.45835188280866
							],
							[
								-96.13037109375,
								49.009050809382046
							],
							[
								-99.49218749999999,
								48.980216985374994
							],
							[
								-102.3046875,
								48.922499263758255
							],
							[
								-102.65625,
								47.39834920035926
							],
							[
								-103.18359375,
								46.619261036171515
							],
							[
								-103.095703125,
								45.82879925192134
							],
							[
								-103.095703125,
								45.1510532655634
							],
							[
								-102.12890625,
								44.902577996288876
							],
							[
								-100.72265625,
								43.83452678223682
							],
							[
								-99.31640625,
								43.32517767999296
							],
							[
								-98.96484375,
								42.8115217450979
							],
							[
								-98.525390625,
								41.902277040963696
							],
							[
								-98.26171875,
								40.84706035607122
							],
							[
								-97.998046875,
								40.04443758460856
							],
							[
								-97.646484375,
								38.95940879245423
							],
							[
								-97.734375,
								38.41055825094609
							],
							[
								-98.4375,
								38.20365531807149
							],
							[
								-98.4375,
								36.914764288955936
							],
							[
								-95.712890625,
								36.94989178681327
							],
							[
								-95.7568359375,
								38.324420427006544
							],
							[
								-96.0205078125,
								38.65119833229951
							],
							[
								-95.95458984375,
								39.06184913429154
							],
							[
								-95.2734375,
								39.16414104768742
							],
							[
								-94.658203125,
								38.61687046392973
							],
							[
								-94.2626953125,
								38.18638677411551
							],
							[
								-93.66943359374999,
								37.90953361677018
							],
							[
								-92.87841796875,
								37.87485339352928
							]
						],
						[
							[
								-112.8515625,
								44.10336537791152
							],
							[
								-110.91796875,
								44.41808794374846
							],
							[
								-110.91796875,
								44.793530904744074
							],
							[
								-109.9072265625,
								45.336701909968134
							],
							[
								-107.57812499999999,
								45.598665689820635
							],
							[
								-105.64453124999999,
								45.72152152227954
							],
							[
								-103.447265625,
								47.234489635299184
							],
							[
								-103.447265625,
								47.82790816919327
							],
							[
								-104.32617187499999,
								48.122101028190805
							],
							[
								-105.29296874999999,
								47.53203824675999
							],
							[
								-106.69921875,
								46.63435070293566
							],
							[
								-108.984375,
								46.392411189814645
							],
							[
								-111.09374999999999,
								46.14939437647684
							],
							[
								-112.32421875,
								45.72152152227954
							],
							[
								-113.818359375,
								44.731125592643274
							],
							[
								-112.8515625,
								44.10336537791152
							]
						],
						[
							[
								-117.3779296875,
								44.308126684886126
							],
							[
								-116.91650390625,
								44.11914151643737
							],
							[
								-116.76269531249999,
								43.75522505306928
							],
							[
								-116.47705078125,
								43.691707903073805
							],
							[
								-116.25732421875,
								43.54854811091286
							],
							[
								-115.97167968750001,
								43.54854811091286
							],
							[
								-115.59814453125001,
								44.08758502824516
							],
							[
								-115.42236328124999,
								44.68427737181225
							],
							[
								-114.5654296875,
								45.27488643704891
							],
							[
								-113.90625,
								45.66012730272194
							],
							[
								-113.31298828125,
								46.255846818480315
							],
							[
								-112.763671875,
								46.694667307773116
							],
							[
								-112.236328125,
								46.86019101567027
							],
							[
								-111.4892578125,
								47.44294999517949
							],
							[
								-111.26953125,
								47.53203824675999
							],
							[
								-111.86279296875,
								47.69497434186282
							],
							[
								-112.78564453124999,
								47.70976154266637
							],
							[
								-113.48876953125,
								47.68018294648414
							],
							[
								-114.01611328125,
								47.5913464767971
							],
							[
								-114.54345703125,
								47.62097541515849
							],
							[
								-114.76318359375,
								48.004625021133904
							],
							[
								-114.80712890625,
								48.3416461723746
							],
							[
								-115.07080078125,
								48.56024979174329
							],
							[
								-115.1806640625,
								48.748945343432936
							],
							[
								-115.33447265625,
								48.821332549646634
							],
							[
								-115.48828125000001,
								48.73445537176822
							],
							[
								-115.90576171874999,
								48.66194284607006
							],
							[
								-116.01562499999999,
								48.356249029540734
							],
							[
								-116.52099609375,
								48.25394114463431
							],
							[
								-117.09228515624999,
								48.09275716032736
							],
							[
								-118.27880859375001,
								47.989921667414194
							],
							[
								-119.13574218749999,
								48.004625021133904
							],
							[
								-119.50927734374999,
								47.70976154266637
							],
							[
								-120.16845703125,
								47.635783590864854
							],
							[
								-120.498046875,
								47.82790816919329
							],
							[
								-120.84960937499999,
								48.22467264956519
							],
							[
								-120.76171875,
								48.516604348867475
							],
							[
								-120.69580078125001,
								48.99463598353405
							],
							[
								-123.26660156249999,
								48.99463598353405
							],
							[
								-123.04687499999999,
								48.777912755501845
							],
							[
								-123.20068359374999,
								48.69096039092549
							],
							[
								-123.15673828124999,
								48.4146186174932
							],
							[
								-123.42041015624999,
								48.22467264956519
							],
							[
								-124.07958984375001,
								48.32703913063476
							],
							[
								-124.69482421875,
								48.44377831058802
							],
							[
								-124.93652343749999,
								48.472921272487824
							],
							[
								-124.12353515624999,
								46.604167162931844
							],
							[
								-124.0576171875,
								45.85941212790755
							],
							[
								-124.4091796875,
								43.42100882994726
							],
							[
								-123.74999999999999,
								43.213183300738876
							],
							[
								-123.04687499999999,
								43.27720532212024
							],
							[
								-122.62939453125001,
								43.58039085560784
							],
							[
								-122.49755859375,
								43.94537239244209
							],
							[
								-122.3876953125,
								44.33956524809713
							],
							[
								-122.03613281249999,
								44.55916341529182
							],
							[
								-121.5087890625,
								44.59046718130883
							],
							[
								-120.84960937499999,
								44.59046718130883
							],
							[
								-120.60791015625,
								44.824708282300236
							],
							[
								-120.34423828125,
								44.933696389694674
							],
							[
								-119.794921875,
								44.933696389694674
							],
							[
								-119.46533203125,
								44.77793589631623
							],
							[
								-119.267578125,
								44.55916341529182
							],
							[
								-118.87207031250001,
								44.55916341529182
							],
							[
								-118.10302734374999,
								44.6061127451739
							],
							[
								-117.3779296875,
								44.308126684886126
							]
						],
						[
							[
								-123.4423828125,
								42.27730877423709
							],
							[
								-124.49707031249999,
								42.19596877629178
							],
							[
								-124.27734374999999,
								41.52502957323801
							],
							[
								-124.21142578125,
								40.88029480552824
							],
							[
								-124.541015625,
								40.48038142908172
							],
							[
								-124.4091796875,
								40.17887331434696
							],
							[
								-124.03564453125,
								39.740986355883564
							],
							[
								-123.837890625,
								38.993572058209466
							],
							[
								-123.24462890625,
								38.39333888832238
							],
							[
								-123.134765625,
								38.013476231041935
							],
							[
								-122.51953124999999,
								37.71859032558816
							],
							[
								-122.51953124999999,
								37.3002752813443
							],
							[
								-122.27783203125,
								36.94989178681327
							],
							[
								-121.904296875,
								36.87962060502676
							],
							[
								-121.79443359375,
								36.70365959719456
							],
							[
								-121.37695312499999,
								36.59788913307022
							],
							[
								-120.56396484375,
								36.38591277287651
							],
							[
								-119.90478515625,
								36.26199220445664
							],
							[
								-119.33349609375,
								36.155617833818525
							],
							[
								-118.95996093749999,
								36.26199220445664
							],
							[
								-118.76220703125001,
								36.63316209558658
							],
							[
								-118.27880859375001,
								36.84446074079564
							],
							[
								-117.75146484375,
								36.96744946416934
							],
							[
								-116.98242187499999,
								36.86204269508728
							],
							[
								-116.23535156249999,
								36.79169061907076
							],
							[
								-115.90576171874999,
								37.19533058280065
							],
							[
								-115.79589843749999,
								37.68382032669382
							],
							[
								-116.12548828124999,
								37.94419750075404
							],
							[
								-116.87255859374999,
								38.13455657705411
							],
							[
								-117.70751953125,
								38.09998264736481
							],
							[
								-118.2568359375,
								39.53793974517628
							],
							[
								-118.27880859375001,
								40.59727063442024
							],
							[
								-118.69628906249999,
								41.50857729743935
							],
							[
								-118.65234374999999,
								42.16340342422401
							],
							[
								-118.71826171875,
								42.8115217450979
							],
							[
								-120.78369140624999,
								42.85985981506279
							],
							[
								-121.35498046875,
								42.89206418807337
							],
							[
								-121.83837890625,
								43.02071359427862
							],
							[
								-122.27783203125,
								42.8115217450979
							],
							[
								-122.73925781250001,
								42.53689200787315
							],
							[
								-123.4423828125,
								42.27730877423709
							]
						],
						[
							[
								-114.60937499999999,
								32.509761735919426
							],
							[
								-112.763671875,
								32.175612478499325
							],
							[
								-111.70898437499999,
								32.21280106801518
							],
							[
								-110.74218749999999,
								32.10118973232094
							],
							[
								-110.390625,
								31.57853542647338
							],
							[
								-110.25878906249999,
								31.353636941500987
							],
							[
								-109.423828125,
								31.353636941500987
							],
							[
								-109.423828125,
								31.98944183792288
							],
							[
								-109.51171875,
								32.47269502206151
							],
							[
								-108.6328125,
								32.47269502206151
							],
							[
								-108.19335937499999,
								32.287132632616384
							],
							[
								-107.5341796875,
								32.287132632616384
							],
							[
								-107.0068359375,
								32.91648534731439
							],
							[
								-107.1826171875,
								33.32134852669881
							],
							[
								-107.75390625,
								33.54139466898275
							],
							[
								-108.5009765625,
								33.65120829920497
							],
							[
								-109.1162109375,
								33.65120829920497
							],
							[
								-109.720458984375,
								33.60546961227188
							],
							[
								-110.0830078125,
								33.6420625047537
							],
							[
								-110.72021484375,
								33.99802726234877
							],
							[
								-111.346435546875,
								34.334364487026306
							],
							[
								-112.00561523437499,
								34.74161249883172
							],
							[
								-112.48901367187499,
								35.08395557927643
							],
							[
								-113.0712890625,
								35.17380831799959
							],
							[
								-113.48876953125,
								35.04798673426734
							],
							[
								-113.587646484375,
								34.93097858831627
							],
							[
								-113.466796875,
								34.63320791137959
							],
							[
								-113.0712890625,
								34.23451236236987
							],
							[
								-113.4228515625,
								34.125447565116126
							],
							[
								-113.6865234375,
								34.1890858311724
							],
							[
								-113.818359375,
								34.488447837809304
							],
							[
								-114.06005859375,
								34.63320791137959
							],
							[
								-114.27978515625,
								34.53371242139564
							],
							[
								-114.444580078125,
								34.14363482031264
							],
							[
								-114.2578125,
								33.65120829920497
							],
							[
								-113.64257812499999,
								33.46810795527896
							],
							[
								-112.8955078125,
								33.43144133557529
							],
							[
								-112.6318359375,
								33.063924198120645
							],
							[
								-113.3349609375,
								32.879587173066305
							],
							[
								-114.82910156249999,
								32.95336814579932
							],
							[
								-115.00488281250001,
								32.62087018318113
							],
							[
								-114.60937499999999,
								32.509761735919426
							]
						],
						[
							[
								-109.9072265625,
								34.30714385628804
							],
							[
								-109.45678710937499,
								34.19817309627726
							],
							[
								-109.127197265625,
								34.17090836352573
							],
							[
								-108.78662109375,
								34.17090836352573
							],
							[
								-108.555908203125,
								34.27083595165
							],
							[
								-108.336181640625,
								34.352506668675936
							],
							[
								-108.04504394531249,
								34.39331222316112
							],
							[
								-107.9351806640625,
								34.38877925439021
							],
							[
								-107.81982421874999,
								34.37064492478658
							],
							[
								-107.73193359375,
								34.4069096565206
							],
							[
								-107.720947265625,
								34.488447837809304
							],
							[
								-107.9461669921875,
								34.57895241036948
							],
							[
								-108.017578125,
								34.8047829195724
							],
							[
								-108.094482421875,
								34.912962495216966
							],
							[
								-108.08349609375,
								35.06597313798418
							],
							[
								-108.094482421875,
								35.34425514918409
							],
							[
								-108.28125,
								35.496456056584165
							],
							[
								-108.314208984375,
								35.47856499535729
							],
							[
								-108.358154296875,
								35.47856499535729
							],
							[
								-108.5174560546875,
								35.46514408578589
							],
							[
								-108.65478515625,
								35.38904996691167
							],
							[
								-108.7646484375,
								35.290468565908775
							],
							[
								-109.10522460937499,
								35.209721645221386
							],
							[
								-109.34692382812499,
								35.10193405724606
							],
							[
								-109.79736328125,
								35.137879119634185
							],
							[
								-110.203857421875,
								35.30840140169162
							],
							[
								-110.511474609375,
								35.44277092585766
							],
							[
								-110.885009765625,
								35.66622234103479
							],
							[
								-111.302490234375,
								35.951329861522666
							],
							[
								-111.456298828125,
								35.9157474194997
							],
							[
								-111.588134765625,
								35.782170703266075
							],
							[
								-111.59912109375,
								35.68407153314097
							],
							[
								-111.57714843749999,
								35.523285179107816
							],
							[
								-111.456298828125,
								35.47856499535729
							],
							[
								-111.357421875,
								35.41591492345623
							],
							[
								-111.33544921874999,
								35.27253175660236
							],
							[
								-111.07177734375,
								34.75966612466248
							],
							[
								-109.9072265625,
								34.30714385628804
							]
						]
					]
				},
				"properties":{

					"fill":"#1b00c8",
					"stroke":"#555555",
					"show_on_map":true,
					"fill-opacity":0.5,
					"popupContent":"Climate change has been a key factor in increasing the risk and extent of wildfires across the country. Decreased soil moisture, increased average temperatures and extensive droughts have seen forest fire-prone areas double between 1984 and 2015.",
					"stroke-width":0.5,
					"stroke-opacity":0.5
				}
			}
		]
	},
{
		"name": "Wildfires",
		"type":"FeatureCollection",
		"features":[
			{
				"type":"Feature",
				"geometry":{
					"type":"Polygon",
					"coordinates":[
						[
							[
								-124.53002929687496,
								42.71473218539461
							],
							[
								-124.22241210937499,
								41.31082388091818
							],
							[
								-124.53002929687496,
								40.51379915504413
							],
							[
								-124.48608398437496,
								40.245991504199
							],
							[
								-124.04663085937496,
								39.87601941962114
							],
							[
								-123.82690429687496,
								38.92522904714054
							],
							[
								-123.16772460937497,
								38.134556577054084
							],
							[
								-122.68432617187497,
								37.96152331396616
							],
							[
								-122.50854492187497,
								37.474858084971046
							],
							[
								-122.06909179687496,
								36.914764288955936
							],
							[
								-121.89331054687496,
								36.31512514748051
							],
							[
								-120.66284179687496,
								34.30714385628807
							],
							[
								-119.56420898437497,
								33.284619968887675
							],
							[
								-118.55346679687497,
								33.32134852669881
							],
							[
								-118.07006835937497,
								33.394759218577995
							],
							[
								-117.27905273437496,
								32.657875736955305
							],
							[
								-116.35620117187497,
								32.657875736955305
							],
							[
								-117.10327148437497,
								35.10193405724608
							],
							[
								-117.71850585937496,
								35.85343961959182
							],
							[
								-118.72924804687496,
								36.24427318493909
							],
							[
								-119.25659179687497,
								36.73888412439429
							],
							[
								-120.04760742187497,
								37.125286284966855
							],
							[
								-120.70678710937497,
								38.71980474264237
							],
							[
								-119.60815429687497,
								39.94343646197423
							],
							[
								-119.12475585937497,
								41.1455697310095
							],
							[
								-120.13549804687496,
								42.032974332441405
							],
							[
								-121.58569335937496,
								42.4234565179383
							],
							[
								-120.5419921875,
								43.54854811091286
							],
							[
								-118.38867187500001,
								43.866218006556394
							],
							[
								-117.861328125,
								44.84029065139799
							],
							[
								-118.38867187500001,
								45.583289756006316
							],
							[
								-119.47631835937496,
								46.649436163350245
							],
							[
								-118.50952148437496,
								47.60616304386876
							],
							[
								-117.14721679687497,
								47.457808530750285
							],
							[
								-115.93872070312496,
								47.23448963529921
							],
							[
								-115.41137695312496,
								46.437856895024204
							],
							[
								-116.11450195312499,
								46.22545288226939
							],
							[
								-116.97143554687499,
								46.01222384063236
							],
							[
								-117.19116210937497,
								45.87471224890479
							],
							[
								-118.07006835937497,
								45.706179285330855
							],
							[
								-117.45483398437499,
								44.9336963896947
							],
							[
								-118.10302734374999,
								43.88997537383687
							],
							[
								-117.85034179687497,
								43.61221676817573
							],
							[
								-118.07006835937497,
								43.32517767999296
							],
							[
								-119.03686523437497,
								42.97250158602602
							],
							[
								-119.25659179687497,
								42.48830197960227
							],
							[
								-118.99291992187496,
								42.00032514831626
							],
							[
								-118.99291992187496,
								41.57436130598913
							],
							[
								-118.99291992187496,
								40.48038142908169
							],
							[
								-118.94897460937496,
								39.87601941962114
							],
							[
								-118.55346679687497,
								39.470125122358205
							],
							[
								-117.85034179687497,
								39.53793974517628
							],
							[
								-117.85034179687497,
								38.99357205820949
							],
							[
								-117.54272460937496,
								38.65119833229951
							],
							[
								-116.83959960937497,
								38.238180119798635
							],
							[
								-116.13647460937497,
								37.82280243352756
							],
							[
								-114.29077148437497,
								37.78808138412046
							],
							[
								-114.29077148437497,
								36.13787471840729
							],
							[
								-114.521484375,
								36.08462129606931
							],
							[
								-114.64233398437496,
								35.92464453144099
							],
							[
								-114.59838867187496,
								35.38904996691164
							],
							[
								-114.42260742187499,
								35.29943548054545
							],
							[
								-114.158935546875,
								35.29943548054545
							],
							[
								-113.719482421875,
								35.47856499535729
							],
							[
								-113.53271484375,
								35.44277092585766
							],
							[
								-113.40087890624999,
								35.31736632923788
							],
							[
								-111.08276367187497,
								33.137551192346145
							],
							[
								-109.89624023437497,
								32.36140331527543
							],
							[
								-109.32495117187497,
								32.10118973232092
							],
							[
								-108.35815429687497,
								32.175612478499325
							],
							[
								-107.56713867187497,
								32.287132632616384
							],
							[
								-106.86401367187497,
								32.694865977875075
							],
							[
								-106.90795898437497,
								33.284619968887675
							],
							[
								-107.45727539062497,
								33.97980872872457
							],
							[
								-108.02856445312497,
								34.07086232376631
							],
							[
								-108.64379882812497,
								33.99802726234875
							],
							[
								-110.77514648437496,
								34.34343606848294
							],
							[
								-112.00561523437499,
								35.42486791930558
							],
							[
								-111.87377929687497,
								36.03133177633187
							],
							[
								-112.04956054687497,
								36.59788913307022
							],
							[
								-112.26928710937497,
								37.125286284966855
							],
							[
								-112.48901367187499,
								37.43997405227054
							],
							[
								-112.13745117187497,
								37.85750715625203
							],
							[
								-112.26928710937497,
								38.95940879245423
							],
							[
								-111.74194335937496,
								38.99357205820949
							],
							[
								-110.81909179687497,
								38.85682013474361
							],
							[
								-110.42358398437497,
								38.479394673276474
							],
							[
								-109.23706054687497,
								38.41055825094609
							],
							[
								-108.92944335937497,
								36.98500309285596
							],
							[
								-108.09448242187496,
								36.77409249464195
							],
							[
								-107.47924804687497,
								36.38591277287651
							],
							[
								-106.86401367187497,
								35.92464453144099
							],
							[
								-106.02905273437497,
								35.567980458012094
							],
							[
								-105.01831054687497,
								35.496456056584165
							],
							[
								-104.18334960937497,
								35.53222622770337
							],
							[
								-103.83178710937497,
								35.85343961959182
							],
							[
								-104.05151367187497,
								36.66841891894783
							],
							[
								-104.75463867187497,
								37.33522435930639
							],
							[
								-105.72143554687499,
								37.54457732085582
							],
							[
								-106.11694335937497,
								37.33522435930639
							],
							[
								-106.55639648437499,
								37.23032838760384
							],
							[
								-106.82006835937497,
								37.54457732085582
							],
							[
								-106.90795898437497,
								38.030785693822914
							],
							[
								-107.03979492187499,
								38.58252615935333
							],
							[
								-106.82006835937497,
								38.92522904714054
							],
							[
								-106.33666992187497,
								39.198205348894795
							],
							[
								-106.33666992187497,
								39.740986355883564
							],
							[
								-106.46850585937497,
								40.07807142745007
							],
							[
								-107.12768554687496,
								40.27952566881288
							],
							[
								-107.83081054687497,
								40.413496049701926
							],
							[
								-109.01733398437497,
								40.34654412118006
							],
							[
								-109.58862304687499,
								40.48038142908169
							],
							[
								-109.54467773437497,
								40.81380923056958
							],
							[
								-109.80834960937497,
								41.07935114946904
							],
							[
								-110.20385742187497,
								41.343824581185686
							],
							[
								-110.68725585937497,
								41.07935114946904
							],
							[
								-111.03881835937499,
								41.1455697310095
							],
							[
								-111.52221679687499,
								41.046216814520655
							],
							[
								-111.65405273437497,
								41.244772343082076
							],
							[
								-110.99487304687497,
								41.80407814427237
							],
							[
								-106.20483398437499,
								42.032974332441405
							],
							[
								-106.24877929687497,
								45.27488643704889
							],
							[
								-106.86401367187497,
								45.521743896993634
							],
							[
								-107.34741210937497,
								45.42929873257377
							],
							[
								-107.52319335937497,
								45.120052841530544
							],
							[
								-108.31420898437497,
								45.058001435398225
							],
							[
								-109.06127929687497,
								45.15105326556342
							],
							[
								-109.28100585937497,
								45.39844997630406
							],
							[
								-110.15991210937496,
								45.27488643704889
							],
							[
								-110.99487304687497,
								44.93369638969465
							],
							[
								-111.12670898437496,
								44.402391829093915
							],
							[
								-111.30249023437497,
								43.992814500489914
							],
							[
								-112.22534179687496,
								43.45291889355465
							],
							[
								-113.06030273437496,
								43.42100882994726
							],
							[
								-113.54370117187496,
								43.42100882994726
							],
							[
								-113.45581054687499,
								45.02695045318546
							],
							[
								-113.06030273437496,
								45.089035564831036
							],
							[
								-111.47827148437497,
								45.182036837015865
							],
							[
								-110.68725585937497,
								45.49094569262732
							],
							[
								-109.50073242187497,
								46.042735653846506
							],
							[
								-108.70971679687496,
								46.255846818480315
							],
							[
								-108.09448242187496,
								46.558860303117164
							],
							[
								-107.91870117187497,
								46.830133640447386
							],
							[
								-108.22631835937496,
								47.30903424774779
							],
							[
								-108.97338867187497,
								47.428087261714225
							],
							[
								-109.85229492187497,
								47.69497434186282
							],
							[
								-110.95092773437497,
								48.01932418480121
							],
							[
								-112.00561523437499,
								48.777912755501816
							],
							[
								-114.11499023437497,
								48.748945343432936
							],
							[
								-114.37866210937497,
								48.980216985374966
							],
							[
								-117.14721679687497,
								49.009050809382025
							],
							[
								-119.91577148437496,
								48.95136647094775
							],
							[
								-120.17944335937496,
								48.66194284607006
							],
							[
								-120.48706054687497,
								48.31242790407178
							],
							[
								-120.79467773437497,
								48.10743118848039
							],
							[
								-121.19018554687497,
								48.28319289548349
							],
							[
								-122.06909179687496,
								48.22467264956519
							],
							[
								-122.20092773437497,
								47.96050238891509
							],
							[
								-122.55249023437496,
								47.75409797968
							],
							[
								-122.55249023437496,
								47.368594345213374
							],
							[
								-122.55249023437496,
								47.100044694025215
							],
							[
								-122.37670898437496,
								46.70973594407157
							],
							[
								-121.67358398437497,
								45.706179285330855
							],
							[
								-121.36596679687496,
								45.27488643704889
							],
							[
								-122.24487304687497,
								43.48481212891603
							],
							[
								-122.81616210937496,
								43.004647127794435
							],
							[
								-124.53002929687496,
								42.71473218539461
							]
						],
						[
							[
								-105.5126953125,
								32.509761735919426
							],
							[
								-104.9853515625,
								32.65787573695528
							],
							[
								-104.58984375,
								32.39851580247402
							],
							[
								-104.1064453125,
								32.0639555946604
							],
							[
								-103.4912109375,
								31.466153715024294
							],
							[
								-102.65625,
								31.353636941500987
							],
							[
								-102.12890625,
								31.015278981711266
							],
							[
								-101.29394531249999,
								30.334953881988564
							],
							[
								-100.94238281249999,
								29.57345707301757
							],
							[
								-100.4150390625,
								29.420460341013133
							],
							[
								-99.97558593749999,
								28.9600886880068
							],
							[
								-99.36035156249999,
								28.998531814051795
							],
							[
								-98.59130859375,
								29.152161283318915
							],
							[
								-98.3056640625,
								29.32472016151103
							],
							[
								-98.3056640625,
								29.76437737516313
							],
							[
								-98.4375,
								33.394759218577995
							],
							[
								-99.0966796875,
								33.76088200086917
							],
							[
								-99.404296875,
								34.30714385628804
							],
							[
								-99.31640625,
								35.06597313798418
							],
							[
								-99.7998046875,
								35.209721645221386
							],
							[
								-100.45898437499999,
								35.17380831799959
							],
							[
								-100.8544921875,
								35.02999636902566
							],
							[
								-101.0302734375,
								34.59704151614417
							],
							[
								-100.94238281249999,
								34.19817309627726
							],
							[
								-100.6787109375,
								33.76088200086917
							],
							[
								-100.634765625,
								33.137551192346145
							],
							[
								-100.546875,
								32.509761735919426
							],
							[
								-100.8544921875,
								32.13840869677249
							],
							[
								-101.6455078125,
								32.24997445586331
							],
							[
								-102.1728515625,
								32.43561304116276
							],
							[
								-102.3046875,
								33.063924198120645
							],
							[
								-102.568359375,
								33.578014746143985
							],
							[
								-103.271484375,
								33.61461929233378
							],
							[
								-103.6669921875,
								33.87041555094183
							],
							[
								-104.4140625,
								33.797408767572485
							],
							[
								-105.1171875,
								33.797408767572485
							],
							[
								-105.64453124999999,
								33.8339199536547
							],
							[
								-106.171875,
								33.137551192346145
							],
							[
								-106.083984375,
								32.509761735919426
							],
							[
								-105.5126953125,
								32.509761735919426
							]
						],
						[
							[
								-104.9359130859375,
								30.62845887475364
							],
							[
								-104.853515625,
								30.391830328088137
							],
							[
								-104.69970703125,
								30.221101852485987
							],
							[
								-104.69970703125,
								29.91685223307015
							],
							[
								-104.52392578125,
								29.707139348134145
							],
							[
								-104.26025390625,
								29.554345125748267
							],
							[
								-104.0185546875,
								29.592565403314058
							],
							[
								-103.9306640625,
								29.7643773751631
							],
							[
								-103.51318359375,
								29.7643773751631
							],
							[
								-103.0078125,
								29.7643773751631
							],
							[
								-102.76611328125,
								29.783449456820605
							],
							[
								-102.63427734374999,
								29.954934549656144
							],
							[
								-102.568359375,
								30.164126343161097
							],
							[
								-102.5244140625,
								30.372875188118016
							],
							[
								-102.9638671875,
								30.372875188118016
							],
							[
								-103.46923828124999,
								30.467614102257855
							],
							[
								-103.7548828125,
								30.65681556429287
							],
							[
								-104.08447265624999,
								30.845647420182598
							],
							[
								-104.5458984375,
								30.939924331023445
							],
							[
								-104.83154296875001,
								30.864510226258336
							],
							[
								-104.9359130859375,
								30.62845887475364
							]
						],
						[
							[
								-82.94677734375,
								32.76880048488168
							],
							[
								-82.880859375,
								32.32427558887655
							],
							[
								-83.21044921874999,
								32.143059999988424
							],
							[
								-83.69384765624999,
								32.03136328493756
							],
							[
								-84.13330078124999,
								32.03136328493756
							],
							[
								-84.13330078124999,
								31.658057274089913
							],
							[
								-84.79248046874999,
								30.90693797089463
							],
							[
								-85.45166015624999,
								29.95969381418452
							],
							[
								-85.23193359374999,
								29.692824739380754
							],
							[
								-84.71008300781249,
								29.78821690967894
							],
							[
								-84.26513671874999,
								29.95969381418452
							],
							[
								-83.69384765624999,
								29.654642479663647
							],
							[
								-82.85888671874999,
								29.08017598962318
							],
							[
								-82.72705078124999,
								28.656851034203395
							],
							[
								-82.90283203124999,
								27.921620449508442
							],
							[
								-82.72705078124999,
								27.415662034560974
							],
							[
								-82.144775390625,
								26.42138972529502
							],
							[
								-81.71630859375,
								25.839449402063185
							],
							[
								-81.463623046875,
								25.819671943904044
							],
							[
								-81.265869140625,
								25.58208527870072
							],
							[
								-81.112060546875,
								25.085598897064752
							],
							[
								-80.39794921874999,
								25.130365915065003
							],
							[
								-80.17822265624999,
								25.40854689267053
							],
							[
								-79.95849609374999,
								26.868180902512403
							],
							[
								-80.44189453124999,
								27.84393338607049
							],
							[
								-80.70556640624999,
								28.695406284421967
							],
							[
								-81.4306640625,
								30.486550842588485
							],
							[
								-81.7822265625,
								30.949346915468563
							],
							[
								-81.89208984374999,
								31.62064369245056
							],
							[
								-81.67236328124999,
								32.143059999988424
							],
							[
								-80.88134765624999,
								32.551443528644285
							],
							[
								-80.48583984374999,
								32.810361684869015
							],
							[
								-79.69482421874999,
								33.142150831105326
							],
							[
								-79.38720703124997,
								33.58259116393916
							],
							[
								-79.51904296874999,
								34.275375297643876
							],
							[
								-79.69482421874999,
								34.854382885097905
							],
							[
								-80.17822265624999,
								35.03449433167974
							],
							[
								-80.48583984374999,
								34.384246040152185
							],
							[
								-81.67236328124999,
								34.45674800347806
							],
							[
								-82.63916015624999,
								33.87497640410958
							],
							[
								-83.12255859375,
								33.5963189611327
							],
							[
								-83.232421875,
								33.32134852669881
							],
							[
								-82.94677734375,
								32.76880048488168
							]
						],
						[
							[
								-74.95285034179689,
								38.90172091499793
							],
							[
								-74.86495971679686,
								38.91027022759441
							],
							[
								-74.71115112304689,
								39.06398173012625
							],
							[
								-74.49142456054686,
								39.251397769004924
							],
							[
								-74.33761596679689,
								39.43831424149678
							],
							[
								-74.15084838867188,
								39.70929962338767
							],
							[
								-74.21264648437501,
								39.74626606218367
							],
							[
								-74.20578002929688,
								39.78426800449768
							],
							[
								-74.15222167968751,
								39.85493798853125
							],
							[
								-74.15771484375001,
								39.890772566959534
							],
							[
								-74.13299560546875,
								39.92237576385938
							],
							[
								-74.1412353515625,
								39.92764154592116
							],
							[
								-74.15359497070312,
								39.925535281697286
							],
							[
								-74.1796875,
								39.92237576385938
							],
							[
								-74.22775268554689,
								39.920269337633975
							],
							[
								-74.33212280273438,
								39.86547951378611
							],
							[
								-74.57931518554689,
								39.79376521264885
							],
							[
								-74.99679565429686,
								39.72620102617503
							],
							[
								-75.46920776367188,
								39.540057885763744
							],
							[
								-75.49255371093751,
								39.3810180329452
							],
							[
								-75.32775878906251,
								39.287545585410435
							],
							[
								-75.16159057617186,
								39.157752153690964
							],
							[
								-75.12451171875001,
								39.185433170302076
							],
							[
								-75.06958007812501,
								39.185433170302076
							],
							[
								-75.00915527343751,
								39.16414104768742
							],
							[
								-74.95285034179689,
								39.14923272582708
							],
							[
								-74.95285034179689,
								39.098094501249086
							],
							[
								-74.99679565429686,
								38.96154447940711
							],
							[
								-74.95285034179689,
								38.90172091499793
							]
						]
					]
				},
				"properties":{
					"fill":"#c80013",
					"stroke":"#555555",
					"show_on_map":true,
					"fill-opacity":0.5,
					"popupContent":"As climate change drives sea levels higher and creates more extreme weather events, scientists expect floodplains in the U.S. to grow by approximately 45 percent by 2100. Globally, that same year, areas now home to 200 million people could fall permanently below the high tide line.",
					"stroke-width":0.5,
					"stroke-opacity":0.5
				}
			}
		]
	}
]

export const disasterGallery = [
	{
		"name": "Hurricanes",
		"headerURL": "https://di-disaster-map.s3.amazonaws.com/hurricanes/hurricaneHEADweb.jpg",
		"headerAlt": "Ominous rain cloud from Tropical Storm Marco near Lake Charles, Louisiana",
		"gallery": [
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/hurricanes/hurricane2.jpg",
				"imgAlt": "Local volunteers help board up a business ahead of Hurricane Delta in Lake Charles, Louisiana on October 8, 2020.",
				"caption": "Local volunteers help board up a business ahead of Hurricane Delta in Lake Charles, Louisiana on October 8, 2020."

			},
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/hurricanes/hurricane3.jpg",
				"imgAlt": "(Photo by ANDREW CABALLERO-REYNOLDS/AFP via Getty Images)",
				"caption": "A man inspects the blown-out windows and other damage inside Capitol One Bank Tower in Lake Charles, Louisiana's downtown area after Hurricane Laura hit on August 27, 2020."
			},
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/hurricanes/hurricane5_.jpg",
				"imgAlt": "(Photo by Joe Raedle/Getty Images)",
				"caption": "Mitch Pickering plays his guitar while walking through the Lake Charles, Louisiana downtown area after Hurricane Laura on August 27, 2020."
			},
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/hurricanes/hurricane1.jpg",
				"imgAlt": "(Photo by STRINGER/AFP via Getty Images)",
				"caption": "The destruction left behind after Hurricane Michael passed through a neighborhood in Mexico Beach, Florida in October 2018."
			},
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/hurricanes/hurricane4.jpg",
				"imgAlt": "",
				"caption": "A worker repairs a street light damaged by Hurricane Delta in Cancun, Mexico, on October 8, 2020."
			},
		]
	},
	{
		"name": "Floods",
		"headerURL": "https://di-disaster-map.s3.amazonaws.com/floods/floodHEADweb.jpg",
		"headerAlt": "Flood header from Ready.gov",
		"gallery": [
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/floods/flood1.jpg",
				"imgAlt": "(Photo by Chip Somodevilla/Getty Images)",
				"caption": "During Hurricane Florence in 2018, flooding from the heavy rain forced hundreds to call for emergency rescues in and around New Bern, North Carolina.",
			},
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/floods/flood2_a.jpg",
				"imgAlt": "Getty Images",
				"caption": "Patrick and Soncia King walk through floods from Hurricane Delta on October 10, 2020 heading toward their home, which they were still repairing from Hurricane Laura.",
			},
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/floods/flood3.jpg",
				"imgAlt": "Getty Images",
				"caption": "Floodwater from the Mississippi River rises around a home on June 1, 2019 in West Alton, Missouri.",			
			},
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/floods/flood4.jpg",
				"imgAlt": "Getty Images",
				"caption": "Larry Koser Jr. looks for important papers and heirlooms inside his father's house after it was flooded by heavy rains from Hurricane Harvey on August 29, 2017 in west Houston.",			
			},
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/floods/flood5.jpg",
				"imgAlt": "Getty Images",
				"caption": "Flood waters cover a roadway in Cameron, Louisiana in the aftermath of Hurricane Laura on October 10, 2020.",			
			}
		]
	},
	{
		"name": "Wildfires",
		"headerURL": "https://di-disaster-map.s3.amazonaws.com/wildfires/wildfireHEADweb.jpg",
		"headerAlt": "Wildfire header from FEMA.gov",
		"gallery": [
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/wildfires/wildfire1.jpg",
				"imgAlt": "(Photo by ROB SCHUMACHER/POOL/AFP via Getty Images)",
				"caption": "The melted sign of an Oak Park Motel destroyed by the Beachie Creek Fire in Gates, Oregon as seen on September 13, 2020. The wildfire caused the evacuation of 40,000 residents and killed at least five."
			},
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/wildfires/wildfire2.jpg",
				"imgAlt": "(Photo by David Ryder/Getty Images)",
				"caption": "Bowls of food and water left out for missing pets in a neighborhood destroyed by wildfire on September 13, 2020 in Talent, Oregon. Hundreds of homes in Talent and the surrounding areas were lost in the fires.",
			},
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/wildfires/wildfire3.jpg",
				"imgAlt": "(FEMA photo/Andrea Booher)",
				"caption": "California fire crews set what's called a back burn fire on October 26, 2007. These small, controlled fires consume available fuel so larger blazes can't sweep through. In this case, it aimed to prevent the Poomacha fire from advancing westward."
			},
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/wildfires/wildfire4.jpg",
				"imgAlt": "(Ben Brooks from Fife WA, USA, Wikimedia)",
				"caption": "An airtanker plane drops fire retardant over the advancing Chelan Butte wildfire in Chelan, Washington on August 14, 2015."
			},
			{
				"imgURL": "https://di-disaster-map.s3.amazonaws.com/wildfires/wildfire5.jpg",
				"imgAlt": "(Photo by Mike Lewelling, National Park Service)",
				"caption": "Aerial view of the Alder Fire in Yellowstone National Park in 2013. It was one of five wildfires burning simultaneously in the park at the time.",
			}
		]
	},
]