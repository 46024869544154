import React, { useState } from 'react';

const Key = (props) => {
	const [keyOpen, setKeyOpen] = useState(false)
	const keyClasses = "key-container" + ((!props.mobile || (props.mobile && keyOpen)) ? " open" : "");

	const keys = Object.keys(props.colorReference).map((key, i) => {
		return (
			<div key={`key-item-${i}`} className="flex align-center key-entry">
				<div className="key-color" style={{backgroundColor: props.colorReference[key].chartColor}}/>
				<p className="key-tag">{key}</p>
			</div>
		)
	})

	return (
		<div className={keyClasses} onClick={() => { if (props.mobile) { return setKeyOpen(!keyOpen) } else { return false }}}>
			{
				(props.mobile) ?
					<div className="flex align-center key-entry">
						<p className="legend key-tag">Legend</p>
					</div>
				: null
			}
			{ keys }
		</div>
	)
}

export default Key