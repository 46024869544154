const baseObject = {
	"tableau": 0,
	"video": 1,
	"singleImg": 2,
	"imageGallery": 3,
	"imageReader": 4,
	"title": 5,
	"share": 6,
	"videoCard": 7,
	"cinemagraph": 8,
	"imgTextBox": 9,
	"textBox": 10,
	"KBTextBoxImg": 11,
	"chart": 12,
}

const chartObject = {
	"lines": 0,
	"bar": 1,
	"circleBar": 2, 
}

export { chartObject, } ;
export default baseObject;