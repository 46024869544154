import React, { useState, useEffect, useCallback } from "react";
import { CSSTransition } from 'react-transition-group';

import ShareModule from '../../ShareModule.js';
import VizContainerLong from './VizContainerLong';
import PanelImg from './PanelImg';

import RPDMedia, { colorReference } from './data/RPDMediaALT.js';

import loader from '../../../images/projects/loader.svg';
import down from '../../../images/projects/arrow-down.svg';
import muteIcon from '../../../images/projects/mute.svg';
import volumeIcon from '../../../images/projects/volume.svg';
import './Pact.css'

const RPDDatascroll = (props) => {
	const [imgLoading, setImgLoading] = useState(true);
	const [chartLoading, setChartLoading] = useState(true);
	const [active, setActive] = useState(0);
	const [firstChange, setFirstChange] = useState(false);
	const [data] = useState(RPDMedia);
	const [mobile] = useState(window.innerWidth < 768.8);
	const [width, setWidth] = useState(500);
	const [height, setHeight] = useState(null);
	
	// MUSIC CONSIDERATIONS
	const [mute, setMute] = useState(true);
	const musicRef = React.createRef();
	const audioStatus = (mute) ? muteIcon : volumeIcon;

	useEffect(() => {
		if (mobile) {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
			let vw = window.innerWidth * 0.01;
			document.documentElement.style.setProperty('--vw', `${vw}px`);
			setHeight(vh * 65)
			setWidth(vw * 100)
		} else {
			setHeight(500)
		}
		document.body.classList.add("detour");
		return () => document.body.classList.remove("detour");
	}, [mobile])

	useEffect(() => {
		let preloadedData = data.slides.map((picture, i) => {
		    const img = new Image();
		    (mobile) ? img.src = picture.mobileUrl : img.src = picture.url;
		    if (i === data.slides.length - 1) {
		    	img.onload = () => {
		    		setImgLoading(false)
		    	}
		    }
		    return img
		});

		window.preloadedPictures = preloadedData;

		return () => {
			preloadedData[0].onload = () => {}
		}
	}, [data, setImgLoading, mobile]);

	const handleMute = useCallback((status) => {
		if (status) {
			musicRef.current.play()
		} else {
			musicRef.current.pause()
		}
		return setMute(m => !m)
	},[musicRef])

	const intersectRefs = data.slides.map((tb, i) => {
		const heightConsideration = "60vh";
		const heightStyleAdjustment = (i === 4) ? {height: "710vh"} : {}
		if (i === 0) {
			return <div key={`intersect-${i}`} className="intersect-invisi-div" height={heightConsideration} style={{height: heightConsideration, marginTop: "-100vh"}}/>
		} else if (i === data.slides.length - 1){
			return (
				<div key={`intersect-${i}`} style={{marginTop: 0}} className="intersect-invisi-div pos-rel fit-to-screen">
	 				<div style={{position: "relative"}} className="text-box-default-static top25 pos-abso left0 right0 font-large">
	 					{data.slides[i].textbox.text}
	 				</div>
	 				<ShareModule icons={["Link", "Twitter", "Facebook"]}>
	 					<p>Share this interactive:</p>
	 				</ShareModule>
	 			</div>
			)
		} else {
			return <div key={`intersect-${i}`} style={heightStyleAdjustment} className="invisi-div"/>
		}
	})

	const panelImgs = data.slides.map((panel, i) => {
		const mediaSrc = (mobile) ? panel.mobileUrl : panel.url;
		return <PanelImg key={`panel-img-${i}`} id={i} active={active} altText={"ALT TEXT TEST"} mediaSrc={mediaSrc} />
	})

	const activeProp = (active >= 1 && active < 5)

	return (
		<div style={{height: '2310vh'}} className="interactive-container">
			{
        		(window.top === window) ? 
					<div className="audio-icon">
						<img rel="preload" as="image" className="unselectable" alt="" onClick={() => handleMute(mute)} src={audioStatus} />
					</div>
				: 
        			null
        	}
			{
				(imgLoading && chartLoading) ?
					<div className="blank-loading-screen">
						<img alt="Loading..." className="proj-loader loader noShadow" src={loader}/>
					</div>
				: null
			}

			<div className="static-scrollyteller-container">
				{ panelImgs }
				<CSSTransition 
					in={activeProp} 
					timeout={600} 
					classNames="fade-600-animate"
				>
					<VizContainerLong mobile={mobile} active={active} setActive={setActive} setChartLoading={setChartLoading} firstChange={firstChange} setFirstChange={setFirstChange} slideData={data.slides} colorReference={colorReference} height={height} width={width}/>
				</CSSTransition>
				
				{
					(active === 0 || active === 5) ? 
						<div>
							<div style={{position: "relative"}} className="text-box-default-static top35 pos-abso left0 right0 font-large">
								{data.slides[0].textbox.text}
							</div>
							{
								(!firstChange) ?
								<div className="scroll-prompt">
									Scroll to continue
									<div className="scroll-down">	
										<img alt="Scroll down" src={down} width="100%"/>
									</div>
								</div>
								: null
							}
						</div>
					: null
				}
			</div>
			{ intersectRefs }
			{
        		(window.top === window) ? 
        			<audio onEnded={() => musicRef.current.play()} ref={musicRef} className="background-music-player" src={data.music} />
        		: 
        			null
        	}
		</div>
	)
}

export default RPDDatascroll;