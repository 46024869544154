const colorArray = [
	{	
		name: "Policy Reform",
		color: "purple",
	},
	{	
		name: "Oversight",
		color: "red",
	},
	{	
		name: "Policing Alternative",
		color: "blue",
	},
]

const Color = ({color}) => {
	return (
		<div className="colorEntry flex flex-row align-center">
			<div className={`blob colorCircle ${color.color}`}></div>
			{color.name}
		</div>
	)
}

export default function ColorKey({classes}) {
	const colors = colorArray.map((color) => <Color key={color.name} color={color} />)
	return (
		<div className={`colorContainer ${(classes === "mapKey") ? "mapKey" : ""}`}>
			<div className='tabletColorContainerFlex'>
				{ colors }
			</div>
		</div>
	)
};

		// color: "rgba(74,20,68,1)",
