import React, { useEffect, useRef } from 'react';

const IntersectRef = (props) => {
	const intersectRef = useRef();
	const active = props.active,
		id = props.id,
		firstChange = props.firstChange,
		propsSetActive = props.setActive,
		propsSetFirstChange = props.setFirstChange,
		propsSetAltActive = props.setAltActive;

	const intersectStyles = {
		height: props.height,
		width: '100%',
		marginTop: (props.id === 0 && props.adjustMargin) ? props.adjustMargin : "0"
	}

	useEffect(() => {
		const intersect = intersectRef.current;
		const callback = entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					if (id > active) {
						propsSetActive(id)
						if (id === 1 && !firstChange && propsSetFirstChange) {
							// Creates space for special actions to only trigger on load,
							// but not on scrolling back up to the top! 
							// (EX => A "scroll to continue" tooltip)
							propsSetFirstChange(true);
						}
					}
				} else if (entry.intersectionRatio === 0 && active === id && id !== 0) {
					propsSetActive(id - 1)
				}
			})
		};

		let observer = new IntersectionObserver(callback, {
			root: null,
			rootMargin: "0px",
		});

		observer.observe(intersect);
		return () => observer.unobserve(intersect)
	}, [active, id, firstChange, propsSetActive, propsSetFirstChange])


	return (
		<div className={`intersect-invisi-div ${props.optionalClasses}`} style={intersectStyles} id={`intersect-${props.id}`} ref={intersectRef}>
			{props.children}
		</div>
	)
}

export default IntersectRef;