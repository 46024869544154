import React, { useState } from 'react';

import CaptionModule from './CaptionModule';

const ImagePlaceholder = ({
		classNames = "",
		imgAlt = "",
		imgSrc = "",
		i = "",
		caption = false,
	 	handleClick = () => {},
	}) => {
		const [featureLoad, setFeatureLoad] = useState(false)

	    return (
	    	<div className={classNames} onClick={() => handleClick(i)}>
	        	<div style={{display: featureLoad ? "none" : "block"}} className="placeholder-gradient"/>
	        	<img onLoad={() => setFeatureLoad(true)} style={{display: featureLoad ? "block" : "none"}} width="100%" alt={imgAlt} src={imgSrc}/>
	        	{
	        		(caption) ?
	        			<CaptionModule text={caption}/>
	        		: null
	        	}
		    </div>
	    )
}

export default ImagePlaceholder;