import React, {useRef, useCallback, useState} from 'react';
import muteIcon from '../images/projects/mute.svg';
import volumeIcon from '../images/projects/volume.svg';

const Player2 = ({track, muted, setMuted}) => {
	const musicRef = useRef()
	const audioStatus = (muted) ? muteIcon : volumeIcon;

	const handleMute = useCallback((status) => {
		if (status) {
			musicRef.current.play()
		} else {
			musicRef.current.pause()
		}
		return setMuted(m => !m)
	},[musicRef])

	return (
		<div className="audio-icon player-mode">
			<img className="unselectable" alt="" onClick={() => handleMute(muted)} src={audioStatus} />
			<audio onEnded={() => musicRef.current.play()} ref={musicRef} className="background-music-player" src={track} />
		</div>
	)
}

export default Player2