import * as d3 from 'd3';
import throttle from "lodash/throttle"

import census_data from './data/1_census.csv';
import search_data from './data/2_search_rate.csv';
import hitrate_data from './data/3_hitrate.csv';
import force_data from './data/4_forceUse.csv';

const parseTime = d3.timeParse("%Y");
const csvPicker = {
	"census": census_data,
	"searchRate": search_data,
	"hitrate": hitrate_data,
	"force": force_data,
}
const scaleBandX = d3.scaleBand()
	.range([0, .5 * Math.PI])
	.align(0)  
const scaleRadialY = d3.scaleRadial()
	.domain([0, 55]);

class D3Component {

	containerEl;
	barChartData;
	props;
	svg;
	sectionPositions;
	chartNodes;
	updateFunctions;
	active;
	chartVisible;
	prevActive;
	prevBarChartStep;

	constructor(containerEl, props) {
		this.containerEl = containerEl;
		this.props = props;
		this.active = 0;
		this.chartVisible = false;
		this.prevActive = 0;
		this.prevBarChartStep = 0;
		this.sectionPositions = [];
		this.chartNodes = [];
		this.barChartData = [];
		this.transitionFunctions = [
			this.showCensus,
			this.showLines,
			this.showHitRate,
			this.showForce,
		]
	
		if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
			const scrollFix = d3.select(".App")
			scrollFix
				.on("scroll", throttle(() => this.scrollTracker(this.containerEl, scrollFix), 200));
		} else {
			const scrollFix = d3.select(window)
			scrollFix
				.on("scroll", throttle(() => this.scrollTracker(this.containerEl, false), 200));
		}

		const svgWidth = props.width + props.margin.left + props.margin.right;
		const svgHeight = props.height + props.margin.top + props.margin.bottom;
		this.svg = d3.select(this.containerEl)
			.append("svg")
				.attr("width", (window.innerWidth <= 630) ? '100%' : svgWidth)
			    .attr("height", (window.innerWidth <= 630) ? '100%' : svgHeight - 20)
			    .attr('viewBox','0 0 '+ Math.min(svgWidth,svgHeight)+ ' '+ Math.min(svgWidth,svgHeight) )
			    .attr('preserveAspectRatio','xMinYMin')

		this.setSections(this.sectionPositions);
    	this.buildAllCharts(props.chartColorReference);
	}

	async changeYear(newDomains, targetIndex) {
		const yearArray = ["2002", "2003", "2004", "2005", "2006", "2007", "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016","2017","2018","2019"];
		var y = d3.scaleLinear()
				.domain([0, newDomains[targetIndex]])
				.range([ this.props.height, 0]);
			var x = d3.scaleBand()
				.range([ 0, this.props.width ])
				.domain(this.barChartData.map(function(d) { return d.Race; }))
				.padding(0.2);

			const yearArrayTarget = yearArray[targetIndex]
			const yearHeader = (targetIndex > 0) ? ` - ${yearArrayTarget}` : ""
			this.props.setYearHeader(yearHeader)

			d3.select(".barY")
				.transition()
				.call(d3.axisLeft(y));

		    // update the bars
		    d3.selectAll("rect")
				.data(this.barChartData)
				.transition()
				.duration(400)
				.attr("x", (d) => x(d["Race"]))
				.attr("y", (d) => y(d[yearArrayTarget]))
				.attr("height", (d) => this.props.height - y(d[yearArrayTarget]))
				.attr("width", x.bandwidth());
	}

	async updateChart(progress, active) {
		if (this.active === 1 || this.active === 3) {
			const whichCircle = (this.active === 1) ? 'census' : 'hitrate';
			const paths = d3.select(`.${whichCircle}`)

			scaleBandX.range([0, (1 * progress + .5) * Math.PI])
			const arc = d3.arc()
				.innerRadius(80)
				.outerRadius(function(d) { return scaleRadialY(d['Value']); })
				.startAngle(function(d) { return scaleBandX(d.Race); })
				.endAngle(function(d) { return scaleBandX(d.Race) + scaleBandX.bandwidth(); })
				.padAngle(0.15)
				.padRadius(80)

			paths.selectAll('.circle-bar')
				.transition()
				.ease(d3.easeLinear)
				.attr("d", arc)

			d3.selectAll(`.${whichCircle}-text-anchor`)
				.transition()
				.ease(d3.easeLinear)
				.attr("transform", (d) => "rotate(" + ((scaleBandX(d.Race) + scaleBandX.bandwidth() / 2) * 180 / Math.PI - 90) + ")"+"translate(" + (scaleRadialY(d['Value'])+10) + ",0)")
     				
		} else if (this.active === 2) {
			let path = d3.selectAll(".line");
			path.each(function(d, i){
				let target = d3.select(this)
				let totalLength = target.node().getTotalLength() 
				let progressDrawLength = totalLength * progress;
				target
					.transition()
					.ease(d3.easeLinear)
					.duration(200)
					.attr("stroke-dasharray", (totalLength) + " " + (totalLength))
					.attr("stroke-dashoffset", (totalLength - progressDrawLength))
			})

		} else if (this.active === 4) {
			// CREATE BREAKPOINTS ARRAY
			const dataShifts = [0.04555555555555555, 0.1, 0.15,  0.21, 0.26, 0.32, 0.37, 0.43, 0.49,  0.54, 0.60,  0.66, 0.71, 0.76, 0.81, 0.86, 0.92, 0.94]
			const newDomains = [20, 20, 20, 30, 30, 30, 50, 50, 50, 75, 75, 100, 100, 140, 140, 140, 140, 140];
			// CHECK WHAT SEGMENT the PROGRESS is in, 
			// AND UPDATE TO THAT spot
			const bisectProgress = progress - .01
			let targetIndex = d3.bisect(dataShifts, bisectProgress);
			if (targetIndex == newDomains.length) {
				targetIndex = newDomains.length - 1
			}

			if (targetIndex !== this.prevBarChartStep) {
				this.changeYear(newDomains, targetIndex)
				this.prevBarChartStep = targetIndex;
			}
		}
	}

	scrollTracker(el, mobileScrollAdjust){
		let scrollMetric;

		if (mobileScrollAdjust) {
			const node = mobileScrollAdjust.node()
			scrollMetric = node.scrollTop
		} else {
			scrollMetric = window.scrollY
		}

		if (scrollMetric < this.sectionPositions[0] && this.active !== 0) {
			this.setActiveChart(0);
			this.hideCensus();
		} else if(scrollMetric > this.sectionPositions[0] && scrollMetric < this.sectionPositions[1] && this.active !== 1) {
			if (!this.props.firstChange) {
				this.props.setFirstChange(true);
			}
			this.setActiveChart(1);
		} else if (scrollMetric > this.sectionPositions[1] && scrollMetric < this.sectionPositions[2] && this.active !== 2) {
			this.setActiveChart(2);
		} else if (scrollMetric > this.sectionPositions[2] && scrollMetric < this.sectionPositions[3] && this.active !== 3) {
			this.setActiveChart(3);
		} else if (scrollMetric > this.sectionPositions[3] && scrollMetric < this.sectionPositions[4] && this.active !== 4) {
			this.setActiveChart(4);
		} else if (scrollMetric > this.sectionPositions[4] && this.active !== 5) {
			this.hideForce();
			this.setActiveChart(5);
		}
		
		const topBound = this.sectionPositions[this.active - 1]
		const lowerBound = this.sectionPositions[this.active]
		const totalArea = lowerBound - topBound
		const percentDecimal = (scrollMetric - topBound) / totalArea
		const progress = Math.round(percentDecimal * 100) / 100

		if (progress > 0 && progress <= 1) {
			if (!this.chartVisible) {
				this.fadeInChart(this.active);
			}
			this.updateChart(progress, this.active)
		}
	}

	setSections(sectionPositions) {
		// SectionPositions will be an array containing each sections'
	    // starting position, relative to the top of the page.
	   	var sections;
	    var startPos;
	    sections = d3.selectAll('.invisi-div');
	    sections.each(function (d, i) {
			var top = this.getBoundingClientRect().top;
			if (i === 0) {
				startPos = top;
				sectionPositions.push(top + window.scrollY);
			} else if (i === 3){
				sectionPositions.push(top - startPos);
				let bottom = this.getBoundingClientRect().bottom;
				sectionPositions.push(bottom - startPos - 350);
			} else {
				sectionPositions.push(top - startPos);
			}
	    });
	}

	buildBars = async (csvData, startingOpacity, miscData, chartReference) => {
		var x = d3.scaleBand()
			.range([ 0, this.props.width ])
			.domain(csvData.map(function(d) { return d.Race; }))
			.padding(0.2);
		
		const targetContainer = this.svg.append("g")
			.attr("transform", "translate(" + this.props.margin.left + "," + this.props.margin.top + ")")
			.attr("class", `chart-sub-container ${miscData.csvKey}`)
			.attr("opacity", startingOpacity);

		targetContainer.append("g")
			.attr("transform", "translate(0," + this.props.height + ")")
			.call(d3.axisBottom(x))
			.selectAll("text")
			.attr("transform", "translate(-10,0)rotate(-45)")
			.style("text-anchor", "end");

		// ADD Y axis
		var y = d3.scaleLinear()
			.domain([0, 150])
			.range([ this.props.height, 0]);
		targetContainer.append("g")
			.attr("class", "barY")
			.call(d3.axisLeft(y));

		// ADD Y Axis Label
		targetContainer.append("text")
			.attr("transform", "rotate(-90)")
			.attr("y", 0 - this.props.margin.left + 5)
			.attr("x",0 - (this.props.height / 2))
			.attr("dy", "1em")
			.style("text-anchor", "middle")
			.attr("fill", "white")
			.text('"Use-of-Force" Cases');  

		// APPEND bars
		targetContainer.selectAll("mybar")
		  .data(csvData)
		  .enter()
		  .append("rect")
		    .attr("x", function(d) { return x(d.Race); })
		    .attr("y", function(d) { return y(d["2002"]); })
		    .attr("width", x.bandwidth())
		    .attr("height", (d) => (this.props.height - y(d["2002"])))
		    .attr("fill", (d) => {
		    		return chartReference[d.Race].chartColor 
		    	})
	}

	buildLines = async (csvData, startingOpacity, miscData, chartReference) => {
		const lineObj = chartReference;

		// SET the ranges
		var x = d3.scaleTime().range([0, this.props.width]);
		var y = d3.scaleLinear().range([this.props.height, 0]);

		// BUILD the lines
		Object.keys(chartReference).forEach((chart) => {
			let line = d3.line()
			    .x(function(d) { return x(d.Year); })
			    .y(function(d) { return y(d[chart]); });
			lineObj[chart].dataPlaceholder = line
		})

		// INTERPRET the data
		csvData.forEach((d) => {
			d.Year = parseTime(d.Year);
			d.White = +d.White;
			d.Black = +d.Black;
			d.Hispanic = +d.Hispanic;
			d.Asian = +d.Asian;
			d.Other = +d.Other;
			d["Native American"] = +d["Native American"];
		});
		csvData = csvData.sort((a, b) => +a.Year - +b.Year)

		// SET the domains
		x.domain(d3.extent(csvData, (d) => { return d.Year; }));
		y.domain([0, d3.max(csvData, (d) => { return d.Black; })]);

		// ATTACH the container
		const targetContainer = this.svg.append("g")
			.attr("transform", "translate(" + this.props.margin.left + "," + this.props.margin.top + ")")
			.attr("class", `chart-sub-container ${miscData.csvKey}`)
			.attr("opacity", startingOpacity);

		// APPEND the lines
		Object.keys(lineObj).forEach((chart, i) => {
			targetContainer.append("path")
				.data([csvData])
				.attr("class", "line")
				.attr("d", lineObj[chart].dataPlaceholder)
				.style("stroke", (d) => lineObj[chart].chartColor)
		})

		// ADD X Axis
		targetContainer.append("g")
		  .attr("transform", "translate(0," + this.props.height + ")")
		  .call(d3.axisBottom(x));

		// ADD X Axis Label
		targetContainer.append("text")             
			.attr("transform", "translate(" + ((this.props.width - this.props.margin.left)/2) + " ," + (this.props.height + this.props.margin.top + 30) + ")")
			.style("text-anchor", "middle")
			.attr("fill", "white")
			.text("Year");

		// ADD Y Axis
		targetContainer.append("g")
		  .call(d3.axisLeft(y));

		// ADD Y Axis Label
		targetContainer.append("text")
			.attr("transform", "rotate(-90)")
			.attr("y", 0 - this.props.margin.left)
			.attr("x", 0 - (this.props.height / 2))
			.attr("dy", "1em")
			.style("text-anchor", "middle")
			.attr("fill", "white")
			.text("Searches Reported");    
	}

	buildCircleBar = async (csvData, startingOpacity, miscData, chartReference) => {
		const innerRadius = 80,
	    	outerRadius = Math.min(this.props.width, this.props.height) / 2; 

		// X scale
		const x = scaleBandX				
			.domain(csvData.map(function(d) { return d.Race; }) );

		// Y scale
		const y = scaleRadialY
			.range([innerRadius, outerRadius]) 

		// APPEND the container
		const targetContainer = this.svg.append("g")
				.attr("transform", "translate(" + ((this.props.width / 2) + miscData.offset.left) + "," + ((this.props.height / 2) + miscData.offset.top) + ")")
				.attr("class", `chart-sub-container ${miscData.csvKey}`)
				.attr("opacity", startingOpacity);
			
		// APPREND the bars
		targetContainer.append("g")
			.selectAll("path")
			.data(csvData)
			.enter()
			.append("path")
				.attr("class", "circle-bar")
				// .attr("fill", "#69b3a2")
				.attr("fill", function(d, i) { 
					return chartReference[d.Race].chartColor 
				})
				.transition()
				.attr("d", d3.arc()
				  .innerRadius(innerRadius)
				  .outerRadius(function(d) { return y(d['Value']); })
				  .startAngle(function(d) { return x(d.Race); })
				  .endAngle(function(d) { return x(d.Race) + x.bandwidth(); })
				  .padAngle(0.15)
				  .padRadius(innerRadius))

		// ADD labels
		targetContainer.append("g")
			.selectAll("g")
			.data(csvData)
			.enter()
			.append("g")
				.attr("class", `${miscData.csvKey}-text-anchor`)
				.attr("text-anchor", function(d) { return (x(d.Race) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) < Math.PI ? "end" : "start"; })
				.attr("transform", function(d) { return "rotate(" + ((x(d.Race) + x.bandwidth() / 2) * 180 / Math.PI - 90) + ")"+"translate(" + (y(d['Value'])+10) + ",0)"; })
			.append("text")
				.text(function(d){return(`${d.Race} - ${d.Value}%`)})
				.attr("transform", function(d) { return (x(d.Race) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) < Math.PI ? "rotate(180)" : "rotate(0)"; })
				.style("font-size", "15px")
				.style("fill", "white")
				.attr("alignment-baseline", "middle")

	}

	buildAllCharts = async (colorReference) => {
		const { svg, props: { mainData, width, height, percentage, setChartLoading } } = this;

		var files = [d3.csv(csvPicker[mainData[0].chart.csvKey]), d3.csv(csvPicker[mainData[1].chart.csvKey]), d3.csv(csvPicker[mainData[2].chart.csvKey]), d3.csv(csvPicker[mainData[3].chart.csvKey])];

		Promise.all(files).then((csvData) => {
  			this.props.setChartLoading(false)
  			this.barChartData = csvData[3];
  			this.buildCircleBar(csvData[0], 0, mainData[0].chart, colorReference)
			this.buildLines(csvData[1], 0, mainData[1].chart, colorReference)
			this.buildCircleBar(csvData[2], 0, mainData[2].chart, colorReference)
			this.buildBars(csvData[3], 0,  mainData[3].chart, colorReference)
		})
	}

	fadeInChart(active) {
		const charts = [".census", ".searchRate", ".hitrate", ".force"]

		d3.select(charts[active - 1])
			.transition()
			.ease(d3.easeLinear)
			.duration(1300)
			.delay(1550)
			.attr("opacity",1);
	}
	hideCensus(){
		d3.select(".census")
			.transition()
			.duration(750)
			.attr("opacity",0);
	}
	showCensus(){
		d3.select(".searchRate")
			.transition()
			.duration(750)
			.attr("opacity",0);
	}
	showLines(){
		d3.select(".census")
			.transition()
			.duration(750)
			.attr("opacity",0);

		d3.select(".hitrate")
			.transition()
			.duration(750)
			.attr("opacity",0);
	}
	showHitRate(){
		d3.select(".force")
			.transition()
			.duration(750)
			.attr("opacity",0);

		d3.select(".searchRate")
			.transition()
			.duration(750)
			.attr("opacity",0);
	}
	showForce(){
		d3.select(".hitrate")
			.transition()
			.duration(750)
			.attr("opacity",0);
	}
	hideForce() {
		d3.select(".force")
			.transition()
			.duration(750)
			.attr("opacity",0);
	}

	setActiveChart = (id) => {
		// SETS ACTIVE locally
		// & SENDS DATA upstairs to React
		this.chartVisible = false;
		this.prevActive = this.active;
		this.active = id;
		this.props.setActive(id);

		if (this.active > 0 && this.active < 5) {
			// NOTE: This active doesn't take into account the intro,
			// While REACT does! So we have subtract from the active here:
			const charts = d3.selectAll(".chart-sub-container").nodes()
			const chartActive = this.active - 1;
			const prevActive = this.prevActive - 1;

			const sign = (chartActive - prevActive) < 0 ? -1 : 1;
			const scrolledSections = d3.range(prevActive + sign, chartActive + sign, sign);
			scrolledSections.forEach((i) => {
				this.transitionFunctions[i]();
			});
		}
	}

	// setActiveDatapoint = (d, i) => {
	// 	d3.select(d.originalTarget)
	// 		.style('fill', 'yellow')
	// 			// this.props.onDatapointClick(d);

	// 	// d3.select(nodes[i]).style('fill', 'yellow');
	// 	this.props.onDatapointClick(i);
	// }

	// resize = (width, height) => {
	// 	const { svg } = this;
	// 	svg.attr('width', width)
	// 		.attr('height', height);
	// 	svg.selectAll('circle')
	// 		.attr('cx', () => Math.random() * width)
	// 		.attr('cy', () => Math.random() * height);
	// }
}

export default D3Component;