import baseObject from '../../../utilities/data/mediaTapperBaseObj.js';

const acConspiracyMedia = {
	"music": "https://blm-protest-media.s3.amazonaws.com/Featurettes_looping.mp3",
	"opening": {
		"height": "",
		"slides":[
			{
				"elType": baseObject.singleImg,
				"url": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/fakeCrisis.jpg",
				"mobileUrl": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/mobile/fakeCrisisMOBILE.jpg",
				"alt": "Conspiracy protest",
				"textbox": {
					"text": "As scientists and politicians grapple with the best way to combat COVID-19, a wealth of online conspiracies have exploded across the internet proclaiming to have discovered the truth about the virus.",
					"placement": {
						"x": null,
						"y": "40vh",
						"absolute": true
					}
				}
			},
			{
				"elType": baseObject.singleImg,
				"url": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/dino.jpg",
				"mobileUrl": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/mobile/dinoMOBILE.jpg",
				"alt": "Conspiracy protest",
				"textbox": {
					"text": "The narratives vary wildly, but if you live in the U.S. chances are high you’ve seen at least one. A June 2020 Pew Research survey found 71% of Americans had heard a theory that the pandemic was planned — and 25% believe it’s true.",
					"placement": {
						"x": null,
						"y": "250vh",
						"absolute": true
					}
				}		
			},
			{
				"elType": baseObject.singleImg,
				"url": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/3_mask.jpg",
				"mobileUrl": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/mobile/3_maskMOBILE.jpg",
				"alt": "Conspiracy protest",
				"textbox": {
					"text": "This misinformation has clouded official narratives around the virus and lent undue legitimacy to movements opposing mask-wearing, social distancing and vaccines. But this aggressive skepticism is not unique to COVID.",
					"placement": {
						"x": null,
						"y": "410vh",
						"absolute": true
					}
				}		
			},
			{
				"elType": baseObject.video,
				"url": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/interviews/Stephen1.mp4",
				"mobileUrl": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/interviews/mobile/Stephen1MOBILE.mp4",
				"playText": "WHY IS COVID-19 SUCH A HOTBED FOR CONSPIRACY? ",
				"textbox": {
					"text": "",
					"placement": {
						"x": null,
						"y": null,
						"absolute": true
					}
				}
			},
			{
				"elType": baseObject.singleImg,
				"url": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/4_illusoryTruth.jpg",
				"mobileUrl": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/mobile/4_illusoryTruthMOBILE.jpg",
				"alt": "Conspiracy protest",
				"textbox": {
					"text": "An array of psychological tendencies encourage the spread of conspiracy. The illusory truth effect, for example, can make certain falsehoods start to feel true overtime if we’re exposed to them repeatedly.",
					"placement": {
						"x": null,
						"y": "710vh",
						"absolute": true
					}
				}
			},
			{
				"elType": baseObject.singleImg,
				"url": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/5_fear.jpg",
				"mobileUrl": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/mobile/5_fearMOBILE.jpg",
				"alt": "Conspiracy protest",
				"textbox": {
					"text": "Fear can also suppress our critical thinking skills, which is why you might find otherwise rational people sharing theories that don’t feel completely tethered to reality.",
					"placement": {
						"x": null,
						"y": "860vh",
						"absolute": true
					}
				}
			},
			{
				"elType": baseObject.video,
				"url": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/interviews/Tara1.mp4",
				"mobileUrl": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/interviews/mobile/Tara1MOBILE.mp4",
				"playText": "HOW DOES FEAR ENCOURAGE THE SPREAD OF CONSPIRACY? ",
				"textbox": {
					"text": "",
					"placement": {
						"x": null,
						"y": null,
						"absolute": true
					}
				}
			},
			{
				"elType": baseObject.singleImg,
				"url": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/6_self-sealing.jpg",
				"mobileUrl": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/mobile/6_self-sealingMOBILE.jpg",
				"alt": "Conspiracy protest",
				"textbox": {
					"text": "Conspiracy theories are also immensely difficult to disprove because of a unique “self-sealing quality” that they have. Presenting contrary evidence to someone who is conspiracy-minded sometimes only serves to bolster their thinking.",
					"placement": {
						"x": null,
						"y": "1160vh",
						"absolute": true
					}
				}
			},
			{
				"elType": baseObject.video,
				"url": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/interviews/Stephen2.mp4",
				"mobileUrl": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/interviews/mobile/Stephen2MOBILE.mp4",
				"playText": "THE DIFFERENCE BETWEEN A SKEPTIC AND A CONSPIRACIST ",
				"textbox": {
					"text": "",
					"placement": {
						"x": null,
						"y": null,
						"absolute": true
					}
				}
			},
			{
				"elType": baseObject.singleImg,
				"url": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/7_vaxx.jpg",
				"mobileUrl": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/mobile/7_vaxxMOBILE.jpg",
				"alt": "Conspiracy protest",
				"textbox": {
					"text": "In regards to vaccines specifically, the anti-vaxx movement, which has been around as long as vaccines themselves, will undoubtedly complicate drug rollouts. This risk is highest in conservative states, several of which saw major case spikes in the summer of 2020.",
					"placement": {
						"x": null,
						"y": "1460vh",
						"absolute": true
					}
				}
			},
			{
				"elType": baseObject.video,
				"url": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/interviews/Stephen3.mp4",
				"mobileUrl": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/interviews/mobile/Stephen3MOBILE.mp4",
				"playText": "THE LINK BETWEEN CONSERVATIVISM AND THE ANTI-VAXX MOVEMENT ",
				"textbox": {
					"text": "",
					"placement": {
						"x": null,
						"y": null,
						"absolute": true
					}
				}
			},
			{
				"elType": baseObject.singleImg,
				"url": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/8_hope.jpg",
				"mobileUrl": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/mobile/8_hopeMOBILE.jpg",
				"alt": "Conspiracy protest",
				"textbox": {
					"text": "But the behaviors that allow conspiracy theories to proliferate also reveal some points of hope.",
					"placement": {
						"x": null,
						"y": "1760vh",
						"absolute": true
					}
				}
			},
			{
				"elType": baseObject.singleImg,
				"url": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/9_repeat.jpg",
				"mobileUrl": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/imgs/mobile/9_repeatMOBILE.jpg",
				"alt": "Conspiracy protest",
				"textbox": {
					"text": "If repeated falsehoods can appear true, then circulating well-documented, thoroughly investigated reports may have a similar effect.",
					"placement": {
						"x": null,
						"y": "1910vh",
						"absolute": true
					}
				}
			},
			{
				"elType": baseObject.video,
				"url": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/interviews/Tara2.mp4",
				"mobileUrl": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/interviews/mobile/Tara2MOBILE.mp4",
				"playText": "HOW CAN PEOPLE SLOW THE SPREAD OF CONSPIRACIST THINKING? ",
				"textbox": {
					"text": "",
					"placement": {
						"x": null,
						"y": null,
						"absolute": true
					}
				}
			},
			{
				"elType": baseObject.singleImg,
				"url": "",
				"mobileUrl": "",
				"textbox": {
					"text": "",
					"placement": {
						"x": null,
						"y": "2250vh",
						"absolute": true
					}
				}
			},
		],
	},
};

export default acConspiracyMedia;