import React, {useState, useCallback} from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import ImagePlaceholder from '../ImagePlaceholder';

import advance from '../../images/projects/advance.svg';
import prev from '../../images/projects/prev.svg';

const DisasterGallery = (props) => {
	const [featureIndex, setFeatureIndex] = useState(0)
	const galleryLength = props.data.gallery.length - 1;

	const switchCover = useCallback((next) => {
        if (next) {
        	 if (featureIndex < galleryLength) {
	            setFeatureIndex(featureIndex + 1)
	        } else {
	            setFeatureIndex(0)
	        }
        } else {
	        if (featureIndex > 0) {
		        setFeatureIndex(featureIndex - 1)
		    } else {
		        setFeatureIndex(galleryLength)
		    }
        }
    }, [featureIndex, galleryLength]);

    const thumbnails = props.data.gallery.map((thumb, i) => <ImagePlaceholder key={`${props.data.name}-thumbnail-${i}`} i={i} classNames={"gallery-thumbnail"} handleClick={setFeatureIndex} imgSrc={thumb.imgURL} imgAlt={thumb.imgAlt} />);

	return (
		<div>
			<h2>Gallery</h2>
		    <div>
		        <div>
					<SwitchTransition>
                        <CSSTransition 
                            key={featureIndex} 
                            timeout={250} 
                            classNames="fade-animate"
                        >
                       		<ImagePlaceholder classNames={"disaster-lightbox-feature"} captions={true} imgSrc={props.data.gallery[featureIndex].imgURL} caption={props.data.gallery[featureIndex].caption} imgAlt={props.data.gallery[featureIndex].imgAlt} />
                        </CSSTransition>
                    </SwitchTransition>		        
                </div>
                <div className="gallery-controls flex">
	                <div className="pointer" onClick={() => switchCover(false)} style={{width: ((props.mobile) ? "10vw" : "3vw")}}>
			        	<img alt="Previous gallery item" src={prev}/>
			        </div>
			        <div className="pointer" onClick={() => switchCover(true)} style={{width: ((props.mobile) ? "10vw" : "3vw")}}>
			            <img alt="Next gallery item" src={advance}/>
			        </div>
		        </div>
		    </div>
		    <div>
		        <div className="library-grid">
		        	{thumbnails}
		        </div>
		    </div>
		</div>
	)
}

export default DisasterGallery;