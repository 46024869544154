import React, { useState, useEffect, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';
// import { Helmet } from 'react-helmet';
import TimelinePanel from './TimelinePanel';

import './TwitterTimeline.css';
import timelinePanelData from './data/timelinePanels.js';

import timeline from '../../../images/detours/tp-twitter-timeline/clock.svg';
// import timeline from '../../../images/detours/tp-twitter-timeline/timeline.svg';
import loader from '../../../images/projects/loader.svg';

const TwitterTimeline = () => {
	const [data] = useState(timelinePanelData);
	const [active, setActive] = useState(false);
	const [minify, setMinify] = useState(false);
	const [firstChange, setFirstChange] = useState(false);
	const [mobile] = useState(window.innerWidth < 768.8);
	const [imgLoading, setImgLoading] = useState(true);
	const [tweetLoading, setTweetLoading] = useState(true);

	useEffect(() => {
		window.twttr = (function(d, s, id) {
			var js, fjs = d.getElementsByTagName(s)[0],
			t = window.twttr || {};
			if (d.getElementById(id)) return t;
			js = d.createElement(s);
			js.id = id;
			js.src = "https://platform.twitter.com/widgets.js";
			fjs.parentNode.insertBefore(js, fjs);

			t._e = [];
			t.ready = function(f) {
				t._e.push(f);
			};
			return t;
		} (document, "script", "twitter-wjs"));
		let timeout;
		if (window.twttr) {
			window.twttr.ready(
				function (twttr){
					twttr.events.bind('loaded', function (event) {
						event.widgets.forEach((widget, i) => {
							if (i === event.widgets.length - 1) {
								setTweetLoading(false)
							}
						});
					});
				}
			)
			timeout = setTimeout(() => {
				if (!window.twttr.ready()) {
					setTweetLoading(false)
				}
			}, 5000)
		}
		return () => {
			clearTimeout(timeout)
		}
	}, []);

	useEffect(() => {
		let preloadedData = data.map((picture, i) => {
		    const img = new Image();
		    (mobile) ? img.src = picture.bgMobile : img.src = picture.bgImg;
		    if (i === data.length - 1) {
		    	img.onload = () => {
		    		setImgLoading(false)
		    	}
		    }
		    return img
		});

		window.preloadedPictures = preloadedData;

		return () => {
			preloadedData[0].onload = () => {}
		}
	}, [data, setImgLoading, mobile]);

	const handleViewChange = useCallback(() => {
		if (!firstChange) {
			setFirstChange(true)
		}
		setActive(!active)
		setMinify(!minify)
		setTimeout(() => {
			setMinify(false)
		}, 2250)
	}, [active, minify, setActive, setMinify, firstChange, setFirstChange])

	const altTimeline = timelinePanelData.slice(1).map((panel, i) => {
		if (i === timelinePanelData.length - 2) {
			const imgSrc = (mobile) ? panel.bgMobile : panel.bgImg;
			return <TimelinePanel key={`timeline-panel-${i}`} year={panel.year} active={active} minify={minify} panelBG={imgSrc}>
				<div className="padding-1x">
					<p>Akiel's name carries incredible weight in Raleigh. It can still be heard at local protests, on social media, and on the frontlines of struggles for racial justice around the country. D.C. Twiddy, the police officer who took Akiel's life, remains a visible part of the Raleigh Police Department. Activists continue to find opportunities to remind the community of his actions in 2016.</p>
					<div onClick={() => handleViewChange()} className="pact-timeline-icon-container cursor-pointer">
						Jump to 2016
						<img width="15%" alt="Jump to 2016" src={timeline}/>
					</div>
				</div>
				<div className="twitter-container">
					<a className="twitter-timeline" data-tweet-limit="21" data-theme="dark" data-chrome="noheader transparent" href="https://twitter.com/exploreframe/timelines/1376647512560373764?ref_src=twsrc%5Etfw"><span>Twitter had some trouble loading.</span> <span className="underline">Check out the timeline here.</span></a>			
				</div>
			</TimelinePanel>
		}
		return <TimelinePanel key={`timeline-panel-${i}`} year={panel.year} panelBG={panel.bgImg}/>	
	})

	return(
		<div >
			{
				(imgLoading || tweetLoading) ?
					<div className="blank-loading-screen">
						<img alt="Loading..." className="proj-loader loader noShadow" src={loader}/>
					</div>
				: null
			}
			<CSSTransition 
				in={active}
				timeout={2200}
				classNames="slide-animation"
			>

			<div className="twitter-timeline-container"> 
				<TimelinePanel year={2016} active={active} minify={minify} panelBG={(mobile) ? timelinePanelData[0].bgMobile : timelinePanelData[0].bgImg}>
					<div className="padding-1x">
						<p>Akiel Denkins' death was an immediate rallying point for the community. Vigils and protests filled the streets, and the backyard where he died was transformed into a memorial. Four years later, Akiel has not been forgotten.</p>
						<div>
							<div onClick={() => handleViewChange()} className="pact-timeline-icon-container cursor-pointer">
								Jump to 2020
								<img width="15%" alt="Jump to 2020" src={timeline}/>
							</div>
						</div>
					</div>
					<div className="twitter-container">
						<a className="twitter-timeline" data-theme="dark" data-tweet-limit="21" data-chrome="noheader transparent" href="https://twitter.com/exploreframe/timelines/1360037064662798337?ref_src=twsrc%5Etfw"><span>Twitter had some trouble loading.</span> <span className="underline">Check out the timeline here.</span></a>				
					</div>
				</TimelinePanel>
				{ altTimeline }
			</div>

			</CSSTransition>
		</div>
	)

}

export default TwitterTimeline