import React, { useState, useEffect, useCallback } from "react";
import { CSSTransition } from 'react-transition-group';


const PanelImg = (props) => {

	const scrollytellerStyles = {position: "fixed", left: "0", filter: "brightness(0.3) grayscale(100%)", pointerEvents: "none"}
	const inProp = (props.id === props.active)

	return (
		<CSSTransition 
			in={inProp}
			timeout={600} 
			classNames="fade-600-animate"
		>
			<img alt={props.altText} className={`static-scrollyteller-img ${(props.id === 0) ? "opacity-1" : "opacity-0"}`} style={scrollytellerStyles} src={props.mediaSrc} />
		</CSSTransition>
	)
}

export default PanelImg