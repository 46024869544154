import React from 'react';
import {Route, Switch, BrowserRouter, Redirect} from 'react-router-dom';

// import LinkTree from './components/LinkTree';
// import Busted from './components/Busted';
import ACTimeline from './components/Antibody/Timeline/ACTimeline';
import ACConspiracy from './components/Antibody/Conspiracy/Conspiracy';
import DisasterMap from './components/DisappearingIsle/DisasterMap';
import RPDDatascroll from './components/Pact/Datascroll/RPDDatascroll';
import AkielTwitter from './components/Pact/TwitterTimeline/TwitterTimeline';
import ReformMap from './components/Pact/ReformMap/ReformMap';
// import TimelineMain from './components/Confiamento/PandemicTimelines/TimelineMain';

import F from './images/F.png'

import './App.css';

function App() {
  return (
    <div className="App">
      {
        (window.top === window) ? 
          <a href="https://frame.media/" target="_blank" rel="noopener noreferrer">
            <div className="nav-F"> 
              <img alt="Frame home" src={F} width="100%"/>
            </div>
          </a>
        :
          null
      }
        <BrowserRouter>
    		  <Switch>
            <Route exact path="/antibody-cure/pandemic-timeline" component={ACTimeline} />
            <Route exact path="/antibody-cure/vaccine-conspiracy-theories" component={ACConspiracy} />
            <Route exact path="/raleigh-pact/overpolicing-in-raleigh" component={RPDDatascroll} />
            <Route exact path="/raleigh-pact/akiel-denkins-timeline" component={AkielTwitter} />
            <Route exact path="/raleigh-pact/police-reform-map" component={ReformMap} />
{/*            <Route exact path="/confiamento/pandemic-timelines" component={TimelineMain} />
*/}            
            <Route exact path="/disappearing-isle/environmental-risk-map" component={DisasterMap}/>
            <Route exact path="/disappearing-isle/disaster-map">
              <Redirect to="/disappearing-isle/environmental-risk-map" />
            </Route>
          {
            //<Route component={Busted}/>
          }
          </Switch>
      	</BrowserRouter>
    </div>
  );
}

export default App;
