import React from 'react';

function TextBox(props) {
	const position = {};

	if (props.data.placement.absolute) {
		position.position = "absolute";
		position.top = props.data.placement.y;
		position.left = "0";
		position.right = "0";
		position.margin = "auto";
	}

	return (
		<div style={position} className="text-box-default-static">
			{props.data.text}
		</div>
	)
}

export default TextBox;