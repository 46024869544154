import React, { useState, useEffect, useCallback, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useThrottle } from "./utilities/utilityFunctions.js";

import playButton from '../images/projects/play.svg';
import pause2 from '../images/projects/pause2.svg';
import forward10 from '../images/projects/forward-10.svg';
import back10 from '../images/projects/back-10.svg';

function TapperCircleTextPlayer({src, muted, muteGesture, setMuteGesture, playText, handleTap, play}) {
	const [paused, setPaused] = useState(true)
	const [controlVis, setControlVis] = useState(false)
	const [featureLoad, setFeatureLoad] = useState(false)
	// const [lowerThirdFade, setLowerThirdFade] = useState(false)
	const vidRef = useRef();

	//PLAY PAUSE AND SCRUB
	const playVid = useCallback(() => {
		// if (muted && muteGesture) {
		// 	vidRef.current.muted = true
		// 	vidRef.current.setAttribute("muted", "")
		// 	vidRef.current.autoplay = true;
		// 	setTimeout(() => {
		// 		// player.play() might return a promise but it's not guaranteed crossbrowser.
		// 		const promise = vidRef.current.play();
		// 		// let's play safe to ensure that if we do have a promise
		// 		if (promise.then) {
		// 			promise
		// 			.then(() => {
		// 				console.log("WELL")
		// 			})
		// 			.catch(() => {
		// 				console.log("NOPE")
		// 			    // if promise fails, hide the video and fallback to <img> tag
		// 			    // videoParentRef.current.style.display = "none";
		// 			    // setShouldUseImage(true);
		// 			});
		// 		}
	 //        }, 0);
		// }
		
		// if (muted && window.top === window) {
		// 	vidRef.current.muted = muted
		// }
		
		(paused) ? vidRef.current.play() : vidRef.current.pause()
		setPaused(!paused);
		setControlVis(c => !c);
	}, [paused])

	// useEffect(() => {
	// 	// if (!paused) {
	// 	// console.log("Muted changed.", muted, muteGesture)
	// 	// vidRef.current.muted = muted
	// 	// if (!muted) {
	// 	// 	setMuteGesture(false)
	// 	// }
	// 	// }

	// }, [muted])
	useEffect(() => {
		// if (!muted) {
		// 	setMuteGesture(false)
		// }
		if (play) {
			vidRef.current.muted = muted;
		}
	}, [muted])

	const resetListener = useCallback(() => {
		setPaused(true);
		// clearTimeout(fadeTimer.current);
		if (controlVis) setControlVis(false);
		vidRef.current.currentTime = 0;

		// setSlideActive(a => a + 1)
		handleTap(true)
	}, [controlVis])

	const scrubSeconds = useCallback((secs) => {
		let time = vidRef.current.currentTime + secs;
		vidRef.current.currentTime = time;
	}, [])

	useEffect(() => {
		const vid = vidRef.current
		vid.addEventListener('ended', resetListener);
		return () => vid.removeEventListener('ended', resetListener);
	}, [resetListener])

	useEffect(() => {
		if (play === true) {
			playVid()
		} else {
			if (!paused) {
				playVid()
				vidRef.current.currentTime = 0;
			}
		}
	}, [play])

	// useEffect(() => {
	// 	let timeOut;
	// 	if (lowerThirdFade === true) {
	// 		console.log("TIMER SET!")
	// 		timeOut = setTimeout(() => {
	// 			console.log("FALSE!")
	// 			setLowerThirdFade(false)
	// 		}, 2000)
	// 	}
	// 	return () => clearTimeout(timeOut)
	// }, [lowerThirdFade, setLowerThirdFade])

	// useEffect(() => {
	// 	if (featureLoad) {
	// 		setLowerThirdFade(true)
	// 	}
	// }, [featureLoad, setLowerThirdFade])

	//CREATE CIRCULAR TEXT
	// const circularText = useCallback((txt, radius) => {
	// 	txt = txt.split("");

	// 	let deg = 360 / txt.length,
	// 	origin = 0;

	// 	return txt.map((ea, i) => {
	// 		if (i > 0) {
	// 			origin += deg;
	// 		}
	// 		return <p key={`letter-${i}-${ea}`} style={{height:`${radius}px`, position:`absolute`, transform:`rotate(${origin}deg)`, fontSize: "2em", transformOrigin:`0% 100%`}}>{ea}</p>
	// 	});
	// }, [])

	return (
		<div className="slideImg">
			<div style={{display: featureLoad ? "none" : paused ? "none": "block"}} className="bootstrap-loader"></div>
			<video 
				muted={muteGesture}
				onCanPlay={() => setFeatureLoad(true)} 
				onClick={() => !paused ? setControlVis(true) : null} 
				ref={vidRef}
				playsInline 
				className="static-scrollyteller-img vid tapper" src={src.vid} 
			/>

			{/*<CSSTransition in={paused} timeout={200} unmountOnExit classNames="lightbox-animate">
				<div style={{zIndex: "10000"}} onClick={() => playVid()} className="bespoke-play circle">
					<img alt="Play button" className="pos-abso margin-auto left0 right0 top0 bottom0" width="50%" src={ playButton } />
				</div>
			</CSSTransition>*/}

			{/*<CSSTransition in={controlVis} timeout={300} unmountOnExit classNames="lightbox-animate">
				<div className="bespoke-controls">
					<div onClick={() => scrubSeconds(-10)} className="bespoke-player-icon back-10">
						<img alt="Rewind 10 seconds" src={back10}/>
					</div>
					<div onClick={() => playVid()} className="bespoke-player-icon pause">
						<img alt="Pause" src={pause2}/>
					</div>
					<div onClick={() => scrubSeconds(10)} className="bespoke-player-icon forward-10">
						<img alt="Fast forward 10 seconds" src={forward10} />
					</div>
				</div>
			</CSSTransition>*/}
		</div>
	)
}

export default TapperCircleTextPlayer;