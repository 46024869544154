import baseObject from '../../../utilities/data/mediaTapperBaseObj.js';
import { chartObject } from '../../../utilities/data/mediaTapperBaseObj.js';

const RPDChartData = {
	"music": "https://pact-datascroll.s3.amazonaws.com/259283__klankbeeld__city-traffic-02-ms-141121-0457.mp3",
	"slides": [
		{
			"url": "https://pact-datascroll-2.s3.amazonaws.com/police_wide.jpg",
			"mobileUrl": "https://pact-datascroll-2.s3.amazonaws.com/police.jpg",
			"textbox": {
				"text": "Raleigh PD’s apparent racial bias can be understood through the experiences of the families they police, but it can also be seen in the data.",
				"placement": {
					"x": null,
					"y": "40vh",
					"absolute": true
				}
			},
			"chart": {
				"text": ""
			}
		},
		{
			"url": "https://pact-datascroll-2.s3.amazonaws.com/rain_wide.jpg",
			"mobileUrl": "https://pact-datascroll-2.s3.amazonaws.com/rain.jpg",
			"chart": {
				"elType": baseObject.chart,
				"chartType": chartObject.circleBar,
				"offset": {
					"left": 20,
					"top": 100
				},
				"csvKey": "census",
				"text": {
					"header": "The city is majority white."
				}
			},
		},
		{
			"url": "https://pact-datascroll-2.s3.amazonaws.com/faces_wide.jpg",
			"mobileUrl": "https://pact-datascroll-2.s3.amazonaws.com/faces.jpg",
			"chart": {
				"elType": baseObject.chart,
				"chartType": chartObject.lines,
				"csvKey": "searchRate",
				"text": {
					"header": "But its attention is unequally fixed on policing Black communities.",
				}
			},
		},
		{
			"url": "https://pact-datascroll-2.s3.amazonaws.com/protest_wide.jpg",
			"mobileUrl": "https://pact-datascroll-2.s3.amazonaws.com/protest.jpg",
			"chart": {
				"elType": baseObject.chart,
				"chartType": chartObject.circleBar,
				"offset": {
					"left": 20,
					"top": 10
				},
				"csvKey": "hitrate",
				"text": {
					"header": "Though Black people are searched more frequently, contraband is found at similar rates across Raleigh.",
				},
			}
		},
		{
			"url": "https://pact-datascroll-2.s3.amazonaws.com/candle_wide.jpg",
			"mobileUrl": "https://pact-datascroll-2.s3.amazonaws.com/candle.jpg",
			"chart": {
				"elType": baseObject.chart,
				"chartType": chartObject.bar,
				"csvKey": "force",
				"text": {
					"header": "The difference in the use of force is even more dramatic.",
				}
			},
		},
		{
			"url": "https://pact-datascroll-2.s3.amazonaws.com/dc_wide.jpg",
			"mobileUrl": "https://pact-datascroll-2.s3.amazonaws.com/dc.jpg",
			"textbox": {
				"text": "These trends are similar nationwide. In 2019, the percentage of Black people who experienced threats or the use of force during police interactions was double that of white people.",
				"placement": {
					"x": null,
					"y": "40vh",
					"absolute": true
				}
			},
			"chart": {
				"text": {
					"header": ""
				}
			}
		},
	],
};

export const colorReference = {
	"Black": {
		chartColor: "#6f1a96",
	},
	"White": {
		chartColor: "#0ead42",
	},
	"Hispanic": {
		chartColor: "orange",
	},
	"Asian": {
		chartColor: "#2abfba",
	},
	"Other": {
		chartColor: "#961a3b",
	},
	"Native American": {
		chartColor: "#ff91f8",
	},
}

export default RPDChartData;