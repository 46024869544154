import React, { useState, useEffect, useRef } from 'react';
import { useThrottle } from "../../utilities/utilityFunctions.js";

function MarkerFull(props){
	const [connector, setConnector] = useState(0);
	const titleRef = useRef();
	const bgRef = useRef();
	const bgStyles = {
		backgroundPosition: "center",
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
	}
	useEffect(() => {
		let bgImg = new Image();
		bgImg.onload = () => {
			bgRef.current.style.backgroundImage = 'url(' + bgImg.src + ')'
		};
		bgImg.src = props.data.markerBGImg;
	}, [props.data.markerBGImg])

	const resizeConnector = useThrottle(() => {
		const theLine = document.getElementById("the-line");
		setConnector(titleRef.current.offsetLeft - theLine.offsetLeft);
	}, 50)

	useEffect(() => {
		setTimeout(() => {
			resizeConnector()
		});
		window.addEventListener('resize', resizeConnector);
		return () => window.removeEventListener('resize', resizeConnector);
	}, [resizeConnector])
	
	return (
			<div ref={bgRef} onClick={(e) => props.triggerLightbox(e, true, props.data.slides)} className="tl-marker-full-container" style={bgStyles}>
				<div ref={titleRef} className="tl-marker-title">
					<div id={`connector-${props.id}`}style={{width: `${connector}px`, left: `${-Math.abs(connector)}px`}} className="tl-connector"></div>					
					<h2>{props.data.year}</h2>
					<h2 style={{fontSize: "175%"}}>{props.data.markerSub}</h2>
				</div>
			</div>
	)
}

export default MarkerFull;
