import React, { useState } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

const CaptionModule = (props) => {
	const [open, setOpen] = useState(false)
	const optionalIconClasses = (props.optionalIconClasses) ? props.optionalIconClasses : ""
	const optionalBoxClasses = (props.optionalBoxClasses) ? props.optionalBoxClasses : ""

	return (
		<SwitchTransition>
		    <CSSTransition 
		        key={open} 
		        timeout={300} 
		        classNames="fade-animate"
		    >	
		        	{
		        		(open) ?
		            		<div onClick={() => setOpen(!open)} className={`caption-full cursor-pointer unselectable ${optionalBoxClasses}`}>
		            			{props.text}
		            		</div>
		        		:
		            		<div onClick={() => setOpen(!open)} className={`caption-icon cursor-pointer unselectable ${optionalIconClasses}`}>
								<strong>?</strong>
							</div>
		        	}
		    </CSSTransition>
		</SwitchTransition>	
	)
}

export default CaptionModule;