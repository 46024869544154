import { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import x from '../images/projects/x-white-outline.svg';

export default function Modal({modal, setModal, children}) {

	return (
		<>
			<div className="modalIcon cursor-pointer" onClick={() => setModal(true)}>
				i
			</div>
			<CSSTransition 
	       		in={modal} 
	       		timeout={350}
	       		unmountOnExit
	       		classNames="fade-600-animate"
	       	>
	       		<div className="modalPanel">
		       		<div className="xPosition cursor-pointer greyHaze" onClick={() => setModal(false)}>
						<img src={x}/>
					</div>
					<div className="modalContent">
						{ children }
					</div>
	       		</div>
	       	</CSSTransition>
		</>		
	)
}