import React, { useState, useRef, useCallback, useEffect } from 'react';

import ImagePlaceholder from '../ImagePlaceholder';
import DisasterGallery from './DisasterGallery';

import arrow from '../../images/projects/single-arrow.svg';
import iceAge from '../../images/detours/di-map-layers/iceAge.png';

import { disasterGallery } from './data/disasterJSONv2.js';

const MapSidebar = (props) => {
	const [startY, setStartY] = useState(null);
	const [stretched, setStretched] = useState(false);
	const panelRef = useRef()

	const contentClasses = "sidebar-content" + ((stretched && props.sidebarVis) ? " scrollable" : "")
	const sidebarClasses = "sidebar-container" + ((stretched && props.sidebarVis) ? " stretched" : (props.sidebarVis) ? "" : " hide");
	const closeArrowStyles = {
		transform: ((props.mobile && !props.sidebarVis) ? "rotate(0deg)" 
			: (!props.mobile && props.sidebarVis) ? "rotate(90deg)" 
			: "")
	}

	const trackTouch = useCallback((e) => {
		if (!stretched) {
			setStartY(e.touches[0].pageY);
		}
	}, [stretched])

	const growMobile = useCallback((e) => {
		const y = e.touches[0].pageY;
	    if (!stretched) {
	    	if (y < startY) {
	            setTimeout(() => {
	                setStretched(true);
	            }, 200)
	        }
	    } else if ((panelRef.current.scrollTop === 0 || panelRef.current.scrollTop < 0) && y > startY){
	    	e.preventDefault();
	        setStretched(false);
	    }
	}, [startY, stretched])

	useEffect(() => {
		panelRef.current.scrollTo(0,0)
	}, [props.sidebarVis])

	return(
		<div style={{ backgroundImage: `url(${iceAge})`}} className={sidebarClasses}>
			<div className="close-tab pointer" onClick={() => {
				props.setSidebarVis(!props.sidebarVis);
				setStretched(false);
			}}>
				<img style={closeArrowStyles} className="detail-close-arrow" alt="Toggle detail view pane" src={arrow} width="100%" />
			</div>
			<div ref={panelRef} className={contentClasses} onTouchStart={(e) => trackTouch(e)} onTouchMove={(e) => growMobile(e)}>
				<div className="sb-text-area">
					{
						(props.activeLayer !== null) ?
							<div>
								<ImagePlaceholder classNames={"disaster-header-container"} imgSrc={disasterGallery[props.activeLayer].headerURL} imgAlt={disasterGallery[props.activeLayer].headerAlt}/>
								<h1>{disasterGallery[props.activeLayer].name}</h1>
							</div>
						: 
							<h1>Environmental Risk Areas</h1>
					}
					{
						(props.activeLayer === 0) ?
							<div>
								<p>Several climate change factors will likely compound to make hurricanes and tropical storms more destructive in the coming decades. NOAA models indicate warming sea surface temperatures will likely intensify storm wind speeds and increase precipitation levels. Rising sea levels will also likely make future coastal storms more damaging, amplifying the storm surges that frequently result from lashing winds.</p>
								<p>The planet likely won’t see an increase in the total number of yearly storms, but the proportion of storms that reach high-intensity category 4 and 5 status is mostly likely set to rise. Currently, more than 8.6 million Americans live in areas susceptible to coastal flooding caused by hurricane or intense storm activity.</p>
							</div>
						: (props.activeLayer === 1) ?
							<div>
								<p>Floods are already the most common — and one of the most deadly — natural disasters in the U.S. As climate change drives sea levels higher and produces more extreme weather events, flooding will likely only worsen in many areas.</p>
								<p>The Fourth National Climate Assessment (2017) found that more flooding is occurring in the Mississippi River Valley, Midwest, and Northeast, while the amount of U.S. coastal flooding has doubled in the past few decades. Increased rain and snowmelt that accompany rising global temperatures will likely have a major impact, and may cause floods to increase in depth, frequency, and extent over the next century.</p>
								<p>Scientists expect floodplains in the U.S. to expand by approximately 45% by 2100. Globally, those same estimates show that areas currently home to 200 million people could fall permanently below the high tide line by the end of the century.</p>
							</div>
						: (props.activeLayer === 2) ?
							<div>
								<p>Wildfires are a necessary and natural part of a forest’s lifecycle in many parts of the country. But rising average temperatures, changing rain and snow patterns, and lengthening droughts are drying out U.S. forests, creating untenable burning conditions.</p>
								<p>Many plants that require fire for propagation, like high-elevation conifers, are frequently being trapped in fires more powerful than they’re evolved to handle. Recent fires in the West have left behind patches of dead conifers up to 1,000-acres wide. This tree loss cuts forests’ CO2-absorbing potential and is allowing more easily burnable grasses to grow instead, further increasing fire risks.</p>
								<p>In total, forest fire-prone areas doubled between 1984 and 2015, and California’s fire protection service, Cal Fire, no longer considers there to be a wildfire “season” — it now lasts the entire year.</p>
							</div>
						:
							<div>
								<p>As our climate continues to change, we may see environmental and economic disruption on a scale unseen in modern history. This map details some of the highest-risk areas for several climate-related disasters around the U.S., based on data collected from the McHarg Institute’s 2100 Project.</p>
								<h2>How To:</h2>
								<ul>
									<li>Tap the map's shaded layers to learn more.</li>
									<li>Show/hide this panel using the caret toggle on the hanging tab.</li>
									<li>Show/hide layers using the toggles in the bottom left corner.</li>
								</ul>
							</div>
					}
					{
						(props.activeLayer !== null) ?
							<DisasterGallery data={disasterGallery[props.activeLayer]} mobile={props.mobile} />
						:
							null
					}
				</div>
			</div>
		</div>
	)
}

export default MapSidebar;