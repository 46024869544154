import React from 'react';
import { CSSTransition } from 'react-transition-group';

const TimelinePanel = (props) => {
	const panelBGStyles = {
		backgroundImage: `url("${props.panelBG}")`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
	}

	return (
		<div style={panelBGStyles} className="pact-timeline-panel">
			<div className="content">
				<div className="padding-1x">
					<h1 className="pact-year-header">{props.year}</h1>
				</div>
				{
					(props.children) ?
					<CSSTransition 
						in={props.minify}
						timeout={600} 
						classNames="fade-600-reverse-animate"
					>
						<div>
							{ props.children }
						</div>
					</CSSTransition>
					: null
				}
			</div>
		</div>
	)
}

export default TimelinePanel;