import { useState } from 'react';

function SlideMedia({slide, i, slideTotal, setLoading}) {
	const [vidLoading, setVidLoading] = useState(true);
	const vidVisibility = (vidLoading) ? {
		opacity: 0
	} : {
		opacity: 1
	}

	const imgStyles = (slide.styles) ? slide.styles : {};

	return (
		<>
	        <div className='slideImg'>
	        	{
	        		(slide.slideType === "img" || slide.slideType === "imgQuote") ?
	        			<img style={imgStyles} src={slide.img} alt={slide.imgAlt} />
		        	: (slide.slideType === "vidText") ?
		        		<>
			        		<video 
			        			autoPlay
			        			loop
			        			muted
			        			playsInline
			        			src={slide.video} 
			        			style={vidVisibility}
			        			onCanPlayThrough={() => setVidLoading(false)}
			        		/>
			        		{
			        			(vidLoading) ? 
			        			 <div className="bootstrap-loader"></div>
			        			 : null
			        		}
		        		</>
		        	: null
		        }
		        <div style={slide.textPosition} className='tapperTextBox textPosition'>
	            	{
	            		(slide.slideType === "imgQuote") ?
	            			<>
		            			<p className="quoteBody">{ slide.text }</p>
		            			<div className="quoteBy">
		            				{ slide.quoteAuthor.map((quote, i) => <p key={`quote-${i}`}>{quote}</p> )}
		            			</div>
	            			</>
	            		: 	
	            			 slide.text 
	            	}
	            </div>
		    </div>
		</>
	)
}

export default SlideMedia