import { useState, useEffect } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, ZoomControl, useMapEvent } from 'react-leaflet';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useQuery } from '../../utilities/miscHooks.js';
import { useVhResize } from '../../utilities/layoutHooks.js'
import { useHistory } from "react-router-dom";

import MiniTapperContainer from './MiniTapperContainer.js';
import ColorKey from './ColorKey.js';
import FoldableShareModule from './FoldableShareModule.js';
import Modal from '../../Modal.js';
import Player2 from '../../Player2.js';
import policeReformExperiments from './data/policeReformExperiments.js';
import credits from './data/credits.js';
import './ReformMap.css'

import icon from '../../../images/detours/rp-reform-map/index.svg';
import icon2 from '../../../images/detours/rp-reform-map/magnifying.svg';
import icon3 from '../../../images/detours/rp-reform-map/target1.svg';
import icon4 from '../../../images/detours/rp-reform-map/target2.svg';

// const policeIcon = new L.Icon({
//     iconUrl: icon3,
//     iconRetinaUrl: icon3,
//     iconAnchor: null,
//     popupAnchor: null,
//     shadowUrl: null,
//     shadowSize: null,
//     shadowAnchor: null,
//     iconSize: new L.Point(45, 45),
//     className: 'ring'
// });

// const policeIconConstructor = L.DivIcon.extend({
//     options: {
//     	className: 'custom-div-icon',
// 	    iconSize: [30, 42],
// 	    iconAnchor: [15, 42]
// 	}
// });

// const policeIconPurple = new policeIconConstructor({html: `<div class='blob purple'></div>` });
// const policeIconRed = new policeIconConstructor({html: `<div class='blob red'></div>` });
// const policeIconBlue = new policeIconConstructor({html: `<div class='blob blue'></div>` });

const policeIconPurple = L.divIcon({
    className: 'custom-div-icon',
    html: `<div class='blob purple'></div>`,
    iconSize: [30, 42],
    iconAnchor: [15, 42]
});

const policeIconRed = L.divIcon({
    className: 'custom-div-icon',
    html: `<div class='blob red'></div>`,
    iconSize: [30, 42],
    iconAnchor: [15, 42]
});

const policeIconBlue = L.divIcon({
    className: 'custom-div-icon',
    html: `<div class='blob blue'></div>`,
    iconSize: [30, 42],
    iconAnchor: [15, 42]
});

const iconKey = {
	"rgba(140, 10, 10": policeIconRed,
	"rgba(74,20,68": policeIconPurple,
	"rgba(39, 52, 159": policeIconBlue
}

function ClickClose({active, setActive, modal, setModal, shareUnfold, setShareUnfold}) {
	const map = useMapEvent('click', () => {
		if (active !== null) {
    		setActive(null)
    	} else if (modal) {
    		setModal(false)
    	}
    	if (shareUnfold) {
    		setShareUnfold(false)
    	}
	})
	return null
}

function ReformMap(){
	const [modal, setModal] = useState(false);
	const [shareUnfold, setShareUnfold] = useState(false);
	const [modalCredits, setModalCredits] = useState(false);
	const [active, setActive] = useState(null);
	const [mobile, setMobile] = useState(window.innerWidth < 768);
	const [muted, setMuted] = useState(true);
	const [prevActive, setPrevActive] = useState(null);
	const activeData = (active !== null) ? policeReformExperiments[active] : policeReformExperiments[prevActive]
	let query = useQuery();
	let history = useHistory();
	
	useVhResize()
	 
	const [lat] = useState(38.914295);
	const [lng] = useState(-96.535144);
	const maxBounds = [
	    [-100.499550, -167.276413], //Southwest
	    [83.162102, -52.233040]  //Northeast
	];
	const [zoom] = useState((mobile) ? 3 : 5);
	const mapStyle = {
		width: '100%',
		height: '100vh',
  		height: 'calc(var(--vh, 1vh) * 100)',
		display: 'block'
	}

	// useEffect(() => {
	// 	let vh = window.innerHeight * 0.01;
	// 	document.documentElement.style.setProperty('--vh', `${vh}px`);
	// 	return () => document.documentElement.style.removeProperty('--vh');
	// }, [])

	useEffect(() => {
		const reformQuery = query.get("reform")
		const reformData = policeReformExperiments.find(r => r.query === reformQuery);
		if (reformData) {
			setModal(false)
			setActive(policeReformExperiments.indexOf(reformData))
		} else {
			setModal(true)
		}
	}, [])

	/* MODAL HOOKS */
	useEffect(() => {
		if (active !== null && modal) {
			setActive(null)
		}
		if (!modal && modalCredits) {
			setModalCredits(false)
		}
	},[modal])
	useEffect(() => {
		if (active !== null) {
			setPrevActive(active);
			setModal(false)
			setShareUnfold(false)
			history.push(`/raleigh-pact/police-reform-map/?reform=${policeReformExperiments[active].query}`)
		} else {
			history.push(`/raleigh-pact/police-reform-map/`)
		}
	}, [active])

	const markers = policeReformExperiments.map((mark, i) => {
		const targetIcon = iconKey[mark.panelColor]
		return ( 
			<Marker icon={ targetIcon } key={`marker-${i}`} position={[mark.lat, mark.lng]} eventHandlers={{click: () => {setActive(i)}}}/> 
		)
	})

	return (
		<div className="mapSizingContainer">
			<ColorKey classes={"mapKey"} />
			<div className="tray">
				<Modal modal={modal} setModal={setModal} active={active} setActive={setActive}>
	    			<SwitchTransition>
		    			<CSSTransition 
				       		key={modalCredits}
				       		timeout={350}
				       		classNames="fade-600-animate"
				       	>
				       		{
				       			(!modalCredits) ?
					       			<div>
						    			<h1>Experiments in Police Reform</h1>
										<div className="decorativeSeparator"></div>
										<p style={{paddingLeft: "1em"}}><em>Historically police reform in the U.S. has been a long, slow process. Impunity for police misconduct and a warrior culture are deeply entrenched norms in many police departments across the country, and it often takes monumental local effort to spur reform. No one reform package has solved every apparent problem in U.S. policing. Still, there are interesting experiments in alternative justice, police oversight, and use-of-force protocols going on around the country. The following map shares the stories behind seven of them.</em>
										</p>
										<h2>How to Use</h2>
										<ul>
											<li>Tap the different points on the map to open up story panels for each initiative</li>
											<ColorKey />
											<li>Read (and share) quick facts about each local reform by tapping the "Initiative / Impact" button on each panel</li>
										</ul>
										<div style={{marginTop: "1.5em"}} className="flex flex-col align-center">
											<div className="learnMoreButton cursor-pointer" onClick={() => setModal(false)}> 
												Explore the Map
											</div>
											<div className="learnMoreButton cursor-pointer"
											onClick={() => setModalCredits(true)}>
												Credits
											</div>
										</div>
									</div>
								: 
									<div className="creditsContainer">
										<h1>Credits</h1>
										{ credits.map((c, i) => <p key={`credit-${i}`}>{c}</p>) }
										<div className="modalCreditsButton cursor-pointer stickyFloat" onClick={() => setModalCredits(false)}>
											Back
										</div>
									</div>
							}
						</CSSTransition>
					</SwitchTransition>
	    		</Modal>
	    		<FoldableShareModule shareUnfold={shareUnfold} setShareUnfold={setShareUnfold} />
    		</div>
    		{/*<div 
    			style={{
					position: "absolute",
					top: "1.5em",
					background: "black none repeat scroll 0% 0%",
					left: "4em",
					width: "6em",
					zIndex: "100000000",
    			}}
    			onClick={() => {
    				setMuted(m => !m)
    			}}
    		>
    			{
    				(muted) ?
    					'MUTED'
    				: 
    					'SOUND'
    			}
    		</div>*/}
			<MapContainer 
		        center={[lat, lng]} 
		        zoom={zoom} 
		        zoomControl={false}
		        style={mapStyle}
		        minZoom={3}
		        maxZoom={9}
		        maxBounds={maxBounds}
		    >
		    	<ClickClose active={active} setActive={setActive} modal={modal} setModal={setModal} shareUnfold={shareUnfold} setShareUnfold={setShareUnfold} />
		    	<TileLayer
		            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a></a>'
		            url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
		        	id='mapbox/light-v10'
		        	accessToken='pk.eyJ1IjoidG9teGJhcm5lc3giLCJhIjoiY2p1OTJsZDEwMXI1ajN5bzJ4NDhhNzVkcCJ9.EV4112N91Zp7z0tOS-bazg'
		        />
				{ markers }
				<ZoomControl position="bottomleft" />
	        </MapContainer>
    		<CSSTransition 
	       		in={(active !== null)} 
	       		timeout={350}
	       		unmountOnExit
	       		classNames="fade-600-animate"
	       	>
    			<MiniTapperContainer activeData={activeData} setActive={setActive} muted={muted} mobile={mobile}/>
    		</CSSTransition>
    		{
    			(window.top === window) ? 
    		    	<Player2 track={"https://frame-v-media-django.s3.amazonaws.com/stories/raleigh-pact-part-ii/background_audio/Detour_2_Music.mp3"} muted={muted} setMuted={setMuted}/>
    			: null
    		}
		</div>
	)
}

export default ReformMap;