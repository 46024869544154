import React, { useState, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';

import MarkerFull from './MarkerFull';
import LightboxTapper from './LightboxTapper';

import acTimelineMedia from './data/ACTimelineMediaFINAL.js';

import snow from '../../../images/detours/ac-timeline/snow.png';
import muteIcon from '../../../images/projects/mute.svg';
import volumeIcon from '../../../images/projects/volume.svg';

import "../../utilities/interactiveUtilities.css";
import "./Timeline.css";

function Timeline(){
	const [lightbox, setLightbox] = useState(false);
	const [lightboxMedia, setLightboxMedia] = useState(null);
	const [mute, setMute] = useState(true);
	const [mobile] = useState(window.innerWidth < 813);
	const mainRef = React.createRef();
	const musicRef = React.createRef();
	const audioStatus = (mute) ? muteIcon : volumeIcon;

	const triggerLightbox = useCallback((e, open, assets) => {
		if (open) {
			e.stopPropagation();
			setLightboxMedia(assets);
			setLightbox(true);
			document.body.style.overflow = "hidden";
		} else {
			setLightbox(false);
			document.body.style.overflow = "visible";
		}
	}, [])

	const mobileMarkers = acTimelineMedia.markers.map((marker, i) => {
		return <MarkerFull key={`marker-full-${i}`} id={i} data={marker} triggerLightbox={triggerLightbox} mobile={mobile}/> 
	})

	const handleMute = useCallback((status) => {
		if (status) {
			musicRef.current.play()
		} else {
			musicRef.current.pause()
		}
		return setMute(m => !m)
	},[musicRef])

	return (
		<div ref={mainRef} className="interactive-main">
			{
        		(window.top === window) ? 
					<div className="audio-icon">
						<img className="unselectable" alt="" onClick={() => handleMute(mute)} src={audioStatus} />
					</div>
				: 
        			null
        	}
			<CSSTransition in={lightbox} timeout={400} unmountOnExit classNames="lightbox-animate">
				<LightboxTapper data={lightboxMedia} mobile={mobile} triggerLightbox={triggerLightbox}/>
	    	</CSSTransition>
			<div style={{backgroundImage: `url(${snow})`}} className="timeline-container">
				<div className="timeline-header">
					<h1>The History of<br/><span>Pandemics</span></h1>
					<p>Scroll and tap on the images below to learn more about past pandemics and how people fought them.</p>
				</div>
				<div id="the-line" className="the-line"></div>
				<div className="tl-spacing-container">
					{ mobileMarkers }
				</div>
			</div>
			{
        		(window.top === window) ? 
        			<audio onEnded={() => musicRef.current.play()} ref={musicRef} className="background-music-player" src={acTimelineMedia.music} />
        		: 
        			null
        	}
		</div>
	)
}

export default Timeline;