import TapperCircleTextPlayer from '../../TapperCircleTextPlayer.js'
import SlideMedia from './SlideMedia.js'

function Slide({slide, slideActive, setSlideActive, i, slideTotal, muted, muteGesture, setMuteGesture, handleTap }) {
	const opacityControl = (slideActive === i) ? {opacity: 1} : {opacity: 0};
	const play = (slideActive === i)

	return (
		<div style={opacityControl}>
			{
				(slide.slideType === "img" || slide.slideType === "vidText" || slide.slideType === "imgQuote") ?
					<SlideMedia slide={slide} i={i} slideTotal={slideTotal} />
	            : (slide.slideType === "vid") ?
	           		<TapperCircleTextPlayer src={slide} play={play} muted={muted} muteGesture={muteGesture} setMuteGesture={setMuteGesture} handleTap={handleTap}/> 
            	:	null
			}
            <div className='slideCounter'>
            	{i + 1} / {slideTotal}
            </div>	
		</div>
	)
}

export default Slide