import baseObject from '../../../utilities/data/mediaTapperBaseObj.js';

const acTimelineMedia = {
	"music": "https://frame-v-media-django.s3.amazonaws.com/stories/antibody-cure/background_audio/14._Detour_2_edit.mp3",
	"markers": [
		{
			"x": 8,
			"markerOffset": 10,
			"markerOffsetMobile": 8.5,
			"year": '165 - 180 A.D.',
			"markerSub": 'Antonine Plague',
			"markerBGImg": 'https://ac-detour-timeline.s3.amazonaws.com/transparencies/transparency_rome_black.png',
			"markerBGSize": "90%",
			"slides": [
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/Angel_of_death2.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/1_plague_rome/rome_1.jpg",
					"alt": "The Pandemic in Rome",
					"motion": "right",
					"text": "The Roman Empire was at its height when huge outbreaks of the Antonine Plague swept through troops returning from Western Asia. Modern epidemiologists believe the disease was a form of smallpox or measles.",
					"textPos": {
						"right": "5em",
						"mobileRight": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/Plague_in_an_ancient_city.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/1_plague_rome/rome_2.jpg",
					"alt": "The Roman Empire",
					"motion": "left",
					"text": "The illness’ symptoms included fever, diarrhea, vomiting, swollen throat, coughing, and red and black pustular rashes. Roman historian Dio Cassius estimated a death rate of 2,000 deaths a day in Rome at the plague’s peak around 189 AD.",
					"textPos": {
						"left": "5em",
						"mobileLeft": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/plague_etching.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/1_plague_rome/rome_3.jpg",
					"alt": "The decline of the Roman Empire",
					"motion": "right",
					"text": "The spiraling death toll decimated the army and reduced the number of new recruits, taxpayers, and farmers. The 15-year plague is widely seen as one of the central events that precipitated the fall of the Roman Empire.",
					"textPos": {
						"right": "5em",
						"mobileRight": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/marcus.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/1_plague_rome/rome_4.jpg",
					"alt": "Marcus Aurelius",
					"motion": "left",
					"text": `Famed stoic Marcus Aurelius served as emperor throughout the pandemic. He discusses the plague in his 'Meditations': "An infected mind is a far more dangerous pestilence than any plague — one only threatens your life, the other destroys your character."`,
					"textPos": {
						"right": "5em",
						"mobileRight": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "",
					"mobileUrl": "",
					"alt": "",
					"motion": "center",
					"text": `Estimated death toll: 5 million people`,
					"textPos": {
						"right": "0",
						"left": "0",
						"mobileLeft": "0",
						"mobileRight": "0"
					}
				}
			],
		},
		{
			"x": 27,
			"markerOffset": 28.65,
			"markerOffsetMobile": 27.5,
			"year": '1346 - 1353',
			"markerSub": 'The Black Death',
			"markerBGImg": 'https://ac-detour-timeline.s3.amazonaws.com/transparencies/transparency_plague.png',
			"markerBGSize": "100%",
			"slides": [
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/black_death/The_Triumph_of_Death_by_Pieter_Bruegel_the_Elder.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/2_plague_black/black_1.jpg",
					"alt": "'The Triumph of Death' by Pieter Bruegel the Elder",
					"motion": "left",
					"text": `The Black Death has gone by many names: the Pestilence, the Great Mortality, or simply the Plague — now the CDC’s official name for the disease. It's the deadliest pandemic recorded in human history, with recurring outbreaks lasting through the 19th century.`,
					"textPos": {
						"right": "5em",
						"mobileRight": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/black_death/2_wall.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/2_plague_black/black_2.jpg",
					"alt": "China",
					"motion": "left",
					"text": `Modern research suggests climate change in China contributed to the spread of plague. Declining rat populations from the country’s dying grasslands forced infected fleas to seek new hosts, spreading to humans, who brought them to Europe on trade routes.`,
					"textPos": {
						"left": "5em",
						"mobileLeft": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/black_death/1349_burning_of_Jews-European_chronicle_on_Black_Death.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/2_plague_black/black_3.jpg",
					"alt": "1349 illuminated manuscript depicting burning Jewish communities",
					"motion": "left",
					"text": `Lacking a modern understanding of disease transmission, conspiracy theories spread unimpeded. Rumors that Jews were pouring poison into wells sparked repeat massacres of Jewish communities. The first took place in 1348 in Toulon, France, where 40 Jews were murdered.`,
					"textPos": {
						"left": "5em",
						"mobileLeft": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/black_death/black-death.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/2_plague_black/black_4.jpg",
					"alt": "Visions of War",
					"motion": "right",
					"text": `Potentially the first biological warfare attack in human history took place during the Black Death in 1347 when Mongolian general Kipchak Khan Janibeg catapulted plague-infested corpses into the port city of Kaffa, now Feodosia in Crimea.`,
					"textPos": {
						"right": "5em",
						"mobileRight": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/black_death/dance_cropped.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/2_plague_black/black_5.jpg",
					"alt": "Dance Macabre",
					"motion": "left",
					"text": '"At every church they dug deep pits down to the water-table ... In the morning when a large number of bodies were found in the pit, they took some earth and shovelled it down on top of them; and later others were placed on top of them and then another layer of earth, just as one makes lasagne with layers of pasta and cheese.” – Marchionne di Coppo Stefani (1336 - 1385), Florence, Italy',
					"textPos": {
						"right": "5em",
						"mobileRight": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/black_death/venice_crop.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/2_plague_black/black_6.jpg",
					"alt": "Quarantine in Venice",
					"motion": "left",
					"text": 'The concept of quarantining was invented to combat plague. Officials in Venetian-controlled Ragusa ordered newly-arrived sailors to isolate until they could prove they weren’t sick. Initially 30 days of forced isolation, it was later raised to 40 — a "quarantena" — the origin of the word "quarantine."',
					"textPos": {
						"left": "5em",
						"mobileLeft": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "",
					"alt": "",
					"motion": "center",
					"text": 'Estimated death toll: 75 to 200 million people',
					"textPos": {
						"left": "0",
						"right": "0",
						"mobileLeft": "0",
						"mobileRight": "0"
					}
				},
			],
		},
		{
			"x": 49,
			"markerOffset": 48.8,
			"markerOffsetMobile": 47.5,
			"year": '1520 - 1949',
			"markerSub": 'Smallpox in the Americas',
			"markerBGImg": 'https://ac-detour-timeline.s3.amazonaws.com/transparencies/transparency_smallpox.png',
			"markerBGSize": "100%",
			"slides": [
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/smallpox/indigenous1.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/3_smallpox/pox_1.jpg",
					"alt": "",
					"motion": "left",
					"text": 'In the 1500s, smallpox was endemic, or constantly present in Europe and Asia, but it reached pandemic proportions when introduced to indigenous Americans.',
					"textPos": {
						"left": "5em",
						"mobileLeft": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/smallpox/indigenous2.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/3_smallpox/pox_2.jpg",
					"alt": "",
					"motion": "left",
					"text": 'Smallpox immunity is passed down hereditarily. Without prior exposure to European germs, the disease swept through native populations at an alarming rate, wiping out an estimated 90% of indigenous Americans.',
					"textPos": {
						"left": "5em",
						"mobileLeft": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/smallpox/aztecsmallpox.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/3_smallpox/pox_3.jpg",
					"alt": "",
					"motion": "right",
					"text": 'Outbreaks crippled major metropolitan centers in the Incan and Aztec empires. After being welcomed as visitors to the Aztec capital Tenochtitlán in November 1519, Hernán Cortés and his conquistadores returned nearly two years later to find nearly half the city had perished from the disease.',
					"textPos": {
						"right": "5em",
						"mobileRight": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/smallpox/indigenous4.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/3_smallpox/pox_4.jpg",
					"alt": "",
					"motion": "left",
					"text": 'During clashes with Delaware, Shawnee, and Mingo tribes in 1763, British generals conspired to deliver smallpox-infected blankets to Native Americans. "We must, on this occasion, Use Every Stratagem in our power to Reduce them." — Sir Jeffery Amherst, Commander in Chief of British forces in North America',
					"textPos": {
						"left": "5em",
						"mobileLeft": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/smallpox/fourbears.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/3_smallpox/pox_5.jpg",
					"alt": "",
					"motion": "right",
					"text": '"I do not fear Death my friends. You know it, but to die with my face rotten, that even the Wolves will shrink with horror at seeing Me, and say to themselves, that is The Four Bears, the Friend to the Whites." — Mandan leader Mato-Tope (The Four Bears)',
					"textPos": {
						"right": "5em",
						"mobileRight": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/smallpox/washington.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/3_smallpox/pox_6.jpg",
					"alt": "",
					"motion": "left",
					"text": 'Smallpox epidemics occasionally broke out among colonists. To combat outbreaks during the Revolutionary War, General George Washington forced his troops to undergo mandatory variolation in 1777, whereby individuals breathed material from smallpox sores to expose themselves to a milder version of the disease.',
					"textPos": {
						"left": "5em",
						"mobileLeft": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "",
					"alt": "",
					"motion": "center",
					"text": 'Estimated death toll: 25 to 55 million people',
					"textPos": {
						"left": "0",
						"right": "0",
						"mobileLeft": "0",
						"mobileRight": "0"
					}
				},
			]
		},
		{
			"x": 72,
			"markerOffset": 72,
			"markerOffsetMobile": 70,
			"year": '1846 - 1860',
			"markerSub": 'Third Cholera Epidemic',
			"markerBGImg": 'https://ac-detour-timeline.s3.amazonaws.com/transparencies/transparency_cholera.png',
			"markerBGSize": "100%",
			"slides": [
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/cholera/cholera1.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/4_cholera/cholera_1.jpg",
					"alt": "",
					"motion": "left",
					"text": 'There have been seven major cholera pandemics in modern history. The third was the deadliest. It started in India and spread through Europe, Asia, Africa, and the U.S., killing anywhere from thousands to over a million in the countries it touched.',
					"textPos": {
						"left": "5em",
						"mobileLeft": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/cholera/choleraNYC3.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/4_cholera/cholera_2.jpg",
					"alt": "",
					"motion": "right",
					"text": 'About 80% of people who ingest cholera bacteria don’t develop symptoms — but those that do can suffer diarrhea or vomiting so severe it can lead to dehydration, septic shock, or death within a few hours.',
					"textPos": {
						"right": "5em",
						"mobileRight": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/cholera/choleraNYC.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/4_cholera/cholera_3.jpg",
					"alt": "",
					"motion": "left",
					"text": 'In urban centers in the U.S., cholera hit Black and Irish immigrant populations particularly hard, as these communities had poor access to sanitation and running water. Many wealthy New Yorkers fled the city to the suburbs and country to avoid the contagion.',
					"textPos": {
						"left": "5em",
						"mobileLeft": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/cholera/choleraNYC2.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/4_cholera/cholera_4.jpg",
					"alt": "",
					"motion": "right",
					"text": '"There is no business doing here if I except that done by Cholera, Doctors, Undertakers, Coffinmakers, &c [sic]. Our bustling city now wears a most gloomy & desolate aspect — one may take a walk up & down Broadway & scarce meet a soul." — Assistant to the painter Asher B. Durand',
					"textPos": {
						"right": "5em",
						"mobileRight": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/cholera/cholera2.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/4_cholera/cholera_5.jpg",
					"alt": "",
					"motion": "left",
					"text": 'During the worst year of the pandemic in 1854, British physician John Snow tracked cholera cases in poor areas of London and traced the source to a single contaminated Broad Street water pump. His research is considered a founding event in epidemiology.',
					"textPos": {
						"left": "5em",
						"mobileLeft": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "",
					"alt": "",
					"motion": "center",
					"text": 'Estimated death toll: Over 1 million people',
					"textPos": {
						"left": "0",
						"right": "0",
						"mobileLeft": "0",
						"mobileRight": "0"
					}
				},
			]
		},
		{
			"x": 90,
			"markerOffset": 91.7,
			"markerOffsetMobile": 90.5,
			"year": '1918 - 1920',
			"markerSub": 'The Spanish Flu',
			"markerBGImg": 'https://ac-detour-timeline.s3.amazonaws.com/transparencies/transparency_flu.png',
			"markerBGSize": "100%",
			"slides": [
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/flu/flu0.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/5_spanish_flu/flu_1.jpg",
					"alt": "",
					"motion": "left",
					"text": 'Nearly the entire world suffered the effects of the Spanish flu, with an estimated 500 million people (a third of the world’s population at the time) becoming infected.',
					"textPos": {
						"left": "5em",
						"mobileLeft": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/flu/flu2.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/5_spanish_flu/flu_2.jpg",
					"alt": "",
					"motion": "right",
					"text": 'The 1918 epidemic became known as the "Spanish flu" because wartime censors in most other European countries minimized reports of the illness, while the Spanish press did not. It contributed to the false impression that the flu had Spanish origins.',
					"textPos": {
						"right": "5em",
						"mobileRight": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/flu/flu3.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/5_spanish_flu/flu_3.jpg",
					"alt": "",
					"motion": "left",
					"text": 'On November 11, 1918, the end of WWI prompted a huge resurgence in cases. People celebrated Armistice Day with parades and parties, resulting in a public health disaster and epidemic-level spikes in some cities.',
					"textPos": {
						"left": "5em",
						"mobileLeft": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/flu/flu4.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/5_spanish_flu/flu_4.jpg",
					"alt": "",
					"motion": "right",
					"text": 'The pandemic was so severe in the U.S. it caused life expectancy to drop by 10 years. Massive public health campaigns encouraged the public to wear masks, while San Francisco and several other hard-hit California cities passed mandatory mask laws.',
					"textPos": {
						"right": "5em",
						"mobileRight": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/flu/1918.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/5_spanish_flu/flu_5.jpg",
					"alt": "",
					"motion": "left",
					"text": 'In protest, an Anti-Mask League formed in San Francisco, and on December 18, 1918 an improvised explosive device was found addressed to the city’s health commissioner, Dr. William C. Hassler. It failed to detonate.',
					"textPos": {
						"left": "5em",
						"mobileLeft": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "https://ac-detour-timeline.s3.amazonaws.com/flu/flu5.jpg",
					"mobileUrl": "https://ac-detour-timeline.s3.amazonaws.com/mobile/5_spanish_flu/flu_6.jpg",
					"alt": "",
					"motion": "right",
					"text": '"We cannot in this matter pay any attention to any public agitators against the mask for the obvious reason that the question is one of public health and not of like or dislike of the mask." — Arthur H. Barendt, President of the San Francisco Board of Health',
					"textPos": {
						"right": "5em",
						"mobileRight": ".6em"
					}
				},
				{
					"type": baseObject.KBTextBoxImg,
					"url": "",
					"alt": "",
					"motion": "center",
					"text": 'Estimated death toll: 50 million people',
					"textPos": {
						"left": "0",
						"right": "0",
						"mobileLeft": "0",
						"mobileRight": "0"
					}
				},
			]
		}
	]
};

export default acTimelineMedia;