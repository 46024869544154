import React, { useState } from "react";

import '../../utilities/flipCards.css'

import prev from '../../../images/projects/prev.svg';

const ConspiracyCard = (props) => {
	const [active, setActive] = useState(0);
	const cardClassNames = 'conspiracy card' + (active ? ' applyflip' : '');

	return (
		<div className="conspiracy-card-container">
			<div className={cardClassNames}>
				<div className="content">
					
					<div onClick={() => setActive(!active)} className="cardFront conspiracy flex align-center" style={{background: `url(${props.data.backgroundImg})`, backgroundSize: 'cover', height: 'inherit'}}>
						<div className="card-front-banner">
							{props.data.cardTitle}
						</div>
					</div>

					<div className="cardBack conspiracy">
						<div onClick={() => setActive(!active)} className="cardBack-header">
							<img alt={props.data.alt} width="75%" src={prev}/>
						</div>
						{
							props.children
						}
					</div>

			    </div>
			</div>
		</div>
	)
}

export default ConspiracyCard;