import React, { useState, useEffect } from "react";

import LongScrollSlides from './LongScrollSlides.js';

import acConspiracyMedia from './data/ACConspiracyMedia.js';

import './Conspiracy.css'

function Conspiracy(){	
	const [loading, setLoading] = useState(true);
	const [active, setActive] = useState(0);
	const [firstChange, setFirstChange] = useState(false);
	const [mobile] = useState(window.innerWidth < 768.8);

	useEffect(() => {
		if (mobile) {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--vh', `${vh}px`);
		}
		document.body.classList.add("detour");
		return () => document.body.classList.remove("detour");
	}, [mobile])

	return (
		<div className="conspiracy-interactive-container">
			<LongScrollSlides data={acConspiracyMedia.opening} active={active} setActive={setActive} mobile={mobile} firstChange={firstChange} setFirstChange={setFirstChange} loading={loading} setLoading={setLoading}/>
		</div>
	)
}

export default Conspiracy;