const acCCardMedia = {
	"cards":[
		{
			"backgroundImg": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/cards/card_5G.jpg",
			"cardTitle": "5G is giving us COVID",
			"alt": "5G cell tower"
		},
		{
			"backgroundImg": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/cards/card_bill.jpg",
			"cardTitle": "Bill Gates is behind COVID",
			"alt": "Bill Gates"
		},
		{
			"backgroundImg": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/cards/card_hydro.jpg",
			"cardTitle": "Hydroxychloroquine",
			"alt": "Hydroxychloroquine"
		},
		{
			"backgroundImg": "https://ac-detour-conspiracy.s3.us-east-2.amazonaws.com/cards/card_lab.jpg",
			"cardTitle": "Chinese biotech",
			"alt": "Wuhan skyline",
		},
	],
};

export default acCCardMedia;