import React, { useState, useEffect, useCallback, useRef } from 'react';
import { MapContainer, TileLayer, LayersControl, GeoJSON, useMapEvent } from 'react-leaflet';
import L from 'leaflet';

import Sidebar from './MapSidebar';
import Player from '../Player.js';
// import { icon } from './Icon.js';

import {disasterJSON } from './data/disasterJSONv2.js';

import "./MapLeaf.css";

function ClickClose({activeLayer, setActiveLayer, sidebarVis, setSidebarVis}) {
	const map = useMapEvent('click', (e) => {
		if (activeLayer !== null) {
    		setTimeout(() => {
    			setActiveLayer(null)
    		}, 600)
    		setSidebarVis(false)
    	} else if (activeLayer === null && sidebarVis) {
    		setSidebarVis(false)
    	}
	})
	return null
}

const DisasterMap = (props) => {
	const [mobile] = useState(window.innerWidth < 768);
	const [activeLayer, setActiveLayer] = useState(null);
	const [sidebarVis, setSidebarVis] = useState(true);
	const [lat] = useState(38.914295);
	const [lng] = useState(-77.035144);
	const [zoom] = useState(4);
	const mapStyle = {
		width: '100vw',
  		height: 'calc(var(--vh, 1vh) * 100)',
		display: 'inline-block'
	}
	const disasterStyle = useCallback((fill) => {
        return {
            fillColor: fill,
            weight: 0.3,
            opacity: 1,
            fillOpacity: 0.5
        };
    }, []);

    useEffect(() => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}, [])

    useEffect(() => {
    	if(!sidebarVis && activeLayer !== null) {
    		setSidebarVis(true)
    	}
    }, [activeLayer])

    const geoJSONLayers = disasterJSON.map((disaster, i) => {
    	return (
    		<LayersControl.Overlay 
				name={disaster.name}
				checked={true}
				key={`disaster-${i}`}
			>
				<GeoJSON
		        	data={disaster}
		        	style={disasterStyle(disaster.features[0].properties.fill)}
		        	eventHandlers={{
		        		click: (e) => {
		        				L.DomEvent.stopPropagation(e);
		        				return setActiveLayer(i)
		        			}
		        		}
		        	}
		        >
		        </GeoJSON>
			</LayersControl.Overlay>
    	)
    })

	return(
		<div className="disaster-map-main">
			<MapContainer 
		        center={[lat, lng]} 
		        zoom={zoom} 
		        style={mapStyle}
		        minZoom={3}
		    >
		    	<ClickClose activeLayer={activeLayer} setActiveLayer={setActiveLayer} sidebarVis={sidebarVis} setSidebarVis={setSidebarVis}/>
		    	<TileLayer
		            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a></a>'
		            url="https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}"
		        	id='mapbox/streets-v11'
		        	accessToken='pk.eyJ1IjoidG9teGJhcm5lc3giLCJhIjoiY2p1OTJsZDEwMXI1ajN5bzJ4NDhhNzVkcCJ9.EV4112N91Zp7z0tOS-bazg'
		        />
		        <LayersControl position="bottomleft" collapsed={false}>
					{ geoJSONLayers }
		        </LayersControl>
	        </MapContainer>
			<Sidebar activeLayer={activeLayer} mobile={mobile} sidebarVis={sidebarVis} setSidebarVis={setSidebarVis} />
			{
				(window.top === window) ? 
					<Player track={"https://frame-v-media-django.s3.amazonaws.com/stories/disappearing-isle/background_audio/13._Detour_3_Slide.mp3"} />
					: null
			}
		</div>
	)
}

export default DisasterMap;