import React, { useState, useEffect } from 'react';
import KBSlide from './KenBurnsSlide';

import advance from '../../../images/projects/advance.svg';
import prev from '../../../images/projects/prev.svg';
import x from '../../../images/projects/x-white-outline.svg';

function LighboxTapper(props){
	const [active, setActive] = useState(0);
	const [loading, setLoading] = useState(true);
	const segments = props.data.map((segment, i) => <div key={`segment-${i}`} className="segment" style={{width:`${100/props.data.length}%`, background: ((active >= i)? "white": "black")}}></div>)
	
	useEffect(() => {
		let preloadedData = props.data.map((picture, i) => {
		    const img = new Image();
		    img.src = picture.url;
		    if (i === 0) {
		    	img.onload = () => {
		    		setLoading(false)
		    	}
		    }
		    return img
		});

		window.preloadedPictures = preloadedData;

		return () => {
			preloadedData[0].onload = () => {}
		}
	}, [props.data]);

	let closeClasses; 

	(active === props.data.length - 1) ? closeClasses = "lb-close-x left-close ring-sm" : closeClasses = "lb-close-x left-close";

	return (
			<div className="fullscreen-lightbox-tapper">
				<div className="segment-container">
					{segments}
				</div>
				<div onClick={(e) => props.triggerLightbox(e, false)} className={closeClasses}>
					<img className="unselectable" alt="Close lightbox" width="100%" src={x}/>
				</div>
				<KBSlide active={active} mobile={props.mobile} loading={loading} data={props.data[active]} nextSlide={() => setActive(active + 1)}/>
				{
					(active !== 0) ?
					<div className="tap-area prev-tap" onClick={() => setActive(active - 1)}>
						<img alt="Previous slide" className="tap-nav-button prev unselectable" src={prev}/>
					</div>
					: null
				}
				{
					(active !== props.data.length - 1) ?
						<div className="tap-area next-tap" onClick={() => setActive(active + 1)}>
							<img alt="Next slide" className="tap-nav-button advance unselectable" src={advance}/>
						</div>
					: null
				}
			</div>

	)
}

export default LighboxTapper;