import React from 'react';
import loader from '../../../images/projects/loader.svg';

const KBSlide = (props) => {
	const slideStyles = {
		backgroundPosition: "center",
		backgroundImage: (props.mobile) ? `url(${props.data.mobileUrl})` : `url(${props.data.url})`, 
		backgroundSize: "110%",
	}, textStyles = {
		left: (!props.mobile) ? props.data.textPos.left : props.data.textPos.mobileLeft,
		right: (!props.mobile) ? props.data.textPos.right : props.data.textPos.mobileRight,
	};
	const slideClasses = "kb-bg" + ((props.data.motion === "left") ? " bg-slide-left" : " bg-slide-right"),
		textClasses = "text-box-default dark KB" + ((props.active === 0 || props.active % 2 === 0) ? " fadeIn" : " fadeIn2");

	return (
		<div className="kb-slide-container">
			{
				(props.loading) ?
					<img alt="Loading..." className="proj-loader loader noShadow unselectable" src={loader}/>
				: 
					<div className="kb-slide-inner">
					{
						(props.data.motion !== "center") ?
							<div style={textStyles} className={textClasses}>
								{props.data.text}
							</div>
						: 
							<div style={textStyles} className="death-slide-kb">
								{props.data.text}
							</div>
					}
					{
						(props.active === 0 && props.data.alt === "The Pandemic in Rome") ?
						<div className="instruction-suggestion always-show">
							Tap the right side of the screen to advance or left side to view the previous slide.
						</div>
						: null
					}
					<div style={slideStyles} className={slideClasses}>
					</div>
				</div>
			}
		</div>
	)
};

export default KBSlide;