const credits = [
	"Spencer Platt/Getty Images",
	"Mark Makela/Getty Images",
	"Andrew Burton/Getty Images",
	"WhiteBirdClinic/Facebook",
	"benjmcbride/Facebook",
	"Beppe Sabatini/Flickr",
	"Thomas Hawk/Flickr",
	"Daniel Arauz/Flickr",
	"usmarshals/Flickr",
	"Tomás Del Coro/Flickr",
	"Beverly Yuen Thompson/Flickr",
	"R. Miller/Flickr",
	"DeShaun Craddock/Flickr",
	"Office of Public Affairs/Flickr",
	"Steve Shook/Flickr",
	"Blake Bolinger/Flickr",
	"Michael Scott/Pexels",
	"@NYPD73Pct/Twitter",
	"The White House from Washington, DC/Wikimedia",
	"Oakland (Calif.). Police Dept/Wikimedia",
	"Mark Ittleman/Wikimedia",
	"Roger Jones/Wikimedia",
	"David Geitgey Sierralupe/Wikimedia",
	"pedrik/Wikimedia",
	"dsgetch/Wikimedia",
	"Thomas Elliott/Wikimedia",
	"Craig Talbert/Wikimedia",
	"CyprianLatewood/Wikimedia",
	"Tdorante10/Wikimedia",
	"Mercury News/YouTube",
	"Not In Our Town/YouTube",
	"Camden County Division of Public Affairs",
	"CityofBerkeley.info",
	"George Perezvelez",
	"Google Maps",
	"White Bird Clinic",
]

export default credits;