import React, { useEffect, useRef } from "react";
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import loader from '../../../images/projects/loader.svg';
import down from '../../../images/projects/arrow-down.svg';

import TextBox from '../../TextBox.js';
import IntersectRef from '../../IntersectRef.js';
import CircleTextPlayer from './CircleTextPlayer.js';
import ConspiracyCard from './ConspiracyCard.js';

import baseObject from '../../utilities/data/mediaTapperBaseObj.js';
import acCCardMedia from './data/ACCCardMedia.js';

function LongScrollSlides(props){	
	const longScrollRef = useRef();
	const mediaSrc = (props.mobile) ? props.data.slides[props.active].mobileUrl : props.data.slides[props.active].url;
	const longScrollStyles = {
		height: props.data.height,
		background: "black",
	};

	//PROPS DESTRUCTURING
	const slides = props.data.slides,
		propsSetLoading = props.setLoading,
		mobile = props.mobile;

	useEffect(() => {
		let preloadedData = slides.map((picture, i) => {
		    const img = new Image();
		    (mobile) ? img.src = picture.mobileUrl : img.src = picture.url;
		    if (i === 0) {
		    	img.onload = () => {
		    		propsSetLoading(false)
		    	}
		    }
		    return img
		});

		window.preloadedPictures = preloadedData;

		return () => {
			preloadedData[0].onload = () => {}
		}
	}, [slides, propsSetLoading, mobile]);

	const textBoxes = props.data.slides.map((tb, i) => {
		if (tb.textbox.text !== "") {
			return <TextBox key={`tb-${i}`} data={tb.textbox}/>
		}
		return null
	})

	const intersectDivs = props.data.slides.map((tb, i) => {
		if (i !== props.data.slides.length - 1) {
			return( 
				<IntersectRef id={i} key={`intersect-${i}`} active={props.active} setActive={props.setActive} firstChange={props.firstChange} setFirstChange={props.setFirstChange} height={"150vh"}/>
			)
		} else {
			return( 
				<IntersectRef id={i} key={`intersect-${i}`} active={props.active} setActive={props.setActive} height={"auto"}>
					<h1 style={{width: "80%", margin: "0 auto"}}>A CLOSER LOOK AT POPULAR CONSPIRACY THEORIES</h1>	
					<div style={{marginBottom: "2em"}} className="conspiracy-card-organizer">
						<ConspiracyCard data={acCCardMedia.cards[0]}>
							<div className="cardBack-text-body">
								5G operates on the <a href="https://gizmodo.com/5g-is-forcing-hundreds-of-tv-channels-to-change-how-the-1837111135" target="_blank" rel="noopener noreferrer">same frequency</a> as UHF TVs in the 1970s and 80s, and yet the world didn’t see a single coronavirus case until 2019. Today's 5G fears stem directly from similar panics surrounding <a href="https://www.reuters.com/article/uk-factcheck-3g-sars-4g-swine-5g-coronav/false-claim-3g-4g-and-5g-caused-sars-swine-flu-and-the-new-coronavirus-idUSKBN21P27O" target="_blank" rel="noopener noreferrer">3G and 4G</a>.
							</div>
						</ConspiracyCard>
						<ConspiracyCard data={acCCardMedia.cards[1]}>
							<div className="cardBack-text-body">
								Bill Gates’ investment in vaccine research has made him a <a href="https://www.bbc.com/news/technology-52833706" target="_blank" rel="noopener noreferrer">major target</a> for conspiracy theories. Misinformation from <a href="https://www.typeinvestigations.org/investigation/2020/05/12/the-long-strange-history-of-bill-gates-population-control-conspiracy-theories/" target="_blank" rel="noopener noreferrer">dubious websites and pundits</a> have spread the idea that his foundations’ previous vaccines caused <a href="https://www.snopes.com/fact-check/bill-gates-vaccinations-depopulation/" target="_blank" rel="noopener noreferrer">mass sterility</a> and <a href="https://www.reuters.com/article/uk-factcheck-gates-india/false-claim-bill-gates-faces-trial-in-india-for-testing-vaccines-on-children-idUSKBN22V27F" target="_blank" rel="noopener noreferrer">death</a> — both likely <a href="https://thelogicalindian.com/fact-check/bill-gates-polio-vaccine-covid-19-gates-foundation-21270" target="_blank" rel="noopener noreferrer">false claims</a>.
							</div>
						</ConspiracyCard>
						<ConspiracyCard data={acCCardMedia.cards[2]}>
							<div className="cardBack-text-body">
								Despite some <a href="https://www.reuters.com/article/us-health-coronavirus-usa-hydroxychloroq/special-report-doctors-embrace-drug-touted-by-trump-for-covid-19-without-hard-evidence-it-works-idUSKBN21O2VO" target="_blank" rel="noopener noreferrer">doctors’</a> and <a href="https://www.usatoday.com/story/news/politics/2020/08/03/peter-navarro-ignores-experts-insists-hydroxychloroquine-effective/5574584002/" target="_blank" rel="noopener noreferrer">experts'</a> emphatic <a href="https://www.nytimes.com/2020/05/12/magazine/didier-raoult-hydroxychloroquine.html" target="_blank" rel="noopener noreferrer">support</a> of the malaria drug’s efficacy to treat COVID-19, controlled clinical trials have produced <a target="_blank" rel="noopener noreferrer" href="https://www.sciencemag.org/news/2020/06/three-big-studies-dim-hopes-hydroxychloroquine-can-treat-or-prevent-covid-19">scant evidence</a> it works to <a href="https://www.contagionlive.com/publications/contagion/2020/august/hydroxychloroquine-for-covid19-a-lesson-in-study-quality" rel="noopener noreferrer" target="_blank">cure</a> or <a rel="noopener noreferrer" href="https://www.sciencedaily.com/releases/2020/08/200817124914.htm" target="_blank">prevent</a> coronavirus. Many of its proponents have <a href="https://www.nytimes.com/2020/07/27/science/coronavirus-retracted-studies-data.html" target="_blank" rel="noreferrer noopener">questionable credentials</a> and a history of giving <a href="https://www.thedailybeast.com/stella-immanuel-trumps-new-covid-doctor-believes-in-alien-dna-demon-sperm-and-hydroxychloroquine" target="_blank" rel="noopener noreferrer">bizarre medical advice</a>.
							</div>
						</ConspiracyCard>
						<ConspiracyCard data={acCCardMedia.cards[3]}>
							<div className="cardBack-text-body">
								There is no <a href="https://www.sciencemag.org/news/2020/07/trump-owes-us-apology-chinese-scientist-center-covid-19-origin-theories-speaks-out" rel="noopener noreferrer" target="_blank">credible evidence</a> that COVID-19 was <a href="https://www.forbes.com/sites/jackbrewster/2020/05/10/a-timeline-of-the-covid-19-wuhan-lab-origin-theory/#6ee4c1155aba" target="_blank" rel="noopener noreferrer">created in a lab</a>. By sequencing the genome, scientists have all but confirmed the virus has a <a href="https://theconversation.com/heres-how-scientists-know-the-coronavirus-came-from-bats-and-wasnt-made-in-a-lab-141850" target="_blank" rel="noreferrer noopener">"zoonotic" animal origin</a>, evolving from a virus that targets bats.
							</div>
						</ConspiracyCard>
					</div>
				</IntersectRef>
			)
		}
	})

	return (
		<div ref={longScrollRef} style={longScrollStyles} className="long-scroll-container">
			{
				(!props.loading) ?
				<SwitchTransition>
					<CSSTransition 
						key={`change-${props.active}`} 
						timeout={300} 
						classNames="fade-animate"
					>
						<div className="static-scrollyteller-container">
							{
								(props.data.slides[props.active].elType === baseObject.singleImg && mediaSrc !== "") ?
									<img alt={props.data.slides[props.active].alt} className="static-scrollyteller-img" style={{position: "fixed", left: "0"}} src={mediaSrc} />
								: (props.data.slides[props.active].elType === baseObject.singleImg && mediaSrc === "") ?
									null
								:
									<CircleTextPlayer src={mediaSrc} playText={props.data.slides[props.active].playText} />
							}
							{
								(props.active === 0 && !props.firstChange) ? 
									<div className="scroll-prompt">
										Scroll to continue
										<div className="scroll-down">	
											<img alt="Scroll down" src={down} width="100%"/>
										</div>
									</div>
								: null
							}
						</div>
					</CSSTransition>
				</SwitchTransition>
				: <img alt="Loading..." className="proj-loader loader noShadow" src={loader}/>
			}
			{intersectDivs}
			{
				(!props.loading) ?
					textBoxes
				: null
			}
		</div>
	)
}

export default LongScrollSlides;