const timelinePanels = [
	{
		"year": 2016,
		"bgImg": "https://pact-twitter-tl.s3.amazonaws.com/1_protestDESKTOP.jpg",
		"bgMobile": "https://pact-twitter-tl.s3.amazonaws.com/1_protest.jpg",
	},
	{
		"year": 2017,
		"bgImg": "https://pact-twitter-tl.s3.amazonaws.com/2_sign.jpg",
	},
	{
		"year": 2018,
		"bgImg": "https://pact-twitter-tl.s3.amazonaws.com/3_fist.jpg",
	},
	{
		"year": 2019,
		"bgImg": "https://pact-twitter-tl.s3.amazonaws.com/4_whitefam.jpg",
	},
	{
		"year": 2020,
		"bgImg": "https://pact-twitter-tl.s3.amazonaws.com/5_candleDESKTOPJPG.jpg",
		"bgMobile": "https://pact-twitter-tl.s3.amazonaws.com/5_candle.jpg",
	},
]

export default timelinePanels;